import { createContext, useContext } from "react";

/**
 * Simplify context creation function using suggested pattern
 * from https://kentcdodds.com/blog/how-to-use-react-context-effectively
 *
 * @example
 * interface ContextValue{
 *   foo: string;
 * }
 * const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<ContextValue>();
 */
export function createTypeSafeContext<Type>(defaultValue?: Type) {
  const TypeContext = createContext<Type | undefined>(defaultValue);
  const useTypeSafeContext = (): Type => {
    const context = useContext(TypeContext);
    if (context === undefined) {
      throw new Error(
        "Could not find provided context. Make sure to wrap your component within the Provider.",
      );
    }
    return context;
  };

  return {
    Provider: TypeContext.Provider,
    Consumer: TypeContext.Consumer,
    useTypeSafeContext,
  };
}
