import { UseQueryOptions } from "@tanstack/react-query";
import { whereEq } from "ramda";
import { IAdminUi } from "core";

import AdminService from "services/admin";

import { Query, useAuthenticatedQuery } from "../utils";

import { QueryKeys } from "./queryKeys";
import { FunctionNameList } from "./types";

const getOptions = (list: FunctionNameList[]) =>
  list.map((item) => ({
    label: item.name,
    value: item.name,
    argnames: item.argnames?.filter(Boolean),
  }));

const getFilteredApps =
  (filter: Record<string, unknown>) => (list: IAdminUi[]) => {
    if ((Object.values(filter) ?? {})?.toString()?.trim()?.length) {
      const pred = whereEq(filter);
      return list.filter((item) => pred(item));
    } else {
      return list;
    }
  };

const services = AdminService.getInstance();

export const fetchApps: Query<IAdminUi[]> = {
  queryKey: QueryKeys.fetchApps,
  queryFn: services.getAllUi,
};

export const fetchFunctions: Query<FunctionNameList[]> = {
  queryKey: QueryKeys.fetchFunctions,
  queryFn: services.getFunctionsName,
};

type SelectorOptions = { label: string; value: string; argnames?: string[] }[];

export const useApps = (
  options?: UseQueryOptions<IAdminUi[], Error, IAdminUi[]>,
) => useAuthenticatedQuery(fetchApps, undefined, options);

export const useFilteredApps = (filter: Record<string, unknown>) =>
  useApps({ select: getFilteredApps(filter) });

export const useFunctions = (
  options?: UseQueryOptions<FunctionNameList[], Error, SelectorOptions>,
) => useAuthenticatedQuery(fetchFunctions, undefined, options);

export const useFunctionOptions = () => useFunctions({ select: getOptions });

export const useDataApiOpenApi = () =>
  useAuthenticatedQuery({
    queryKey: "data-api-openapi",
    queryFn: services.getDataApiOpenApi,
  });

export const useAppEdition = (
  options?: UseQueryOptions<boolean, Error, boolean>,
) =>
  useAuthenticatedQuery(
    {
      queryKey: "app-editon",
      queryFn: services.getEdition,
    },
    undefined,
    options,
  );
