import { memo } from "react";
import { Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import { FormattedDate, FormattedTime } from "react-intl";
import { IElementComponentProps } from "core";
import { getTotalTimezoneOffset } from "utils/date";
import { stringToDateObject } from "utils/string";

import { DateTimeField } from "./types";

interface IProps
  extends IElementComponentProps<Record<string, unknown>, DateTimeField> {
  value: string | null;
  timezone: number | null;
  formatString?: string;
}

const DefaultDateTimeField = memo<IProps>(
  ({
    value,
    element: {
      config: { isRelative, showDatePart, showTimePart },
    },
    timezone,
    formatString,
  }) => {
    let dateValue = stringToDateObject(value);
    if (!dateValue) {
      return null;
    }

    if (timezone !== null) {
      dateValue = new Date(
        dateValue.valueOf() + getTotalTimezoneOffset(dateValue, timezone),
      );
    }

    let formattedValue = null;

    if (formatString) {
      formattedValue = (
        <Typography>{format(dateValue, formatString)}</Typography>
      );
    } else {
      formattedValue = (
        <>
          {showDatePart && <FormattedDate value={dateValue} />}{" "}
          {showTimePart && <FormattedTime value={dateValue} />}
        </>
      );
    }

    if (isRelative && formattedValue) {
      const relativeValue = formatDistanceToNow(dateValue, {
        addSuffix: true,
      });

      return (
        <Tooltip title={formattedValue}>
          <span>{relativeValue}</span>
        </Tooltip>
      );
    }

    return formattedValue;
  },
);

DefaultDateTimeField.displayName = "DateTimeField";

export default DefaultDateTimeField;
