import { lazy, memo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { EChartsOption } from "echarts";
import DefaultDataDisplay from "elementTypes/default_data_display/DataDisplay";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { RoutePaths } from "staticPages/routes";
import { QueryKeys, useApps, useUsers } from "../../../../queries/admin";
import { IApp, IUser } from "../../../types";

export const Echarts = withLazyLoading(
  lazy(() => import("../../../../elementTypes/common/Echarts/Echarts")),
  true,
);

export const AdminPageComponent = memo(() => {
  const queryClient = useQueryClient();
  const users = useUsers();
  const apps = useApps();
  const usersList = (users.data ?? []) as IUser[];
  const appList = (apps.data ?? []) as IApp[];

  const totalUsers = usersList.length;
  const totalApps = appList.length;
  const activeUsers = usersList.filter((user) => user.isActive).length;
  const load = (key: string) => () =>
    queryClient.invalidateQueries({ queryKey: [key] });

  const option: EChartsOption = {
    tooltip: { trigger: "item", formatter: "{b}: {c} ({d}%)" },
    legend: { orient: "vertical", left: "left", show: true },
    series: [
      {
        avoidLabelOverlap: true,
        label: { show: true },
        type: "pie",
        radius: ["55%", "75%"],
        data: [
          {
            name: "Active Users",
            value: activeUsers,
          },
          {
            name: "Deactivated Users",
            value: totalUsers - activeUsers,
          },
        ],
      },
    ],
  };

  return (
    <>
      <Box pb={2}>
        <Typography variant="h5">Dashboard</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box display="grid" gap={2}>
            <DefaultDataDisplay
              label="Total Apps"
              icon="app_shortcut"
              barPosition="bottom"
              formattedData={totalApps}
              error={null}
              loading={apps.isFetching || apps.isInitialLoading}
              load={load(QueryKeys.fetchApps)}
              color="primary"
              href={RoutePaths.Apps}
            />
            <DefaultDataDisplay
              label="Total Users"
              icon="people_alt"
              barPosition="bottom"
              formattedData={totalUsers}
              error={null}
              loading={users.isInitialLoading || users.isFetching}
              load={load(QueryKeys.fetchUsers)}
              color="info"
              href={RoutePaths.Users}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Echarts option={option} elementId="app-users" chartType="pie" />
        </Grid>
      </Grid>
    </>
  );
});
