import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  ColorConfig,
  IElement,
  SelectorTypes,
  UntransformedConfig,
} from "core/types";
import { customExpression } from "core/types/customExpression";
import { ActionConfigType } from "elementTypes/common/ActionConfig/types";

export const CallButtonConfig = t.intersection([
  t.type({
    callName: customExpression(t.string),
    callArgs: t.union([customExpression(t.UnknownRecord), t.UnknownRecord]),
  }),
  t.partial({
    icon: customExpression(t.string),
    color: ColorConfig,
    variant: t.keyof({
      outlined: null,
      contained: null,
    }),
    showNotification: t.boolean,
    disabled: t.union([t.boolean, customExpression(t.boolean)]),
    onSuccess: t.array(ActionConfigType),
  }),
]);

export const callButtonSelectors: SelectorTypes<CallButtonConfig> = {
  loading: types.boolean("Indicates if the function is being called right now"),
  error: types.nullable(types.string()),
  result: types.nullable(types.any(), "the function result"),
};

export type CallButtonConfig = t.TypeOf<typeof CallButtonConfig>;

export const CallButtonTranslationKeys = ["label"] as const;

export type CallButtonTranslationKeys =
  (typeof CallButtonTranslationKeys)[number];

export type UntransformedCallButtonConfig =
  UntransformedConfig<CallButtonConfig>;

export type CallButton = IElement<
  CallButtonConfig,
  {},
  CallButtonTranslationKeys
>;
