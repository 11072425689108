import { IconNameType } from "elementTypes/common/MuiIcon";
import { isDevMode } from "utils/other";
import { RoutePaths } from "../../../routes";

type MenuItem = {
  name: string;
  title: string;
  path: string;
  icon: IconNameType;
  isEnterprise?: boolean;
  menu?: MenuItem[];
};

const devMode = isDevMode();

const devMenu: MenuItem = {
  name: "development_menu",
  title: "Development",
  path: "",
  icon: "engineering" as IconNameType,
  menu: [
    {
      name: "internal",
      title: "CYPEX Internal DB",
      path: RoutePaths.Internal,
      icon: "AdminPanelSettings" as IconNameType,
    },
  ],
};

const appMenu: MenuItem[] = [
  {
    name: "dashboard",
    title: "Dashboard",
    path: RoutePaths.Admin,
    icon: "dashboard",
  },
  {
    name: "database",
    title: "Database",
    path: RoutePaths.Database,
    icon: "storage",
  },
  {
    name: "applications",
    title: "Applications",
    path: RoutePaths.Apps,
    icon: "AppShortcut" as IconNameType,
  },
  {
    name: "authentication",
    title: "Authentication",
    path: "",
    icon: "lock_open" as IconNameType,
    menu: [
      {
        name: "users",
        title: "Users",
        path: RoutePaths.Users,
        icon: "people",
      },
      {
        name: "roles",
        title: "Roles",
        path: RoutePaths.Roles,
        icon: "people_outline",
      },
      {
        name: "settings",
        title: "Login Settings",
        path: RoutePaths.AdminSettings,
        icon: "settings",
      },
      {
        name: "ldap-configuration",
        title: "LDAP Configuration",
        path: RoutePaths.Ldap,
        icon: "lock_open",
      },
      {
        name: "repositoryConfiguration",
        title: "Repository Configuration",
        path: RoutePaths.RepositoryConfig,
        icon: "GitHub",
        isEnterprise: true,
      },
    ],
  },
  {
    name: "audit",
    title: "Audit",
    path: "",
    icon: "security",
    menu: [
      {
        name: "audit-tables",
        title: "Tables",
        path: RoutePaths.AuditsTables,
        icon: "table_chart",
      },
      {
        name: "audit-users",
        title: "Users",
        path: RoutePaths.AuditUsers,
        icon: "people",
      },
    ],
  },
  {
    name: "fileManagement",
    title: "File Management",
    path: RoutePaths.Files,
    icon: "folder",
  },
  {
    name: "dataApi",
    title: "Data API",
    path: RoutePaths.DataApi,
    icon: "http",
  },
  {
    name: "add-ons",
    title: "Add-ons",
    path: "",
    icon: "library_add",
    menu: [
      {
        name: "repositoryApplications",
        title: "Repository Applications",
        path: RoutePaths.AppRepos,
        icon: "apps",
      },
      {
        name: "extensions",
        title: "Extensions",
        path: RoutePaths.Extensions,
        icon: "extension",
        isEnterprise: true,
      },
    ],
  },
];

export const menu: MenuItem[] = devMode ? [...appMenu, devMenu] : appMenu;
