import { memo, useCallback, useMemo, useRef, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import { ConnectedReduxModuleProps } from "core";
import { LoadingComponent } from "layouts/common/Loading";

import { MuiIcon } from "../common/MuiIcon";
import { TableMetadataRow } from "../default_table/types";

import { ItemState } from "./components";
import { ReduxModule } from "./reduxModule";
import { useStyles } from "./style";
import { StateChangeDropDown } from "./types";

type DefaultStateChangeDropdown = ConnectedReduxModuleProps<
  ReduxModule,
  StateChangeDropDown
>;

const DefaultStateChangeDropdown = memo<DefaultStateChangeDropdown>(
  ({
    value,
    changeValue,
    language,
    element: {
      config: { disabled },
      props: { metadata },
    },
  }) => {
    const options = useMemo(
      () => (metadata as TableMetadataRow)?.stateChanges ?? [],
      [metadata],
    );
    const currentState =
      (metadata as TableMetadataRow)?.currentStateI18n?.[language] ?? {};

    const availableOptions = useMemo(
      () =>
        options ? options.filter((o) => o.to !== value && o.to !== null) : null,
      [options, value],
    );

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
    const anchorRef = useRef(null);
    const onClick = () => setAnchorEl(anchorRef.current);
    const onChangeState = useCallback(
      (newValue: any) => () => {
        changeValue(newValue);
        onClose();
      },
      [changeValue, onClose],
    );

    const selectOptions = useMemo(() => {
      if (availableOptions && !!availableOptions.length) {
        return availableOptions.map(
          ({ to, i18n: { [language]: i18nProps } }, i: number) => (
            <ItemState
              key={i}
              name={to}
              onChangeState={onChangeState}
              i18n={i18nProps}
            />
          ),
        );
      }
      return null;
    }, [language, onChangeState, availableOptions]);

    const renderSelect = (
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        disableAutoFocusItem={true}
        disableAutoFocus={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // TODO: check if no getContentAnchorEl={null} is causing problems
      >
        {selectOptions}
      </Menu>
    );

    const isDisabled =
      disabled || !(availableOptions && !!availableOptions.length);

    const btnTooltip = currentState?.shortDescription;

    const iconButton = () => {
      switch (true) {
        case !availableOptions:
          return "update";
        case Boolean(anchorEl):
          return "expand_less";
        default:
          return "expand_more";
      }
    };

    const {
      classes: { root, btnClass },
    } = useStyles();

    if (options) {
      const label = currentState.title ?? value;
      const component = (
        <div className={root}>
          <ButtonGroup ref={anchorRef} size="small" disabled={isDisabled}>
            <Button className={btnClass}>{label}</Button>
            <Button onClick={onClick}>
              <MuiIcon icon={iconButton()} />
            </Button>
          </ButtonGroup>
        </div>
      );

      return (
        <>
          {isDisabled || !btnTooltip ? (
            component
          ) : (
            <Tooltip title={btnTooltip}>{component}</Tooltip>
          )}
          {renderSelect}
        </>
      );
    }

    return <LoadingComponent />;
  },
);

export default DefaultStateChangeDropdown;
