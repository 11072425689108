import {
  ChangeEvent,
  MouseEvent,
  lazy,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Box, TextField, Typography } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";
import { useElementTypesContext } from "core/ElementTypesContext";
import { Section, ViewFieldToElement, useEditorTranslation } from "core/editor";

import { IElementModel, IElementType, IObjectView, Language } from "core/types";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";

import { useDebouncedState } from "utils/hooks";
import { useTableEditorTranslation } from "../../../translation";
import { CellAlignment, UntransformedTableConfig } from "../../../types";

import { SelectElementParams, UpdateChildrenParams } from "../../component";
import { searchColumns } from "../utils";
import { ColumnDialog } from "./ColumnDialog";
import { Columns } from "./Columns";
import { ColumnsProvider } from "./ColumnsContext";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

export type IColumn = TableHeaderCell & {
  position: {
    column: number;
  };
};

export type IGenerateColumn = ViewFieldToElement & {
  fieldName?: string;
  sortable?: boolean;
  label?: string;
  align?: CellAlignment;
  width?: string;
};

export type ColumnDetails =
  | (Omit<IColumn, "i18n"> & {
      label: string;
      index: number;
      isNew?: boolean;
      isHidden?: string | null;
    })
  | ReturnType<typeof newColumnParams>;

type Props = {
  language: Language;
  elementModel: IElementModel<UntransformedTableConfig>;
  currentView?: IObjectView;
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
  generateDefaultColumns: () => void;
  generateColumn: (newProps: IGenerateColumn) => void;
  updateChildren: (...params: UpdateChildrenParams) => void;
  deleteColumn: (index: number) => void;
  selectElement: (...params: SelectElementParams) => void;
};

export const ColumnEditor = memo<Props>(
  ({
    elementModel: {
      config: { hidden: initiallyHidden },
      children,
    },
    language,
    currentView,
    updateChildren,
    generateColumn,
    generateDefaultColumns,
    deleteColumn,
    selectElement,
  }) => {
    const {
      addColumnTooltip,
      columnsTitle,
      generateColumnsTooltip,
      generateConfirmation,
    } = useTableEditorTranslation();
    const { cancelButton } = useEditorTranslation();
    const { elementTypes: allElementTypes } = useElementTypesContext();

    const [columnDetails, setColumnDetails] = useState<ColumnDetails | null>(
      null,
    );

    const [lang, setLang] = useState<Language>(language);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [cachedSearchValue, cachedHandleSearch] = useDebouncedState(
      searchQuery,
      setSearchQuery,
      { delay: 200 },
    );

    const { searchLabel } = useTableEditorTranslation();

    const {
      header: { elements: columns },
    } = children as any;
    // TODO: check *hidden*

    const filteredColumns = useMemo(
      () =>
        cachedSearchValue.trim()
          ? searchColumns(columns, searchQuery)
          : columns,
      [cachedSearchValue, columns, searchQuery],
    );

    const handleSearchChange = ({
      target: { value },
    }: ChangeEvent<HTMLInputElement>) => cachedHandleSearch(value);

    const hidden =
      !initiallyHidden || initiallyHidden?.length < (columns ?? []).length
        ? new Array((columns ?? []).length).fill(null)
        : initiallyHidden;

    const columnsExist = !!columns.length;
    const elementTypes: Record<string, IElementType<any>> = Object.keys(
      allElementTypes,
    ).reduce((result, name) => {
      const elementType = allElementTypes[name];

      return elementType.defaultElement
        ? { ...result, [name]: elementType }
        : result;
    }, {});

    const handlePopupClose = useCallback(() => setAnchorEl(null), []);

    function changeLanguage(newLanguage: Language) {
      setLang(newLanguage);
    }

    function setNewParams() {
      setColumnDetails(newColumnParams(getLastOrder(columns)));
    }

    function onGenerateClick(e: MouseEvent<HTMLButtonElement>) {
      columnsExist ? setAnchorEl(e.currentTarget) : generateDefaultColumns();
    }

    const handleGenerateColumns = () => {
      generateDefaultColumns();
      handlePopupClose();
    };

    const clearSearch = () => cachedHandleSearch("");

    const searchBar = (
      <Box p={1} borderTop="1px solid" borderColor="divider">
        <TextField
          InputProps={{
            startAdornment: <GridSearchIcon />,
            endAdornment: cachedSearchValue.trim() ? (
              <IconButton
                icon="clear"
                color="primary"
                size="small"
                onClick={clearSearch}
              />
            ) : null,
          }}
          fullWidth
          value={cachedSearchValue}
          size="small"
          placeholder={searchLabel}
          onChange={handleSearchChange}
        />
      </Box>
    );

    return (
      <ColumnsProvider
        value={{
          columnDetails,
          language: lang,
          elementTypes,
          currentView,
          hidden,
          filteredColumns,
          updateChildren,
          setColumnDetails,
          deleteColumn,
          generateColumn,
          selectElement,
          searchValue: cachedSearchValue,
        }}
      >
        <Section
          title={columnsTitle}
          classes={{ root: "editor-table-section-columns" }}
          headerAction={
            <Box display="flex" alignItems="center">
              <IconButton
                icon="filter_none"
                onClick={onGenerateClick}
                color="primary"
                tooltip={generateColumnsTooltip}
              />
              <IconButton
                icon="add"
                onClick={setNewParams}
                color="primary"
                tooltip={addColumnTooltip}
              />
            </Box>
          }
          cardActions={
            columnsExist ? (
              <LanguageSwitch
                language={lang}
                changeLanguage={changeLanguage}
                colorVariant="dark"
                fullWidth={true}
              />
            ) : undefined
          }
        >
          {searchBar}
          <Columns />
        </Section>
        <ColumnDialog />
        <Popover
          anchorEl={anchorEl}
          onClose={handlePopupClose}
          actionsAlign="center"
          actions={
            <>
              <Button
                id="generate-columns-pop-up-confirm"
                label={generateColumnsTooltip}
                color="secondary"
                onClick={handleGenerateColumns}
              />
              <Button
                label={cancelButton}
                onClick={handlePopupClose}
                id="generate-columns-pop-up-cancel"
              />
            </>
          }
        >
          <Typography component="span">{generateConfirmation}</Typography>
        </Popover>
      </ColumnsProvider>
    );
  },
);

function getLastOrder(arr: IColumn[]) {
  return arr!.length ? Math.max(...arr.map((el) => el.position.column)) + 1 : 1;
}

function newColumnParams(order: number) {
  return {
    name: "",
    label: "",
    config: {
      dataSource: {
        fieldName: "",
        sortable: false,
      },
      align: "center" as CellAlignment,
    },
    position: {
      column: order,
      row: 1,
      width: 1,
      height: 1,
    },
    isNew: true,
    index: order - 1,
    isHidden: null as null | string,
  };
}
