import * as t from "io-ts";
import { customExpression } from "core";

// docs: https://postgrest.org/en/v12/references/api/resource_representation.html#response-format
export const ResponseType = t.keyof({
  json: null,
  geo: null,
  text: null,
});

export type BuiltinResponseType = t.TypeOf<typeof ResponseType>;

export const ResponseTypeConfig = t.union([
  ResponseType,
  customExpression(t.union([ResponseType, t.null])),
]);
