import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { customExpression } from "core/types/customExpression";
import { SelectorTypes } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";

import { FormInputConfig } from "../common";

export const dateTimeInputSelectors: SelectorTypes<DateTimeInputConfig> = {
  value: types.nullable(types.date()),
  disabled: types.boolean(),
};

export const DateTimeInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.string])),
  FormInputConfig,
  t.partial({
    showDatePart: t.boolean,
    showTimePart: t.boolean,
    minDate: customExpression(t.union([t.null, t.string])),
    maxDate: customExpression(t.union([t.null, t.string])),
    timezone: customExpression(t.union([t.null, t.number])),
    formatString: customExpression(t.union([t.undefined, t.string])),
  }),
]);

export type DateTimeInputConfig = t.TypeOf<typeof DateTimeInputConfig>;

export type UntransformedDateTimeInputConfig =
  UntransformedConfig<DateTimeInputConfig>;

export const DateTimeInputTranslationKeys = ["label"] as const;

export type DateTimeInputTranslationKeys =
  (typeof DateTimeInputTranslationKeys)[number];

export type DateTimeInput = IElement<
  DateTimeInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  DateTimeInputTranslationKeys
>;
