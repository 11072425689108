import { MouseEvent, memo, useState } from "react";
import { Box, FormLabel } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Control, Controller, FieldValues } from "react-hook-form";

import { useRoles } from "queries/admin";
import { getRoleOptions } from "queries/admin/utils";

import { Autocomplete } from "../../../../../../../elementTypes/common/Autocomplete";
import { ToggleButton } from "../../../../../../../elementTypes/common/ToggleButton";
import { UIStateForm } from "../StateForm/types";

import { useStateTransitionTranslations } from "./translation";
import { UICreateStateTransitionForm } from "./types";

enum RadioPermissions {
  everybody = "everybody",
  selected = "selected",
}

interface PermissionProps {
  node?: UIStateForm | UICreateStateTransitionForm;
  control: Control<FieldValues>;
}

export const PermissionComponent = memo<PermissionProps>(
  ({ node, control }) => {
    const translation = useStateTransitionTranslations();
    const defaultValue = (node?.acl ?? []).length
      ? RadioPermissions.selected
      : RadioPermissions.everybody;
    const [permissionType, setPermissionType] =
      useState<RadioPermissions>(defaultValue);

    const { data: roleOptions, isInitialLoading } = useRoles({
      select: getRoleOptions,
    });

    const handleChange = (_: MouseEvent<HTMLElement>, value: string | null) =>
      value && setPermissionType(value as RadioPermissions);

    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          gap={1}
        >
          <FormLabel component="div">{translation.formLabelContent}</FormLabel>
          <ToggleButtonGroup
            value={permissionType}
            exclusive
            onChange={handleChange}
            size="small"
            fullWidth
          >
            <ToggleButton
              tooltip={translation.everybodyTooltip}
              value={RadioPermissions.everybody}
            >
              {translation.everybodyToggleTitle}
            </ToggleButton>
            <ToggleButton
              tooltip={translation.selectedTooltip}
              value={RadioPermissions.selected}
            >
              {translation.selectedToggleTitle}
            </ToggleButton>
          </ToggleButtonGroup>
          <Controller
            render={({ field: { ref, ...inputField } }) => (
              <Autocomplete
                isMulti={true}
                options={roleOptions}
                disabled={
                  permissionType === RadioPermissions.everybody ||
                  !roleOptions ||
                  isInitialLoading
                }
                label={translation.permissionsLabelField}
                isLoading={false}
                innerRef={ref}
                {...inputField}
              />
            )}
            control={control}
            name="acl"
            defaultValue={node?.acl ?? []}
          />
        </Box>
      </>
    );
  },
);
