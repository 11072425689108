import { ChangeEvent, memo } from "react";
import { Box, Checkbox, TextField, Tooltip, Typography } from "@mui/material";
import { FixedSizeList, areEqual } from "react-window";

import { Tip } from "common/elements/Tip.tsx";
import { IFixedRow } from "core";
import Button from "elementTypes/common/Button";
import { LoadingComponent } from "layouts/common/Loading";
import { useRoles } from "queries/admin";
import { Role } from "queries/admin/types";
import { useSearchQuery } from "utils/hooks";
import { humanize } from "utils/string";
import { QueryGroup } from "../components/types";
import { usePermissionContext } from "../context/databasePanelPermission/PermissionContext.utils.ts";

import { BasicQuery } from "./queryBuilder/BasicQuery";
import { useStyles } from "./styles";
import { useERDTranslation } from "./translation";
import { NodeData, UIBasicQueryForm } from "./types";

type Props = {
  nodeData: NodeData;
  queryGroups?: QueryGroup[];
};

type IRow = IFixedRow<Role>;

const ITEM_SIZE = 44;

enum Permissions {
  select = "SELECT",
  insert = "INSERT",
  update = "UPDATE",
  delete = "DELETE",
}

export const QueryBuilder = memo<Props>(({ nodeData, queryGroups }) => {
  const data: UIBasicQueryForm = {
    tableName: nodeData.tableName,
    title: humanize(nodeData.tableName),
    queryGroupId: null,
  };
  return <BasicQuery data={data} queryGroups={queryGroups} />;
});

const cellStyles = (index: number) => ({
  display: "flex",
  alignItems: "center",
  px: 1,
  ...(index !== 0 && {
    justifyContent: "center",
  }),
});

export const PermissionTable = memo(() => {
  const { permissions = {}, setPermissions } = usePermissionContext();
  const {
    classes: { tableRow },
  } = useStyles({ selected: false });
  const { data: roles, isInitialLoading } = useRoles();

  const { filteredResult, searchValue, setSearchValue } = useSearchQuery(
    roles ?? [],
    [{ name: "name", weight: 0.7 }],
  );

  const filteredRoles = searchValue?.trim()
    ? filteredResult.map((result) => ({
        name: result.item.name,
      }))
    : roles;

  const translation = useERDTranslation();
  const headers = [translation.userColumnTitle, ...Object.values(Permissions)];

  const updatePermissions = (key: string, value: string[]) =>
    setPermissions({ ...permissions, [key]: value });

  const handleSearch = ({
    target: { value: newSearchQuery },
  }: ChangeEvent<HTMLInputElement>) => setSearchValue(newSearchQuery);

  const selectCellPermissions = (type: Permissions) => () => {
    if (!roles) {
      return;
    }

    const allPermissionsSet = roles.some(
      ({ name }: Role) => !permissions[name]?.includes(type),
    );

    const nextVal = roles.reduce(
      (res: Record<string, string[]>, role: Role) => ({
        ...res,
        [role.name]: allPermissionsSet
          ? [...new Set([...(permissions[role.name] ?? []), type])]
          : permissions[role.name]?.filter((permission) => permission !== type),
      }),
      {} as Record<string, string[]>,
    );

    setPermissions(nextVal);
  };

  const tableHead = headers.map((head, index: number) => (
    <Box key={head} {...cellStyles(index)}>
      {index !== 0 ? (
        <Button
          variant="text"
          tooltip={translation.columnSelectTooltip}
          label={head}
          onClick={selectCellPermissions(head as Permissions)}
        />
      ) : (
        head
      )}
    </Box>
  ));

  const Row = memo<IRow>(({ index, style }) => {
    if (!filteredRoles) {
      return null;
    }
    const name = filteredRoles[index].name;
    const rowPermissions = permissions[name] ?? [];

    const handleChange = (
      ev: ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      let nextVal = rowPermissions;
      if (checked) {
        nextVal = [...new Set([...nextVal, ev.target.name])];
      } else {
        nextVal = nextVal.filter((permission) => permission !== ev.target.name);
      }
      updatePermissions(name, nextVal);
    };

    const allPermissionsSet = () =>
      rowPermissions.length === Object.values(Permissions).length;

    const selectRowPermissions = () => {
      const nextVal = allPermissionsSet() ? [] : Object.values(Permissions);
      updatePermissions(name, nextVal);
    };

    const columns = headers.map((title, i) => (
      <Box
        key={`${name}-${title}${i}`}
        {...cellStyles(i)}
        style={{ cursor: "pointer" }}
      >
        {i === 0 ? (
          <Tooltip title={translation.rowSelectTooltip}>
            <Typography variant="body1">{name}</Typography>
          </Tooltip>
        ) : (
          <Checkbox
            name={title}
            checked={permissions[name]?.includes(title)}
            onChange={handleChange}
          />
        )}
      </Box>
    ));

    return (
      <Box
        style={style}
        display="grid"
        gridTemplateColumns=" 2fr repeat(4, 1fr)"
        onClick={selectRowPermissions}
        {...(index !== filteredRoles.length - 1 && {
          className: tableRow,
        })}
      >
        {columns}
      </Box>
    );
  }, areEqual);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pb={1}
      >
        <Box display="flex" gap={1} alignItems={"center"}>
          <Typography variant="h5">
            {translation.permissionTableTitle}
          </Typography>
          <Tip
            title={translation.permissionsTooltipTitle}
            text={translation.permissionsTooltipText}
          />
        </Box>
        <Box width="30%">
          <TextField
            onChange={handleSearch}
            value={searchValue}
            fullWidth
            label={translation.permissionTableSearchTitle}
            size="small"
            variant="standard"
          />
        </Box>
      </Box>
      <Box border="1px solid" borderRadius={1} borderColor="divider">
        <Box
          display="grid"
          gridTemplateColumns=" 2fr repeat(4, 1fr)"
          className={tableRow}
        >
          {tableHead}
        </Box>
        {isInitialLoading && <LoadingComponent />}
        {filteredRoles?.length && (
          <FixedSizeList
            height={ITEM_SIZE * Math.min(filteredRoles.length, 8)}
            itemCount={filteredRoles.length}
            itemSize={ITEM_SIZE}
            width="100%"
            itemData={filteredRoles}
          >
            {Row}
          </FixedSizeList>
        )}
      </Box>
    </>
  );
});
