import { useCallback, useMemo } from "react";
import { select } from "redux-saga/effects";

import { Translated, Translation } from "../../types";
import {
  getTranslatedText,
  getTranslatedTexts,
} from "../../utils/element-utils";
import { useSessionContext } from "../SessionContext";
import { selectors } from "../reduxModule";

export function useTranslator() {
  const { language } = useSessionContext();

  const translate = useCallback(
    <Keys extends keyof any>(translation: Translation<Keys>) =>
      getTranslatedTexts(language, translation),
    [language],
  );

  return { language, translate };
}

export function createUseTranslation<Keys extends keyof any>(
  translation: Translation<Keys>,
) {
  return function useCreatedTranslation(): Translated<Keys> &
    Record<string, string> {
    return useTranslation(translation);
  };
}

export function useTranslation<Keys extends keyof any>(
  translation?: Translation<Keys>,
): Translated<Keys> {
  const { translate } = useTranslator();
  const value = useMemo(
    () => (translation ? translate(translation) : ({} as Translated<Keys>)),
    [translate, translation],
  );
  return value;
}

export function* getTranslatedTextSaga<Key extends string>(
  translation: Translation<Key>,
  key: Key,
) {
  const language: ReturnType<typeof selectors.currentLanguage> = yield select(
    selectors.currentLanguage,
  );
  return getTranslatedText(language, translation, key);
}
