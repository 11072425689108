import {
  ChangeEvent,
  MouseEvent,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, FormLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
  useParentForm,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { getDateValue } from "utils/date";

import { UntransformedDateTimeInputConfig } from "../types";

import {
  getDateOrUndefined,
  getDefaultFormat,
  getType,
  toMoment,
} from "../utils";
import { ConfigComponent, TranslationComponent } from "./components";
import { useDateTimeInputEditorTranslation } from "./translation";

enum Format {
  date = "showDatePart",
  time = "showTimePart",
}

export const DateTimeInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const translation = useDateTimeInputEditorTranslation();
  const {
    elementModel: {
      config: {
        dataSource,
        defaultValue,
        showDatePart = true,
        showTimePart = true,
        maxDate,
        minDate,
        formatString,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeInputConfig>();
  const {
    fieldPath: [fieldName],
  } = dataSource ?? { fieldPath: [] };
  const { getParentViewField } = useParentForm();

  const format =
    showDatePart && showTimePart
      ? [Format.date, Format.time]
      : showDatePart
        ? [Format.date]
        : [Format.time];

  const isTimeOnly = useMemo(
    () => getParentViewField(fieldName as string)?.generalType.type === "time",
    [fieldName, getParentViewField],
  );

  useEffect(() => {
    if (isTimeOnly && showDatePart) {
      changeConfigValue(Format.date, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeOnly]);

  const changeLanguage = (l: Language) => setLang(l);

  const defaultFormat = useMemo(() => {
    return getDefaultFormat(getType(showDatePart, showTimePart));
  }, [showDatePart, showTimePart]);

  const handleDefaultValueInputChange = (
    date: Date | null,
    keyboardInput?: string,
  ) => {
    if (keyboardInput) {
      if (keyboardInput.length === defaultFormat.length) {
        changeConfigValue(
          "defaultValue",
          moment(keyboardInput, toMoment(defaultFormat)).toISOString(),
        );
      }
    } else {
      changeConfigValue("defaultValue", date ? date.toISOString() : null);
    }
  };

  const handleFormatChange = (
    _event: MouseEvent<HTMLElement>,
    value: [Format.time | Format.date],
  ) => {
    if (value?.length) {
      for (const f of Object.values(Format)) {
        changeConfigValue(f as Format.time | Format.date, value.includes(f));
        changeConfigValue("defaultValue", null);
      }
    }
  };

  const formats = Object.values(Format).map((f) => (
    <ToggleButton key={f} value={f} aria-label={f}>
      {translation[`${f}Label`]}
    </ToggleButton>
  ));

  const handleChangeFormatString = (event: ChangeEvent<HTMLInputElement>) => {
    const nextFormat = event.target.value.trim()
      ? event.target.value
      : undefined;

    changeConfigValue("formatString", nextFormat);
  };

  const dateProps = {
    value: defaultValue ? defaultValue : null,
    label: editorTranslation.defaultValueInputLabel,
    onChange: handleDefaultValueInputChange,
    disabled: Boolean(dataSource),
    minDate: getDateOrUndefined(getDateValue(minDate)),
    maxDate: getDateOrUndefined(getDateValue(maxDate)),
    inputFormat: defaultFormat,
  };

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["date", "dateTime", "time"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        {showDatePart && showTimePart ? (
          <DateTimePicker
            {...dateProps}
            renderInput={(props) => <TextField {...props} fullWidth />}
          />
        ) : showDatePart ? (
          <DatePicker
            {...dateProps}
            renderInput={(props) => <TextField {...props} fullWidth />}
          />
        ) : (
          <TimePicker
            {...dateProps}
            renderInput={(props) => <TextField {...props} fullWidth />}
          />
        )}
        <Box my={0.5} gap={1} display="flex" alignItems="center">
          <FormLabel>{translation.formatLabel}</FormLabel>
          <ToggleButtonGroup
            size="small"
            value={format}
            onChange={handleFormatChange}
            fullWidth
          >
            {formats}
          </ToggleButtonGroup>
        </Box>
        <TextField
          fullWidth
          value={formatString ?? ""}
          label={translation.formatStringLabel}
          onChange={handleChangeFormatString}
          placeholder="dd-MM-yyyy hh:mm"
          helperText={translation.formatHelperText}
        />
      </FormInputConfigEditorComponent>
      <ConfigComponent />
    </>
  );
});
