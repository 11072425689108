import { ConnectedProps, connect } from "react-redux";

import { selectors as routerSelectors } from "../../router/reduxModule";
import { actions as rmActions, selectors } from "../reduxModule";

import { EditableElementWrapper as EEComponent } from "./component";

const mapStateToProps = (state: any) => ({
  editModeOn: selectors.editModeOn(state),
  selected: selectors.selected(state),
  updatedElements: selectors.updatedElements(state),
  activeGrid: selectors.activeGrid(state),
  page: routerSelectors.page(state),
  shouldHighlightBorders:
    !!selectors.draggableElementParams(state) ||
    selectors.isDragging(state) ||
    selectors.isResizing(state),
});

const mapDispatchToProps = {
  selectElement: rmActions.selectElement,
  setActiveGrid: rmActions.setActiveGrid,
  unselectElement: rmActions.unselectElement,
  updateCopiedElements: rmActions.updateCopiedElements,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ReduxProps = ConnectedProps<typeof connector>;

export const EditableElementWrapper = connector(EEComponent);
