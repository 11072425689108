import { memo, useMemo } from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { buildCustomExpressionValue } from "core";
import { useElementEditorContext } from "core/editor/EditorLayout/components/ElementEditorContext";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { NonExpressionEditorProps } from "core/editor/common/CustomExpressionEditor/types";
import { Section } from "core/editor/common/Section";
import { getDateValue } from "utils/date";

import { UntransformedDateTimeInputConfig } from "../../types";
import { getDefaultFormat, toMoment } from "../../utils";
import { useDateTimeInputEditorTranslation } from "../translation";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { maxDate, minDate },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeInputConfig>();
  const translation = useDateTimeInputEditorTranslation();
  const defaultFormat = useMemo(() => {
    return getDefaultFormat("date");
  }, []);

  const customSelectDate =
    (_dateConfig: "minDate" | "maxDate") =>
    ({
      value: dateValue,
      onChange: onConfigChange,
    }: NonExpressionEditorProps) => {
      const handleChange = (date: Date | null, keyboardInput?: string) => {
        if (keyboardInput) {
          if (keyboardInput.length === defaultFormat.length) {
            onConfigChange(
              moment(keyboardInput, toMoment(defaultFormat)).toISOString(),
            );
          }
        } else {
          if (date) {
            onConfigChange(date.toISOString());
          }
        }
      };

      return (
        <DatePicker
          value={getDateValue(dateValue)}
          onChange={handleChange}
          renderInput={(props) => <TextField fullWidth {...props} />}
          inputFormat={defaultFormat}
        />
      );
    };

  const handleChange =
    (dateConfig: "minDate" | "maxDate") => (value: string) => {
      changeConfigValue(dateConfig, value);
    };

  const handleChangeTimezone = (value: string) => {
    changeConfigValue("timezone", value);
  };

  return (
    <Section title={translation.configTitle} wrapped={true}>
      <CustomExpressionEditor
        label={translation.minDateLabel}
        value={minDate ?? ""}
        config={config}
        onChange={handleChange("minDate")}
        nonExpressionEditor={customSelectDate("minDate")}
      />
      <CustomExpressionEditor
        label={translation.maxDateLabel}
        value={maxDate ?? ""}
        config={config}
        onChange={handleChange("maxDate")}
        nonExpressionEditor={customSelectDate("maxDate")}
      />
      <CustomExpressionEditor
        label={"Timezone"}
        value={config.timezone ?? buildCustomExpressionValue("null")}
        config={config}
        onChange={handleChangeTimezone}
      />
    </Section>
  );
});
