import { PropsWithChildren, memo, useEffect } from "react";
import { useEditorContext } from "core/editor";

// TODO: check if we can avoid this
import { Panel as EditorPanel } from "core/editor/EditorLayout/components/Panel/";
import { PaperComponent } from "./component";

export const AdminEditorLayout = memo<
  PropsWithChildren & {
    menuWidth: string;
    toggleOpen: () => void;
    toggleEditMode: () => void;
    logoUrl?: string;
    isStaticPage?: boolean;
    isAdminPage?: boolean;
    openState?: boolean;
  }
>(
  ({
    children,
    isAdminPage,
    isStaticPage,
    openState,
    menuWidth,
    logoUrl,
    toggleOpen,
    toggleEditMode,
  }) => {
    const context = useEditorContext();

    const editModeOn = context?.editModeOn ?? false;
    const dontShowMenu = isAdminPage || editModeOn;

    useEffect(() => {
      if (dontShowMenu && openState) {
        toggleOpen();
      }
    }, [dontShowMenu, openState, toggleOpen]);

    useEffect(() => {
      if (isStaticPage && editModeOn) {
        toggleEditMode();
      }
    }, [isStaticPage, editModeOn, toggleEditMode]);

    return (
      <PaperComponent
        {...{
          openState,
          editModeOn,
          menuWidth,
          logoUrl,
        }}
      >
        {editModeOn ? <EditorPanel>{children}</EditorPanel> : children}
      </PaperComponent>
    );
  },
);
