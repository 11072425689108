import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { getServerError } from "core/utils/api";

import { createWatcherSaga } from "core/utils/saga";
import { BuiltinResponseType } from "services/api/types/ResponseType";
import { GeoJSONField } from "../types";

import { Actions, Selectors, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: GeoJSONField,
  selectors: Selectors,
) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const responseFormat: BuiltinResponseType = yield select(
      selectors.responseFormat,
    );

    const { dataSource } = element.config;

    if (!dataSource?.viewName) {
      yield put(actions.loadSuccess(null));
      return;
    }

    try {
      const data: Record<string, unknown>[] | GeoJSON.FeatureCollection =
        yield call(services.api.loadViewData, token, {
          viewName: dataSource.viewName,
          params: {},
          responseFormat,
        });

      yield put(actions.loadSuccess(data ?? null));
    } catch (error) {
      yield put(actions.loadError(getServerError(error)));
    }
  }

  return function* mainSaga() {
    yield all([
      takeLatest(types.LOAD, loadSaga),
      createWatcherSaga(selectors.responseFormat, {
        onChange: loadSaga,
      }),
    ]);

    yield put(actions.load());
  };
}
