import { FC, memo, useEffect, useMemo, useReducer } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { shallowEqual as shallowEqualRedux, useSelector } from "react-redux";

import { useIsMounted } from "../../utils/hooks";

import { useElementTypesContext } from "../ElementTypesContext";
import ReduxModuleContext, {
  useReduxModuleContext,
} from "../ReduxModuleContext";
import { useReducerManager } from "../buildStore";
import { EditableElementWrapper } from "../editor";
import { selectors as editorSelectors } from "../editor/reduxModule";
import { useSessionContext } from "../session";
import { IElementModel, IElementProps } from "../types";
import { getTranslatedTexts } from "../utils/element-utils";
import { shallowEqual } from "../utils/shallowEqual";
import { FallbackElement } from "./Fallback";
import { initialState, reducer } from "./reducer";

type Props = {
  [name: string]: unknown;
  element: IElementModel;
  elementProps?: IElementProps;
  dynamic?: boolean;
};

const Element: FC<Props> = ({
  elementProps = {},
  element: elementModel,
  dynamic = false,
  ...props
}) => {
  const updatedElementModel =
    useSelector(
      (state) => editorSelectors.updatedElements(state)[elementModel.id],
      shallowEqualRedux,
    ) ?? elementModel;

  const isMounted = useIsMounted();

  const reduxModuleContext = useReduxModuleContext();
  const { getElementType } = useElementTypesContext();
  const reducerManager = useReducerManager();

  const { language } = useSessionContext();

  const [
    {
      element,
      module,
      elementType,
      childrenReduxModuleContext,
      error,
      elementModel: prevElementModel,
      elementProps: prevElementProps,
      getModule,
      unmount,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const translatedTexts = useMemo(
    () => (element ? getTranslatedTexts(language, element.i18n) : {}),
    [element, language],
  );

  useEffect(() => {
    const update = () => {
      const nextElementType = getElementType(updatedElementModel);
      try {
        const mounted = reducerManager.mountElement(
          updatedElementModel,
          reduxModuleContext,
          elementProps,
          dynamic,
        );
        dispatch({
          type: "updateElement",
          payload: {
            elementProps,
            elementModel: updatedElementModel,
            elementType: nextElementType,
            element: mounted.element,
            module: mounted.module,
            childrenReduxModuleContext: mounted.childrenReduxModuleContext,
            unmount: mounted.unmount,
            getModule: mounted.getModule,
          },
        });
      } catch (err) {
        // catches redux-saga bootstrapping (root saga) errors
        // does NOT catch redux-saga errors
        dispatch({
          type: "error",
          payload: {
            elementProps,
            error: err,
            elementModel: updatedElementModel,
            elementType: nextElementType,
          },
        });
      }
    };
    if (
      updatedElementModel !== prevElementModel ||
      !shallowEqual(elementProps, prevElementProps)
    ) {
      unmount?.();
      update();
    }

    return () => {
      if (!isMounted() && unmount) {
        unmount();
      }
    };
  }, [
    elementProps,
    updatedElementModel,
    prevElementProps,
    prevElementModel,
    reducerManager,
    reduxModuleContext,
    error,
    dynamic,
    getElementType,
    isMounted,
    unmount,
  ]);

  if (error) {
    // TODO: Handle error, but still display element
    return <FallbackElement error={error} elementModel={updatedElementModel} />;
  }

  let elementComponent: JSX.Element;

  if (!element || !getModule || !childrenReduxModuleContext || !elementType) {
    return null;
  } else {
    const { component: Component } = elementType;

    Component.displayName = `${element.type}Component`;

    const elementTranslated = {
      ...element,
      i18n: translatedTexts,
    };

    elementComponent = (
      // catches react errors
      <ErrorBoundary
        fallbackRender={FallbackElement}
        resetKeys={[elementModel, elementProps]}
      >
        <Component
          element={elementTranslated}
          elementModel={elementModel}
          module={module}
          getReduxModule={getModule}
          reduxContext={reduxModuleContext.context}
          {...props}
        />
      </ErrorBoundary>
    );
  }

  if (elementType && elementType.editorComponent) {
    elementComponent = (
      <EditableElementWrapper
        elementModel={elementModel}
        elementType={elementType}
      >
        {elementComponent}
      </EditableElementWrapper>
    );
  }

  if (childrenReduxModuleContext) {
    return (
      <ReduxModuleContext.Provider value={childrenReduxModuleContext}>
        {elementComponent}
      </ReduxModuleContext.Provider>
    );
  } else {
    return elementComponent;
  }
};

Element.displayName = "ElementWrapper";

const MemoizedElement = memo(Element);

export default MemoizedElement;
