import { memo } from "react";
import IconButton, { ButtonProps } from "elementTypes/common/IconButton";

import { RoutePaths } from "staticPages/routes";
import { PropsFromRedux } from "./container";
import { useAdminButtonTranslation } from "./translations";

type Props = PropsFromRedux & ButtonProps;

export const AdminButton = memo<Props>(({ color, editModeOn }) => {
  const { label } = useAdminButtonTranslation();

  const buttonProps = {
    icon: "admin_panel_settings",
    tooltip: label,
    color,
    placement: "bottom" as const,
    fontSize: "large" as const,
    disabled: editModeOn,
    href: RoutePaths.Admin,
  } as const;

  return (
    <IconButton
      {...buttonProps}
      sx={{ color: buttonProps.color }}
      size="small"
    />
  );
});
