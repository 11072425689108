import { memo } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useRouterParamByName } from "core/router/reduxModule/utils";
import BackButton from "elementTypes/common/BackButton";
import { RoutePaths } from "staticPages/routes";
import { useRoles, useUser } from "../../../../../../queries/admin";
import { getRoleOptions } from "../../../../../../queries/admin/utils";

import { Form } from "./components/Form";

export const EditUsersPage = memo(() => {
  const userId = useRouterParamByName("userId");

  const { data: roleOptions } = useRoles({
    select: getRoleOptions,
  });

  const user = useUser({ userId }, { enabled: !!userId });
  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={RoutePaths.Users} />
        <Typography variant="h5">Editing - {user?.data?.email}</Typography>
      </Box>
      {user?.data && roleOptions && (
        <Form roleOptions={roleOptions} user={user?.data} />
      )}
    </>
  );
});
