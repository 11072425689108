import { memo } from "react";
import Link from "@mui/material/Link";

import Button from "elementTypes/common/Button";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { Colors } from "elementTypes/common/StyledTypography/utils";
import { Props } from "./container";
import useStyles from "./styles";

const DefaultExternalLinkField = memo<Props>(
  ({
    element: {
      config: { isButton, icon, variant },
      i18n: { label: i18nLabel },
    },
    color = "default",
    link,
  }) => {
    const { classes } = useStyles();
    const target = "_blank";

    if (isButton) {
      return (
        <Button
          href={link}
          external
          target={target}
          iconRight={icon}
          rel="noopener noreferrer"
          color={Colors[color]}
          variant={variant}
          className={classes.button}
          label={i18nLabel ?? ""}
        />
      );
    }

    return (
      <Link
        href={link ?? ""}
        target={target}
        rel="noopener noreferrer"
        className={classes.text}
      >
        {color ? (
          <StyledTypography color={color} text={i18nLabel ?? ""} />
        ) : (
          i18nLabel ?? ""
        )}
      </Link>
    );
  },
);

DefaultExternalLinkField.displayName = "DefaultExternalLinkField";

export default DefaultExternalLinkField;
