import { MouseEventHandler, memo, useEffect, useRef, useState } from "react";
import { TreeItem } from "@mui/lab";
import { Box, Tooltip, Typography } from "@mui/material";

import prettyBytes from "pretty-bytes";
import { isOverflowing } from "utils/string";
import { useStyles } from "./styles";

interface IProps {
  onClick?: MouseEventHandler<HTMLLIElement>;
  schema: string;
  table: string;
  tableSchema: string;
  tableSize?: number;
  disabled?: boolean;
}

const TableDetails = memo<IProps>(
  ({ onClick, schema, table, tableSize, tableSchema, disabled }) => {
    const textContainerRef = useRef<HTMLSpanElement | null>(null);
    const { classes } = useStyles();
    const [textOverflows, setTextOverflows] = useState(false);

    useEffect(() => {
      const overflow = isOverflowing(textContainerRef.current);
      setTextOverflows(overflow);
    }, [textContainerRef.current?.offsetWidth]);

    return (
      <TreeItem
        key={`table-${schema}.${table} `}
        nodeId={`table-${schema}.${table}`}
        disabled={disabled}
        label={
          <Box
            display="grid"
            justifyContent="space-between"
            gap={1}
            alignItems="center"
            gridTemplateColumns="1fr max-content"
          >
            {textOverflows ? (
              <Tooltip
                title={`${tableSchema}.${table}`}
                PopperProps={{
                  disablePortal: true,
                }}
                disableFocusListener
                disableTouchListener
                arrow
              >
                <Typography
                  className={classes.textOverflow}
                  ref={textContainerRef}
                >
                  {table}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                className={classes.textOverflow}
                ref={textContainerRef}
              >
                {table}
              </Typography>
            )}
            {tableSize ? (
              <Typography variant="overline">
                {prettyBytes(Number(tableSize))}
              </Typography>
            ) : null}
          </Box>
        }
        onClick={onClick}
      />
    );
  },
);

export default TableDetails;
