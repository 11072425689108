import {
  IDefaultElement,
  IElement,
  IElementModel,
  IElementType,
  IMenuItem,
  IObjectView,
  IPage,
  IPageParams,
  IUiReleaseOverview,
  IUiSavePoint,
  LayoutDefinition,
  QueryToPagesMappingValue,
  TCompiledRoute,
  TCssGridConfig,
  TElementModelWithPosition,
  Translation,
} from "../../types";
import { createAction, createActionTypeScoper } from "../../utils/redux";

import { MODULE_NAME } from "./constants";
import { ISelectedElement } from "./types";
import { DraggableParams } from ".";

const scopeActionType = createActionTypeScoper(MODULE_NAME);

export const types = {
  LOAD_VIEWS: scopeActionType("LOAD_VIEWS"),
  LOAD_VIEWS_SUCCESS: scopeActionType("LOAD_VIEWS_SUCCESS"),
  LOAD_VIEWS_ERROR: scopeActionType("LOAD_VIEWS_ERROR"),
  EDIT_MODE_TOGGLE: scopeActionType("EDIT_MODE_TOGGLE"),
  ELEMENT_SELECT: scopeActionType("ELEMENT_SELECT"),
  ELEMENT_UNSELECT: scopeActionType("ELEMENT_UNSELECT"),
  ELEMENT_HIGHLIGHT: scopeActionType("ELEMENT_HIGHLIGHT"),
  ELEMENT_DRAGGABLE: scopeActionType("ELEMENT_DRAGGABLE"),
  ELEMENT_SET_IS_DRAGGING: scopeActionType("ELEMENT_SET_IS_DRAGGING"),
  ELEMENT_SET_IS_RESIZING: scopeActionType("ELEMENT_SET_IS_RESIZING"),
  ELEMENT_CREATE: scopeActionType("ELEMENT_CREATE"),
  ELEMENT_DELETE: scopeActionType("ELEMENT_DELETE"),
  ELEMENT_ADD: scopeActionType("ELEMENT_ADD"),
  CONFIG_VALUE_CHANGE: scopeActionType("CONFIG_VALUE_CHANGE"),
  POSITION_VALUE_CHANGE: scopeActionType("POSITION_VALUE_CHANGE"),
  PAGE_CREATE: scopeActionType("PAGE_CREATE"),
  PAGE_CREATE_SUCCESS: scopeActionType("PAGE_CREATE_SUCCESS"),
  PAGE_CREATE_ERROR: scopeActionType("PAGE_CREATE_ERROR"),
  PAGE_UPDATE: scopeActionType("PAGE_UPDATE"),
  PAGE_UPDATE_SUCCESS: scopeActionType("PAGE_UPDATE_SUCCESS"),
  PAGE_UPDATE_ERROR: scopeActionType("PAGE_UPDATE_ERROR"),
  PAGE_UPDATE_IDENTIFIER_LABEL_EXPRESSION: scopeActionType(
    "PAGE_UPDATE_IDENTIFIER_LABEL_EXPRESSION",
  ),
  PAGE_DELETE: scopeActionType("PAGE_DELETE"),
  PAGE_DELETE_SUCCESS: scopeActionType("PAGE_DELETE_SUCCESS"),
  NEXT_ELEMENT_ID_UPDATE: scopeActionType("NEXT_ELEMENT_ID_UPDATE"),
  TRANSLATION_VALUE_CHANGE: scopeActionType("TRANSLATION_VALUE_CHANGE"),
  UPDATE_ELEMENTS: scopeActionType("UPDATE_ELEMENTS"),
  GRID_SET_ACTIVE: scopeActionType("GRID_SET_ACTIVE"),
  EDIT_MODE_EXIT: scopeActionType("EDIT_MODE_EXIT"),
  LOAD_UI_RELEASES: scopeActionType("LOAD_UI_RELEASES"),
  LOAD_UI_RELEASES_SUCCESS: scopeActionType("LOAD_UI_RELEASES_SUCCESS"),
  LOAD_UI_RELEASES_ERROR: scopeActionType("LOAD_UI_RELEASES_ERROR"),
  LOAD_UI_SAVE_POINTS: scopeActionType("LOAD_UI_SAVE_POINTS"),
  LOAD_UI_SAVE_POINTS_SUCCESS: scopeActionType("LOAD_UI_SAVE_POINTS_SUCCESS"),
  LOAD_UI_SAVE_POINTS_ERROR: scopeActionType("LOAD_UI_SAVE_POINTS_ERROR"),
  RELEASE: scopeActionType("RELEASE"),
  RELEASE_SUCCESS: scopeActionType("RELEASE_SUCCESS"),
  RELEASE_ERROR: scopeActionType("RELEASE_ERROR"),
  PUBLISH_RELEASE: scopeActionType("PUBLISH_RELEASE"),
  SAVE: scopeActionType("SAVE"),
  SAVE_SUCCESS: scopeActionType("SAVE_SUCCESS"),
  SAVE_ERROR: scopeActionType("SAVE_ERROR"),
  ELEMENT_CHILDREN_UPDATE: scopeActionType("ELEMENT_CHILDREN_UPDATE"),
  UPDATE_LAYOUT_DEFINITION: scopeActionType("UPDATE_LAYOUT_DEFINITION"),
  SET_ERRORS: scopeActionType("SET_ERRORS"),
  DISCARD_CHANGES: scopeActionType("DISCARD_CHANGES"),
  UPDATE_MENU_ENTRIES: scopeActionType("UPDATE_MENU_ENTRIES"),
  RESTORE_SAVE_POINT: scopeActionType("RESTORE_SAVE_POINT"),
  CLEAN_ACTIONS_ERRORS: scopeActionType("CLEAN_ACTIONS_ERRORS"),
  SET_ROUTES: scopeActionType("SET_ROUTES"),
  INITIALIZE_SELECTION: scopeActionType("INITIALIZE_SELECTION"),
  UPDATE_QUERY_TO_PAGES_MAPPING: scopeActionType(
    "UPDATE_QUERY_TO_PAGES_MAPPING",
  ),
  UPDATE_COPIED_ELEMENTS: scopeActionType("UPDATE_COPIED_ELEMENTS"),
};

export const actions = {
  loadUiReleases: createAction(types.LOAD_UI_RELEASES, (uiName: string) => ({
    uiName,
  })),
  loadUiReleasesSuccess: createAction(
    types.LOAD_UI_RELEASES_SUCCESS,
    (uiReleases: IUiReleaseOverview[]) => ({ uiReleases }),
  ),
  loadUiReleasesError: createAction(
    types.LOAD_UI_RELEASES_ERROR,
    (error: string) => ({ error }),
  ),
  loadUiSavePoints: createAction(
    types.LOAD_UI_SAVE_POINTS,
    (uiName: string) => ({
      uiName,
    }),
  ),
  loadUiSavePointsSuccess: createAction(
    types.LOAD_UI_SAVE_POINTS_SUCCESS,
    (uiSavePoints: IUiSavePoint[]) => ({
      uiSavePoints,
    }),
  ),
  loadUiSavePointsError: createAction(
    types.LOAD_UI_SAVE_POINTS_ERROR,
    (error: string) => ({ error }),
  ),
  restoreSavePoint: createAction(
    types.RESTORE_SAVE_POINT,
    (uiName: string, savePointId: string) => ({
      uiName,
      savePointId,
    }),
  ),
  setErrors: createAction(
    types.SET_ERRORS,
    (errors: Record<string, string>) => ({ errors }),
  ),
  setRoutes: createAction(types.SET_ROUTES, (newRoutes: TCompiledRoute[]) => ({
    newRoutes,
  })),
  cleanActionsErrors: createAction(types.CLEAN_ACTIONS_ERRORS),
  loadViews: createAction(types.LOAD_VIEWS),
  loadViewsSuccess: createAction(
    types.LOAD_VIEWS_SUCCESS,
    (viewList: IObjectView[]) => ({ viewList }),
  ),
  loadViewsError: createAction(types.LOAD_VIEWS_ERROR, (error: string) => ({
    error,
  })),
  toggleEditMode: createAction(types.EDIT_MODE_TOGGLE),
  exitEditMode: createAction(types.EDIT_MODE_EXIT),
  selectElement: createAction(
    types.ELEMENT_SELECT,
    (element: IElement | IElementModel, type: IElementType, page: IPage) => ({
      element,
      type,
      page,
    }),
  ),
  unselectElement: createAction(types.ELEMENT_UNSELECT, (page: IPage) => ({
    page,
  })),
  highlightElement: createAction(
    types.ELEMENT_HIGHLIGHT,
    (page: IPage, element?: IElement | IElementModel, type?: IElementType) => ({
      element,
      type,
      page,
    }),
  ),
  setActiveGrid: createAction(
    types.GRID_SET_ACTIVE,
    (element: IElementModel | TElementModelWithPosition, page: IPage) => ({
      element,
      page,
    }),
  ),
  updateElements: createAction(
    types.UPDATE_ELEMENTS,
    (
      updatedElements: Record<
        string,
        IElementModel | TElementModelWithPosition
      >,
      selected: ISelectedElement | null,
      page: IPage,
    ) => ({
      updatedElements,
      selected,
      page,
    }),
  ),
  updateLayoutDefinition: createAction(
    types.UPDATE_LAYOUT_DEFINITION,
    (updatedLayoutDefinition: LayoutDefinition | null) => ({
      updatedLayoutDefinition,
    }),
  ),
  updateMenuEntries: createAction(
    types.UPDATE_MENU_ENTRIES,
    (updatedMenu: IMenuItem[] | null) => ({
      updatedMenu,
    }),
  ),
  updateQueryToPagesMapping: createAction(
    types.UPDATE_QUERY_TO_PAGES_MAPPING,
    (
      updatedQueryToPagesMapping: Record<
        string,
        QueryToPagesMappingValue
      > | null,
    ) => ({
      updatedQueryToPagesMapping,
    }),
  ),

  changeConfigValue: createAction(
    types.CONFIG_VALUE_CHANGE,
    (
      selected: ISelectedElement,
      keyOrConfig: string | Record<string, unknown>,
      value?: any,
    ) => ({
      selected,
      keyOrConfig,
      value,
    }),
  ),
  changeTranslationValue: createAction(
    types.TRANSLATION_VALUE_CHANGE,
    (selected: ISelectedElement, value: Translation<string>) => ({
      selected,
      value,
    }),
  ),
  changePositionValue: createAction(
    types.POSITION_VALUE_CHANGE,
    (selected: ISelectedElement, key: string, value: any) => ({
      selected,
      key,
      value,
    }),
  ),
  setDraggableElement: createAction(
    types.ELEMENT_DRAGGABLE,
    (params: DraggableParams) => ({ params }),
  ),
  setIsElementDragging: createAction(
    types.ELEMENT_SET_IS_DRAGGING,
    (flag: boolean) => ({ flag }),
  ),
  setIsElementResizing: createAction(
    types.ELEMENT_SET_IS_RESIZING,
    (flag: boolean) => ({ flag }),
  ),
  createElement: createAction(
    types.ELEMENT_CREATE,
    (
      elementTypes: Record<string, IElementType>,
      type: IElementType,
      page: IPage,
      position: TCssGridConfig,
      rootElement: IElementModel | TElementModelWithPosition,
      defaultElement?: IDefaultElement,
      elementName?: string,
      childName?: string,
      disableSelection?: boolean,
      formElementModel?: IElementModel | TElementModelWithPosition,
    ) => ({
      elementTypes,
      type,
      page,
      position,
      rootElement,
      defaultElement,
      elementName,
      childName,
      disableSelection,
      formElementModel,
    }),
  ),
  deleteElement: createAction(
    types.ELEMENT_DELETE,
    (
      element: IElementModel | TElementModelWithPosition,
      elementTypes: Record<string, IElementType>,
      page: IPage,
      rootElement: IElementModel | TElementModelWithPosition,
    ) => ({
      element,
      elementTypes,
      page,
      rootElement,
    }),
  ),
  addElement: createAction(
    types.ELEMENT_ADD,
    (
      element: IElementModel | TElementModelWithPosition,
      elementTypes: Record<string, IElementType>,
      page: IPage,
      rootElement: IElementModel | TElementModelWithPosition,
    ) => ({
      element,
      elementTypes,
      page,
      rootElement,
    }),
  ),
  createPage: createAction(
    types.PAGE_CREATE,
    (
      elementTypes: Record<string, IElementType>,
      name: string,
      i18n: Translation<"label">,
      generateMenu: boolean,
      params?: IPageParams,
    ) => ({
      elementTypes,
      name,
      i18n,
      generateMenu,
      params,
    }),
  ),
  createPageSuccess: createAction(
    types.PAGE_CREATE_SUCCESS,
    (page: IPage, sampleUrl: string, pages: Record<"string", IPage>) => ({
      page,
      sampleUrl,
      pages,
    }),
  ),
  createPageError: createAction(
    types.PAGE_CREATE_ERROR,
    (error: string | null) => ({
      error,
    }),
  ),
  updatePage: createAction(
    types.PAGE_UPDATE,
    (
      page: IPage,
      i18n: Translation<"label">,
      generateMenu: boolean,
      params?: IPageParams,
    ) => ({ page, i18n, params, generateMenu }),
  ),
  updatePageSuccess: createAction(
    types.PAGE_UPDATE_SUCCESS,
    (page: IPage, pages: Record<"string", IPage>) => ({
      page,
      pages,
    }),
  ),
  updatePageIdentifierLabelExpression: createAction(
    types.PAGE_UPDATE_IDENTIFIER_LABEL_EXPRESSION,
    (page: IPage, value: string, pages: Record<"string", IPage>) => ({
      page,
      value,
      pages,
    }),
  ),
  deletePage: createAction(types.PAGE_DELETE, (page: IPage) => ({
    page,
  })),
  deletePageSuccess: createAction(
    types.PAGE_DELETE_SUCCESS,
    (page: IPage, pages: Record<"string", IPage>, sampleUrl: string) => ({
      page,
      pages,
      sampleUrl,
    }),
  ),
  updateNextElementId: createAction(
    types.NEXT_ELEMENT_ID_UPDATE,
    (id: number) => id,
  ),
  release: createAction(
    types.RELEASE,
    (data: { name: string; description: string }) => data,
  ),
  releaseSuccess: createAction(types.RELEASE_SUCCESS),
  releaseError: createAction(types.RELEASE_ERROR, (error: string) => error),
  publishRelease: createAction(
    types.PUBLISH_RELEASE,
    (uiName: string, releaseName: string) => ({ uiName, releaseName }),
  ),
  save: createAction(types.SAVE, (description: string) => description),
  saveSuccess: createAction(types.SAVE_SUCCESS),
  saveError: createAction(types.SAVE_ERROR, (error: string) => error),
  updateElementChildren: createAction(
    types.ELEMENT_CHILDREN_UPDATE,
    (
      element: IElementModel | TElementModelWithPosition,
      children:
        | IElementModel[]
        | TElementModelWithPosition[]
        | IElementModel
        | TElementModelWithPosition,
      page: IPage,
      childName?: string,
      childKey?: string,
    ) => ({
      element,
      children,
      page,
      childName,
      childKey,
    }),
  ),
  updateCopiedElements: createAction(
    types.UPDATE_COPIED_ELEMENTS,
    (
      element: IElementModel | TElementModelWithPosition,
      action: "add" | "delete" | "update",
    ) => ({
      element,
      action,
    }),
  ),
  discardChanges: createAction(types.DISCARD_CHANGES),
  /**
   * set root grid to active grid by default
   */
  initializeSelection: createAction(
    types.INITIALIZE_SELECTION,
    (page: IPage) => ({
      page,
    }),
  ),
};
