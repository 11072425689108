import {
  Key,
  compile,
  pathToRegexp,
  parse as regexParse,
} from "path-to-regexp";
import { parseUrl as QSParseUrl, parse, stringify } from "query-string";
import { useSelector } from "react-redux";

import {
  IAppMetadata,
  IElementModel,
  IPage,
  Language,
  TCompiledRoute,
  TStaticCompiledRoute,
  Translation,
} from "../../types";

import { APP_URL } from "./constants";
import { selectors } from "./selectors";
import { IRouteMatch, IStaticRouteMatch } from "./types";

/*
 * This is a helper to convert deep queries into shallow queries, because 'query-string' purposely
 * doesn't support nested queries as stated in the `Nesting` section
 * of https://github.com/sindresorhus/query-string
 */
export function stringifyQueries(params: any) {
  const shallowQueries = {} as any;
  for (const k of Object.keys(params)) {
    shallowQueries[k] = JSON.stringify(params[k]);
  }
  return stringify(shallowQueries);
}

/*
 * This is a helper to convert a queryString into a deep query value, because 'query-string' purposely
 * doesn't support nested queries as stated in the `Nesting` section
 * of https://github.com/sindresorhus/query-string
 */
export function parseQueries(queryString: string) {
  const result = {} as any;
  const shallowQueries = parse(queryString) as any;
  for (const k of Object.keys(shallowQueries)) {
    result[k] = JSON.parse(shallowQueries[k]);
  }
  return result;
}

export function compileRoutes(appMetadata: IAppMetadata) {
  const { pages } = appMetadata.release.definition;
  return Object.keys(pages).map<TCompiledRoute>((id) =>
    compileRoute(pages[id]),
  );
}

export function compileRoute(page: IPage): TCompiledRoute {
  const keys: Key[] = [];
  /* TODO:
   * In the section 'Compatibility with Express <= 4.x' of https://github.com/pillarjs/path-to-regexp
   * it is explained that wildcard tokens are not supported. Instead, path-to-regexp uses '(.*)'.
   * We can leave this replace here or change the way we write urls server-side.
   */

  const pageUrlWithSlash = withLeadingSlash(page.url);

  const prefixedPageUrl = prefixPageUrl(pageUrlWithSlash);
  const url = prefixedPageUrl.replace("*", "(.*)");
  const re = pathToRegexp(url, keys);
  return [re, keys, page.id, url];
}

const getParams = (match: RegExpExecArray, keys: Key[]) => {
  const params = Object.create(null);
  for (let i = 1; i < match.length; i++) {
    params[keys[i - 1].name] = match[i] !== undefined ? match[i] : undefined;
  }

  return params;
};

function checkParams(
  allowedParams: IPage["params"],
  currentQuery: Record<string, unknown>,
) {
  return !Object.keys(allowedParams).find(
    (param) => currentQuery[param] === undefined,
  );
}

export function matchRoute(
  routes: TCompiledRoute[],
  staticRoutes: TStaticCompiledRoute[],
  url: string,
  queries: Record<string, unknown>,
  allPages: Record<string, IPage> | undefined,
): IRouteMatch | IStaticRouteMatch | null {
  for (const [pattern, , pageId] of routes) {
    const page = allPages?.[pageId];
    if (page) {
      const match = pattern.exec(url);
      if (match && checkParams(page.params || {}, queries)) {
        return {
          page,
          params: page.params || {},
        };
      }
    }
  }

  for (const { pattern, keys, id, isAdmin, auth } of staticRoutes) {
    let match = pattern.exec(url);
    if (!match) {
      match = pattern.exec(`${url}?${stringify(queries)}`);
    }
    if (match) {
      const params = getParams(match, keys);
      return {
        params,
        auth,
        isAdmin,
        staticPageId: id,
      };
    }
  }

  return null;
}

type CreatePage = {
  id: string;
  url: string;
  element: IElementModel;
  i18n: Translation<"label">;
  language: Language;
};

export function createPage({ url, element, i18n, id }: CreatePage): IPage {
  return {
    id,
    url,
    element,
    params: {},
    default: false, // TODO: what is this?
    i18n,
  };
}

export function getSampleUrl(urlPattern: string) {
  const tokens = regexParse(urlPattern);
  return compile(urlPattern)(
    tokens.reduce(
      (all, t) => (typeof t === "string" ? all : { ...all, [t.name]: "param" }),
      {},
    ),
  );
}

/**
 * make sure that url has a leading slash
 * @param url
 */
export function withLeadingSlash(url: string) {
  return url.startsWith("/") ? url : `/${url}`;
}

export function parseUrl(url: string) {
  return QSParseUrl(url);
}

/**
 * prefix the url with the page prefix
 * @param url
 */
export function prefixPageUrl(url: string) {
  const urlWithLeadingSlash = withLeadingSlash(url);
  return urlWithLeadingSlash.startsWith(`/${APP_URL}`) // backwards compatibility
    ? urlWithLeadingSlash
    : `/${APP_URL}${urlWithLeadingSlash}`;
}

export function useRouterParamByName<T = string>(
  param: string,
  defaultValue?: T,
) {
  const routerParams = useSelector(selectors.params);
  if (!routerParams?.[param]) {
    if (defaultValue === undefined) {
      throw new Error(`Param ${param} does not exist.`);
    }
    return defaultValue;
  }
  return routerParams[param];
}
