import { createTypeSafeContext } from "../utils/createTypeSafeContext";

import { IElementModel, IElementType } from "./types";

interface IElementTypesContext {
  elementTypes: Record<string, IElementType>;
  availableElementTypes: Record<string, IElementType>;
  getElementType: (element: IElementModel) => IElementType;
}

const { Provider, useTypeSafeContext } =
  createTypeSafeContext<IElementTypesContext>();

export const useElementTypesContext = useTypeSafeContext;
export const ElementTypesProvider = Provider;
