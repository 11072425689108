import { memo, useCallback } from "react";
import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import { useCustomChartEditorTranslation } from "../../translation";
import { UntransformedCustomChartConfig } from "../../types";

export const OptionComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { option },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCustomChartConfig>();
  const translation = useCustomChartEditorTranslation();

  const handleExpressionValueChange = useCallback(
    (newValue: string) => changeConfigValue("option", newValue),
    [changeConfigValue],
  );

  return (
    <Section title={translation.optionTitle} wrapped={true}>
      <CustomExpressionEditor
        value={option as any}
        config={config}
        onChange={handleExpressionValueChange}
        disableSwitcher={true}
      />
    </Section>
  );
});
