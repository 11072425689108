import { memo, useCallback } from "react";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

import { ResponseFormatAutocomplete } from "core/editor/common/ResponseFormat";
import { BuiltinResponseType } from "services/api/types/ResponseType";
import { UntransformedMetaQueryConfig } from "../types";
import { useMetaQueryEditorTranslation } from "./translation";

export const DataSourceEditor = memo(() => {
  const {
    elementModel: {
      config,
      config: { dataSource, filter },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedMetaQueryConfig>();

  const t = useMetaQueryEditorTranslation();

  const changeDataSource = useCallback(
    (newDataSource: UntransformedMetaQueryConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const { queryName = "", responseFormat = "json" } = dataSource;

  const changeFilter = useCallback(
    (value?: string) =>
      changeConfigValue("filter", value?.trim().length ? value : undefined),
    [changeConfigValue],
  );

  const handleQueryNameChange = (newQueryName: string) => {
    changeDataSource({
      ...dataSource,
      queryName: newQueryName,
    });
  };

  const handleResponseFormatChange = (newFormat: BuiltinResponseType) => {
    changeDataSource({
      ...dataSource,
      responseFormat: newFormat,
    });
  };

  const { dataSourceTitle, viewLabel } = useEditorTranslation();

  return (
    <Section title={dataSourceTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={queryName}
        viewLabel={viewLabel}
        onViewNameChange={handleQueryNameChange}
      />
      <CustomExpressionEditor
        value={filter ?? ""}
        config={config}
        onChange={changeFilter}
        label={t.filterLabel}
        disableSwitcher
      />
      <ResponseFormatAutocomplete
        value={responseFormat as BuiltinResponseType}
        onChange={handleResponseFormatChange}
      />
    </Section>
  );
});
