import { memo } from "react";
import { Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { DEFAULT_APP_URL } from "core/router/reduxModule";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import IconButton from "elementTypes/common/IconButton";
import { TrialLabel } from "elementTypes/common/TrialLabel";
import { AppBarMenu } from "layouts/common/AppBarMenu";

import { DOCS_URL } from "../const";
import useStyles from "../styles";

export const AdminHeader = memo(() => {
  const {
    classes: { appBar },
  } = useStyles();
  const ui = useSelector(sessionSelectors.ui);

  return (
    <AppBar
      color="primary"
      position="static"
      variant="outlined"
      className={appBar}
      elevation={0}
    >
      <Toolbar variant="dense" sx={{ gap: 1 }}>
        <Stack
          spacing={2}
          direction="row"
          flex={1}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography component="h1" variant="h6" color="inherit" noWrap={true}>
            Administration
          </Typography>
          <TrialLabel />
        </Stack>
        {ui && (
          <IconButton
            icon="screen_share"
            tooltip="Back To the latest App"
            href={DEFAULT_APP_URL}
            color="inherit"
            fontSize="large"
          />
        )}
        <Button
          color="inherit"
          variant="outlined"
          href={DOCS_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Docs
        </Button>
        <AppBarMenu />
      </Toolbar>
    </AppBar>
  );
});
