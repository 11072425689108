import { OptionsObject } from "notistack";
import { AuthMeResponse } from "services/api";

import {
  Definition,
  IAppMetadata,
  IUi,
  IUiSavePoint,
  Language,
} from "../../types";
import { createAction, createActionTypeScoper } from "../../utils/redux";
import { Notification } from "../Notifier/types";

import { MODULE_NAME } from "./constants";

const scopeActionType = createActionTypeScoper(MODULE_NAME);

export const types = {
  APP_METADATA_LOAD: scopeActionType("APP_METADATA_LOAD"),
  APP_METADATA_LOAD_SUCCESS: scopeActionType("APP_METADATA_LOAD_SUCCESS"),
  APP_METADATA_LOAD_ERROR: scopeActionType("APP_METADATA_LOAD_ERROR"),
  APP_METADATA_REPLACE: scopeActionType("APP_METADATA_REPLACE"),
  APP_METADATA_FINISHED: scopeActionType("APP_METADATA_FINISHED"),
  LOGIN: scopeActionType("LOGIN"),
  LOGIN_SUCCESS: scopeActionType("LOGIN_SUCCESS"),
  LOGIN_ERROR: scopeActionType("LOGIN_ERROR"),
  LOGOUT: scopeActionType("LOGOUT"),
  UI_LIST_SET: scopeActionType("UI_LIST_SET"),
  UI_CHANGE: scopeActionType("UI_CHANGE"),
  SNACKBAR_ENQUEUE: scopeActionType("SNACKBAR_ENQUEUE"),
  SNACKBAR_CLOSE: scopeActionType("SNACKBAR_CLOSE"),
  SNACKBAR_REMOVE: scopeActionType("SNACKBAR_REMOVE"),
  SNACKBAR_LIST_SET: scopeActionType("SNACKBAR_LIST_SET"),
  LANGUAGE_CHANGE: scopeActionType("LANGUAGE_CHANGE"),
  // get user information
  USER_LOAD: scopeActionType("USER_LOAD"),
  USER_SUCCESS: scopeActionType("USER_SUCCESS"),
  USER_ERROR: scopeActionType("USER_ERROR"),
  BOOTSTRAP: scopeActionType("BOOTSTRAP"),
};

export const actions = {
  loadAppMetadata: createAction(
    types.APP_METADATA_LOAD,
    (ui: IUi, redirect?: string) => ({
      ui,
      redirect,
    }),
  ),
  loadAppMetadataSuccess: createAction(
    types.APP_METADATA_LOAD_SUCCESS,
    (ui: IUi, appMetadata: IAppMetadata, redirect?: string) => ({
      ui,
      appMetadata,
      redirect,
    }),
  ),
  loadAppMetadataError: createAction(
    types.APP_METADATA_LOAD_ERROR,
    (error: string) => ({ error }),
  ),
  loadAppMetadataFinished: createAction(types.APP_METADATA_FINISHED),
  replaceAppMetadata: createAction(
    types.APP_METADATA_REPLACE,
    (params: {
      name: string;
      nextSavePoint: IUiSavePoint;
      nextDefinition: Definition;
    }) => params,
  ),
  login: createAction(
    types.LOGIN,
    (payload: { identifier: string; password: string }) => payload,
  ),
  loginSuccess: createAction(
    types.LOGIN_SUCCESS,
    ({ token }: { token: string }) => ({
      token,
    }),
  ),
  loginError: createAction(types.LOGIN_ERROR, (error: string) => ({ error })),
  loadUser: createAction(types.USER_LOAD, ({ token }: { token: string }) => ({
    token,
  })),
  loadUserSuccess: createAction(
    types.USER_SUCCESS,
    ({
      isAdmin,
      additionalInformation,
    }: {
      isAdmin: boolean;
      additionalInformation: AuthMeResponse["additionalInformation"];
    }) => ({
      isAdmin,
      additionalInformation,
    }),
  ),
  loadUserError: createAction(types.USER_ERROR, (error: string) => ({ error })),
  logout: createAction(types.LOGOUT, (redirect?: string) => redirect),
  setUiList: createAction(types.UI_LIST_SET, (list: IUi[]) => list),
  changeUi: createAction(types.UI_CHANGE, (ui: IUi) => ui),
  enqueueSnackbar: createAction(
    types.SNACKBAR_ENQUEUE,
    (notification: Notification) => ({
      ...notification,
      key:
        (notification.options && notification.options.key) ||
        new Date().getTime() + Math.random(),
    }),
  ),
  closeSnackbar: createAction(
    types.SNACKBAR_CLOSE,
    (key?: OptionsObject["key"]) => ({
      dismissAll: !key,
      key,
    }),
  ),
  removeSnackbar: createAction(
    types.SNACKBAR_REMOVE,
    (key: OptionsObject["key"]) => key,
  ),
  setSnackbarList: createAction(
    types.SNACKBAR_LIST_SET,
    (notifications: Notification[]) => notifications,
  ),
  changeLanguage: createAction(
    types.LANGUAGE_CHANGE,
    (lang: Language, sync = true) => ({ lang, sync }),
  ),
  bootstrap: createAction(types.BOOTSTRAP),
};
