import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import { editorTranslation } from "core/editor";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation";
import { getServerError } from "core/utils/api";

import { createWatcherSaga } from "core/utils/saga";
import {
  buildFixedFilterFromConfig,
  mapParamsToApiServiceParams,
} from "elementTypes/default_table/reduxModule/utils";
import { IFilterGroup } from "elementTypes/default_table/toolsPanel";
import { BuiltinResponseType } from "services/api/types/ResponseType";
import { MetaQuery } from "../types";
import { Actions, Selectors, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: MetaQuery,
  selectors: Selectors,
) {
  const { dataSource, initialLoad, loadOnChange } = element.config;

  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);

    if (!dataSource.queryName?.length) {
      // Inside editor mode if element has just been created viewName can be empty
      // set error to warn user of viewName necessity
      const msg: string = yield call(
        getTranslatedTextSaga,
        editorTranslation,
        "viewNameError",
      );
      yield put(actions.loadError(msg));
      return;
    }

    try {
      const configFilterValue: ReturnType<typeof selectors.filter> =
        yield select(selectors.filter);

      const filter = buildFixedFilterFromConfig(
        configFilterValue,
      ) as IFilterGroup | null;

      const metadata = {
        order: null,
        offset: 0,
        limit: 1000,
        filter,
      };

      const responseFormat: BuiltinResponseType = yield select(
        selectors.responseFormat,
      ) ?? "json";

      const data: Record<string, unknown>[] = yield call(
        services.api.loadViewData,
        token,
        {
          viewName: dataSource.queryName,
          params: mapParamsToApiServiceParams(metadata),
          responseFormat,
        },
      );
      yield put(actions.loadSuccess(data));
    } catch (error) {
      yield put(actions.loadError(getServerError(error)));
    }
  }

  function* callLoad() {
    if (loadOnChange) {
      yield put(actions.load());
    }
  }

  return function* mainSaga() {
    yield all([
      takeLatest(types.LOAD, loadSaga),
      createWatcherSaga(element.config.filter, {
        onChange: callLoad,
      }),
    ]);

    if (initialLoad) {
      yield put(actions.load());
    }
  };
}
