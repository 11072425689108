import { memo } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { getPushArguments } from "core/router/urlService";
import { AlertBox } from "elementTypes/common/AlertBox";
import Button from "elementTypes/common/Button";
import Link from "elementTypes/common/Link";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";

import { Props } from "./container";
import useStyles from "./styles";
import { useInternalLinkFieldTranslation } from "./translation";

const DefaultInternalLinkField = memo<Props>(
  ({
    label,
    linkTo: { pageId, params },
    loading,
    element: {
      config: { isButton },
    },
    pages,
  }) => {
    const { classes } = useStyles();
    const translation = useInternalLinkFieldTranslation();

    if (!pageId) {
      return (
        <AlertBox
          title={translation.configErrorTitle}
          message={translation.linkNotSet}
        />
      );
    }

    const page = pages[pageId];

    if (!page) {
      return (
        <AlertBox
          title={translation.configErrorTitle}
          message={translation.pageNotFound}
        />
      );
    }

    if (loading) {
      return <LinearProgress variant="indeterminate" />;
    }
    if (isButton) {
      return (
        <Button
          href={getPushArguments(page, params)[0]}
          label={String(label)}
          fullWidth
        />
      );
    }

    return label ? (
      <Link
        title={String(label)}
        className={classes.link}
        underline="always"
        href={getPushArguments(page, params)[0]}
      >
        {label}
      </Link>
    ) : (
      <>{NON_BREAKING_SPACE}</>
    );
  },
);

DefaultInternalLinkField.displayName = "DefaultInternalLinkField";

export default DefaultInternalLinkField;
