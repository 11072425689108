import { HTMLAttributes } from "react";
import { ListItemText } from "@mui/material";
import { AutocompleteOption } from "elementTypes/common/Autocomplete/types";
import { TruncatedTypography } from "elementTypes/common/TruncatedTypography";

export const customRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOption,
) => (
  <li {...props}>
    <ListItemText
      primary={<TruncatedTypography title={option.label} />}
      secondary={<TruncatedTypography title={option.value as string} />}
      secondaryTypographyProps={{ variant: "subtitle2", display: "block" }}
    />
  </li>
);
