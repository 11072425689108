import * as t from "io-ts";
import { selectors } from "core/editor/reduxModule";
import { Type, buildObjectViewType, types } from "core/runtime-typing";
import {
  IElement,
  SelectorTypes,
  UntransformedConfig,
  customExpression,
} from "core/types";
import { ResponseTypeConfig } from "services/api/types/ResponseType";

export const DataDisplayConfig = t.intersection([
  t.type({
    dataSource: t.partial({
      viewName: t.string,
      identifierName: t.string,
      // should only the first row or the whole array be available
      setAllData: t.boolean,
      responseFormat: ResponseTypeConfig,
    }),
    // a format expression, e.g. `@@expression: element.data.value + " USD"`
    format: customExpression(t.any),
  }),
  t.partial({
    // identifier value, used to build query filter, e.g. `@@expression: location.query.identifier`
    identifier: customExpression(t.any),
    // an icon identifier, e.g. `flight_takeoff`
    icon: t.string,
    // the color to use, e.g. `success`, `info`, `error`, `warning`
    color: customExpression(t.string),
    // where to place the bar, null if bar is disabled
    barPosition: t.union([
      t.null,
      t.keyof({
        top: null,
        right: null,
        bottom: null,
        left: null,
      }),
    ]),
    variant: t.keyof({
      h1: null,
      h2: null,
      h3: null,
      h4: null,
      h5: null,
      h6: null,
      subtitle1: null,
      subtitle2: null,
      body1: null,
      caption: null,
      button: null,
      overline: null,
    }),
    cardVariant: t.keyof({
      outlined: null,
      elevation: null,
    }),
  }),
]);

export const dataDisplaySelectors: SelectorTypes<DataDisplayConfig> = {
  loading: types.boolean(
    "Indicates if the data is in process of being loaded from the server",
  ),
  error: types.nullable(types.string()),
  formattedData: types.optional(
    types.union([types.string(), types.number(), types.anyRecord()]),
  ),
  identifier: types.optional(types.union([types.number(), types.string()])),
  color: types.optional(types.string()),
  data: ({ config, state }) => {
    let type: Type;
    const viewName = config.dataSource.viewName;
    const viewList = selectors.viewList(state);
    const responseType =
      typeof config.dataSource.responseFormat === "function"
        ? config.dataSource.responseFormat(state)
        : config.dataSource.responseFormat;
    if (!viewList) {
      // view list still loading, do not show any typing errors
      type = types.any();
    } else if (!viewName) {
      type = types.optional(types.null(), "no source view set");
    } else {
      if (responseType && ["geo", "text"].includes(responseType)) {
        // TODO: TYpe GEO response: https://postgrest.org/en/v12/how-tos/working-with-postgresql-data-types.html#postgis
        type = types.any();
      } else {
        const view = viewList.find((v) => v.name === viewName);
        if (!view) {
          throw new Error(`Invalid view ${viewName}`);
        }
        type = buildObjectViewType(view);
        if (config.dataSource.setAllData) {
          type = types.array(type);
        }
      }
    }
    return types.nullable(type);
  },
};

export type DataDisplayConfig = t.TypeOf<typeof DataDisplayConfig>;

export type UntransformedDataDisplayConfig =
  UntransformedConfig<DataDisplayConfig>;

export const DataDisplayTranslationKeys = ["label"] as const;

export type DataDisplayTranslationKeys =
  (typeof DataDisplayTranslationKeys)[number];

export type DataDisplay = IElement<
  DataDisplayConfig,
  {},
  DataDisplayTranslationKeys
>;
