import * as t from "io-ts";
import { Translated } from "core";
import { customExpression } from "core/types/customExpression";
import { UntransformedConfig } from "core/types/react";
import { FixedFilterGroup } from "elementTypes/default_table/toolsPanel";

import EchartsContainerDataset from "./EchartsContainerDataset";
import EchartsContainerOneSerie from "./EchartsContainerOneSerie";
import { IOptionEchartProps } from "./types";

interface IEchartContainerProps {
  elementId: string;
  defaultOptions: IOptionEchartProps;
  i18n?: Translated<`${string}Label`>;
}

export interface IConfigEchartsContainerProps {
  config: IOptionEchartProps;
}

export type ConfigEchartsContainerProps =
  t.InterfaceType<IConfigEchartsContainerProps>;

const ToolboxFeature = t.record(t.string, t.record(t.string, t.unknown));

const Legend = t.partial({
  placement: t.keyof({
    top: null,
    end: null,
    bottom: null,
    start: null,
    hidden: null,
  }),
  orient: t.keyof({
    vertical: null,
    horizontal: null,
  }),
});

export const EchartsContainerSingleConfig = t.intersection([
  t.type({
    dataSource: t.type({
      viewName: t.string,
      columns: t.type({
        labelColumnName: t.string,
        valueColumnName: t.string,
      }),
    }),
  }),
  t.partial({
    showBackground: t.boolean,
    filter: customExpression(t.union([t.null, FixedFilterGroup])),
    sort: t.array(
      t.type({
        fieldName: t.string,
        asc: t.boolean,
      }),
    ),
    toolbox: ToolboxFeature,
    themeName: t.string,
    theme: t.record(t.string, t.unknown),
    legend: Legend,
    selected: t.union([t.null, t.record(t.string, t.unknown)]),
  }),
]);

export const EchartsContainerMultiConfig = t.intersection([
  t.type({
    dataSource: t.type({
      viewName: t.string,
      columns: t.type({
        labelColumnName: t.string,
        valueColumnNames: t.array(t.string),
      }),
    }),
  }),
  t.partial({
    showBackground: t.boolean,
    isStacked: t.boolean,
    filter: customExpression(t.union([t.null, FixedFilterGroup])),
    sort: t.array(
      t.type({
        fieldName: t.string,
        asc: t.boolean,
      }),
    ),
    toolbox: ToolboxFeature,
    themeName: t.string,
    theme: t.record(t.string, t.unknown),
    legend: Legend,
    selected: t.union([t.null, t.record(t.string, t.unknown)]),
  }),
]);

export type EchartsContainerSingleConfig = t.TypeOf<
  typeof EchartsContainerSingleConfig
>;
export type EchartsContainerMultiConfig = t.TypeOf<
  typeof EchartsContainerMultiConfig
>;

interface CommonProps<Type extends string> {
  type: Type;
  data: any;
  title?: string;
}

export type IEchartsContainerSingleProps = EchartsContainerSingleConfig &
  IEchartContainerProps &
  CommonProps<"pie">;

export type IEchartsContainerMultiProps = EchartsContainerMultiConfig &
  IEchartContainerProps &
  CommonProps<"bar" | "line" | "scatter">;

export type UntransformedEchartConfig =
  UntransformedConfig<EchartsContainerSingleConfig>;

export { EchartsContainerOneSerie, EchartsContainerDataset };
