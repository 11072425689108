import { memo, useEffect, useMemo, useRef } from "react";
import { AutocompleteChangeReason } from "@mui/material/useAutocomplete";
import deepEqual from "fast-deep-equal";
import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import { ConnectedReduxModuleProps } from "core";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element-utils";
import { usePrevious } from "utils/hooks";
import { BaseAutocomplete } from "../common/Autocomplete/components";
import { BaseProps } from "../common/Autocomplete/components";

import {
  AutocompleteOption,
  IAutocompleteValue,
} from "../common/Autocomplete/types";
import { getValueObject } from "../common/Autocomplete/utils.tsx";

import { ReduxModule } from "./reduxModule";
import { useAutocompleteInputTranslation } from "./translation";
import { AutocompleteInput } from "./types";

type Props = ConnectedReduxModuleProps<ReduxModule, AutocompleteInput>;

const DefaultAutocompleteInput = memo<Props>((props) => {
  const {
    changeValue,
    element: {
      config: {
        allowSameValue,
        isMulti,
        dataSource,
        isClearable = true,
        options: staticOptions,
        fetchDelay = 1000,
        size,
        chipVariant,
        autosuggestHighlight,
        firstOptionSelected,
        virtualizedList,
        withCheckbox,
        reference,
      },
      i18n: { label },
    },
    loadingOptions,
    options,
    optionsError,
    searchInputValue = undefined,
    errors,
    valueObject,
    value,
    fixedFilter,
    disabled,
    required,
    loadOptions,
    changeSearchInputValue,
    changeTouched,
    setValueObject,
    moreDataAvailable,
  } = props;
  const i18n = useAutocompleteInputTranslation();
  const { language } = useSessionContext();
  const selectOptions = staticOptions
    ? staticOptions.map(
        (staticOption) =>
          staticOption && {
            value: staticOption.value,
            label:
              getTranslatedText(language, staticOption.i18n, "label") ??
              value.toString(),
          },
      )
    : moreDataAvailable
      ? [...options, { value: Infinity, label: i18n.moreOptionsAvailable }]
      : options ?? [];

  const initialValue = useRef<string | string[] | null>(null);
  const autocompleteSearchThrottled = useRef(
    throttle((setInputValue?: boolean) => {
      loadOptions(setInputValue);
    }, fetchDelay),
  ).current;

  const shouldUpdate = useMemo(() => !staticOptions?.length, [staticOptions]);

  const getOptionDisabled = (option: AutocompleteOption) =>
    option.value === Infinity;

  useEffect(() => {
    if (!shouldUpdate && !!optionsError?.length) {
      autocompleteSearchThrottled();
    }
  }, [shouldUpdate, optionsError, autocompleteSearchThrottled]);

  useEffect(() => {
    // Save the initial value for future reset form
    initialValue.current = value;

    // Fetch options when it doesn't have a static options.
    if (shouldUpdate) {
      autocompleteSearchThrottled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteSearchThrottled, shouldUpdate]);
  useEffect(() => {
    // If the value is equal to initialValue, it means that the reset form was reset
    if (isEqual(value, initialValue.current)) {
      const newValueObject = getValueObject(selectOptions, value, isMulti);

      if (shouldUpdate) {
        // We need to set the search input value when the options was fetched.
        autocompleteSearchThrottled(true);
      } else {
        setValueObject(newValueObject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteSearchThrottled, shouldUpdate, value]);

  // reload options if filter has been changed during element editing
  const prevFilter = usePrevious(fixedFilter);
  useEffect(() => {
    if (reference && !deepEqual(fixedFilter, prevFilter)) {
      autocompleteSearchThrottled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedFilter]);

  // set default value, use first option if firstOptionSelected === true
  useEffect(() => {
    const isValueExists = value || (Array.isArray(value) && value.length);
    if (firstOptionSelected && !isValueExists && selectOptions.length) {
      const defaultOption = selectOptions[0];
      const defaultValue = isMulti
        ? [defaultOption.value]
        : defaultOption.value;
      handleChange(defaultValue, "selectOption");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstOptionSelected, selectOptions]);

  const handleChange = (
    newValue: IAutocompleteValue,
    reason: AutocompleteChangeReason,
  ) => {
    const newValueObject = getValueObject(selectOptions, newValue, isMulti);

    setValueObject(newValueObject);
    if (reason === "clear") {
      // if the value was cleared manually, set it to `null`
      changeValue(null);
    } else {
      changeValue(newValue);
    }

    switch (reason) {
      case "selectOption": {
        if (!isMulti && !Array.isArray(newValueObject)) {
          changeSearchInputValue(newValueObject?.["label"] ?? null);
        } else {
          changeSearchInputValue(null);
        }
        break;
      }
      case "clear": {
        changeSearchInputValue(null);
        break;
      }
    }
  };
  const handleInputChange = (inputValue: string | null) => {
    if (inputValue !== searchInputValue) {
      changeSearchInputValue(inputValue);
      if (shouldUpdate) {
        autocompleteSearchThrottled();
      }
    }
  };

  const selectProps = {
    label,
    name: dataSource?.fieldPath.join("."),
    options: selectOptions as AutocompleteOption[],
    onChange: handleChange,
    onInputChange: handleInputChange,
    searchInputValue,
    placeholder:
      loadingOptions && !(options && options.length)
        ? i18n.loadingOptions
        : i18n.placeholder,
    isLoading: loadingOptions && !(options && options.length),
    disabled,
    isClearable,
    isMulti,
    allowSameValue,
    valueObject,
    optionsError,
    size,
    chipVariant,
    autosuggestHighlight,
    virtualizedList,
    firstOptionSelected,
    withCheckbox,
    changeTouched,
    errors,
    required,
    getOptionDisabled,
  };

  return <BaseAutocomplete {...(selectProps as BaseProps)} />;
});

export default DefaultAutocompleteInput;
