import {
  MouseEvent,
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql";
import "codemirror/theme/neat.css";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Button, Typography } from "@mui/material";
import { Editor, Position } from "codemirror";
import debounce from "lodash/debounce";
import { Controlled as CodeMirror } from "react-codemirror2";
import {
  ControllerRenderProps,
  FieldError,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
} from "react-hook-form";

import "./erdHints";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { useModel, useTestCustomQuery } from "queries/admin";
import { getApiError } from "queries/utils";

import { useAdminContext } from "staticPages/admin/context";
import {
  AlertBox,
  AlertBoxProps,
} from "../../../../../elementTypes/common/AlertBox";
import { entitiesNamesBySchema } from "../erd/utils";

import { ErdHintOption } from "./erdHints";
import { useStyles } from "./styles";
import { useCustomQueryTranslation } from "./translation";
import { TestCustomQueryResponse, UICustomQueryForm } from "./types";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

type Props = {
  field: ControllerRenderProps<UICustomQueryForm, "code">;
  onDataPreview: () => void;
  setError: UseFormSetError<UICustomQueryForm>;
  clearError: UseFormClearErrors<UICustomQueryForm>;
  getValues: UseFormGetValues<UICustomQueryForm>;
  error?: FieldError;
};

const QueryEditor = memo<Props>(
  ({ onDataPreview, error, clearError, getValues, setError, field }) => {
    const { classes } = useStyles();
    const model = useModel();
    const dataModel = model.data;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [editorCursorPosition, setEditorCursorPosition] = useState<
      | Position
      | {
          line: null;
          ch: null;
        }
    >({
      line: null,
      ch: null,
    });
    const { selectedQueryEntity, setSelectedQueryEntity } = useAdminContext();

    useEffect(() => {
      if (
        editorCursorPosition.line !== null &&
        editorCursorPosition.ch !== null &&
        selectedQueryEntity
      ) {
        const valuesArray = field.value.split("\n");
        const foundValue = valuesArray[editorCursorPosition.line];

        if (foundValue) {
          const newLine = `${foundValue.slice(
            0,
            editorCursorPosition.ch,
          )}${selectedQueryEntity} ${foundValue.slice(
            editorCursorPosition.ch,
          )}`;

          valuesArray[editorCursorPosition.line] = newLine;
          field.onChange(valuesArray.join("\n"));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedQueryEntity]);

    useEffect(() => {
      return () => {
        setSelectedQueryEntity(null);
      };
    }, [setSelectedQueryEntity]);

    const erdHintOption: ErdHintOption =
      useMemo(
        () => dataModel && entitiesNamesBySchema(dataModel),
        [dataModel],
      ) ?? {};

    const handleServerValidation = (serverError: TestCustomQueryResponse) => {
      const { errorHint, errorLine } = serverError;
      if (serverError.error) {
        const manualError = {
          type: "server",
          types: {
            errorLine: `${errorLine}`,
            errorHint,
          },
          message: serverError.error,
        };
        setError("code", manualError);
      } else {
        clearError("code");
      }
    };

    const {
      queryEditorErrorDetails,
      queryEditorErrorTitle,
      queryEditorInfo,
      queryEditorLine,
      queryEditorLineDetailsPrefix,
      queryEditorLineErrorPrefix,
      queryEditorLoading,
      queryEditorRun,
    } = useCustomQueryTranslation();

    const validateQuery = useTestCustomQuery({
      onSuccess: (data: TestCustomQueryResponse) => {
        handleServerValidation(data);
      },
      onError: (validateError) => {
        handleServerValidation({ error: getApiError(validateError) });
      },
    });

    const handleValidateQuery = useCallback(
      (newCode: string) => {
        if (!newCode) {
          return;
        }
        const updated = {
          data: {
            // TODO remove "fresh" fallback, use randomly generated string instead
            viewName: getValues("name") || "fresh",
            code: newCode,
          },
        };
        validateQuery.mutate(updated);
      },
      [validateQuery, getValues],
    );

    const debouncedValidation = useMemo(
      () => debounce(handleValidateQuery, 512),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const handleChange = (_: any, __: any, newValue: string) => {
      field.onChange(newValue);
      debouncedValidation(newValue);
      if (!newValue.trim()) {
        clearError("code");
      }
    };

    const handleOpen = (e: MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(e.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const isEmpty = !field.value?.trim();

    const isValid = !validateQuery.isLoading && !isEmpty && !error;

    const alertProps: AlertBoxProps = {
      variant: "standard",
      ...(isEmpty
        ? { color: "info", message: queryEditorInfo }
        : validateQuery.isLoading
          ? { color: "info", message: queryEditorLoading }
          : isValid
            ? {
                color: "success",
                message: "Valid",
                action: (
                  <Button
                    style={{ marginRight: "8px" }}
                    endIcon={<PlayArrowIcon />}
                    onClick={onDataPreview}
                  >
                    {queryEditorRun}
                  </Button>
                ),
              }
            : { color: "error", message: error?.message }),
    };

    const editorDidMount = (editor: Editor) => {
      setTimeout(() => editor.refresh(), 0);
    };

    const onKeyEvent = (editor: Editor, event: any) => {
      setTimeout(() => editor.showHint(event), 0);
    };

    const onEditorCursor = (ed: Editor, data: Position) => {
      setEditorCursorPosition(data);
      ed.focus();
    };

    return (
      <Box>
        <Box border="1px solid" borderColor="divider" borderRadius={1}>
          <Box width="100%" height="350px" overflow="auto">
            <CodeMirror
              editorDidMount={editorDidMount}
              value={field.value}
              ref={field.ref}
              onBeforeChange={handleChange}
              onChange={() => ({})}
              onCursor={onEditorCursor}
              className={classes.codeClass}
              onKeyPress={onKeyEvent}
              options={{
                lineWrapping: true,
                lineNumbers: true,
                smartIndent: true,
                foldGutter: true,
                gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                mode: "sql",
                theme: "neat",
                tabSize: 2,
                extraKeys: {
                  "Ctrl-Space": "autocomplete",
                },
                hintOptions: {
                  hintList: erdHintOption,
                  completeSingle: false,
                  showHint: true,
                } as any,
              }}
            />
          </Box>
          <AlertBox
            classes={{ message: classes.alertOverflow }}
            {...alertProps}
            {...(!!error?.types?.errorHint &&
              !validateQuery.isLoading && {
                action: (
                  <Box mr={1}>
                    <Button
                      onClick={handleOpen}
                      color="inherit"
                      size="small"
                      variant="text"
                    >
                      {queryEditorErrorDetails}
                    </Button>
                  </Box>
                ),
              })}
          />
        </Box>
        {error?.types?.errorHint && (
          <Popover anchorEl={anchorEl} onClose={handleClose}>
            <Typography variant="subtitle1">{queryEditorErrorTitle}</Typography>
            <Typography variant="subtitle2">
              <Box component="span" style={{ textTransform: "uppercase" }}>
                {`${queryEditorLineErrorPrefix}: `}
              </Box>
              {error.message}
            </Typography>
            <Typography variant="caption">
              <Box component="span" style={{ textTransform: "uppercase" }}>
                {`${queryEditorLineDetailsPrefix}: `}
              </Box>
              {error.types?.errorHint}
              {` ${queryEditorLine} ${error?.types?.errorLine}`}
            </Typography>
          </Popover>
        )}
      </Box>
    );
  },
);

export default QueryEditor;
