import { memo, useMemo, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  ConnectedStaticReduxModuleActionsProps,
  IMenuItem,
  ITranslatedMenuItem,
  RouterReduxModule,
} from "core";
import { prefixPageUrl } from "core/router/reduxModule/utils";
import { useSessionContext } from "core/session";
import Link from "elementTypes/common/Link";
import { IconNameType, MuiIcon } from "elementTypes/common/MuiIcon";

import { GenerateMenuList, IGenerateMenuItemsProps } from "../MenuList";

import { mapStateToProps } from "./container";
import useStyles from "./styles";

type MenuItemProps = {
  item: ITranslatedMenuItem;
  menu: IMenuItem[];
} & ConnectedStaticReduxModuleActionsProps<RouterReduxModule> &
  IGenerateMenuItemsProps &
  ReturnType<typeof mapStateToProps>;

export const MenuItem = memo<MenuItemProps>(
  ({ depth = 0, item, menu, uiName, menuItemSelected, getPageById }) => {
    const { language } = useSessionContext();
    const { classes } = useStyles({ depth });
    const isNested = !!menu.length;
    const isError = !isNested && !item.externalLink && !item.pageId;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const handleClickMenu = () => setMenuOpen((prevOpen) => !prevOpen);
    // TODO handle external links
    const page = useMemo(
      () => (item.pageId ? getPageById(item.pageId) : null),
      [item.pageId, getPageById],
    );

    const prefixedUrl = page ? prefixPageUrl(page.url) : undefined;

    const itemIcon =
      item.i18n.icon || isError ? (
        <ListItemIcon
          className={`${classes.icon} ${
            isError ? classes.errorIcon : classes.baseIcon
          }`}
        >
          <MuiIcon
            icon={isError ? "error" : (item.i18n.icon! as IconNameType)}
            fontSize="large"
          />
        </ListItemIcon>
      ) : (
        <div className={`${classes.icon} ${classes.baseIcon}`} />
      );

    const SubMenu = useMemo(
      () =>
        GenerateMenuList(menu, uiName, menuItemSelected, language, depth + 1),
      [depth, menu, uiName, menuItemSelected, language],
    );

    const href = item.externalLink ?? prefixedUrl;

    return (
      <Link
        {...(!isNested && {
          href,
        })}
        underline="none"
        color="inherit"
        component={isNested ? "li" : "a"}
      >
        <ListItem
          className={`${classes.button} ${classes.nested} menu-item`}
          button={true}
          onClick={isNested ? handleClickMenu : undefined}
          selected={prefixedUrl === menuItemSelected}
        >
          {itemIcon}
          <ListItemText primary={item.i18n.label} />
          {isNested && (menuOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {isNested && (
          <Collapse
            in={menuOpen}
            unmountOnExit={false}
            timeout={0}
            mountOnEnter={true}
          >
            {SubMenu}
          </Collapse>
        )}
      </Link>
    );
  },
);
