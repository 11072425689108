import { PropsWithChildren, memo } from "react";
import { Helmet } from "react-helmet";
import { EditorLayout } from "core/editor/EditorLayout/container";
import { buildLayoutGetter } from "core/getLayout";
import { layouts } from "layouts";
import { LoadingComponent } from "layouts/common/Loading";
import { AdminLayout } from "staticPages/admin/components/AdminLayout";
import { StaticPagesLayout } from "staticPages/common/Layout";

import { IAppMetadata, Language } from "../../types";
import { getTranslatedTexts } from "../../utils/element-utils";
import { useSessionContext } from "../SessionContext";

import { MapStateToProps } from "./container";

type Props = ReturnType<MapStateToProps> & PropsWithChildren;

type ComponentProps = {
  appMetadata: IAppMetadata | null | undefined;
} & PropsWithChildren;

const Component = memo<ComponentProps>(({ appMetadata, children }) => {
  const getLayout = buildLayoutGetter(layouts);
  const layoutName = appMetadata?.release.definition.layout.name;
  const RealLayout = getLayout(layoutName);
  if (!RealLayout) {
    return <span>{`Layout "${layoutName}" not supported`}</span>;
  }
  Component.displayName = `${layoutName}ChildrenComponent`;

  return <RealLayout appMetadata={appMetadata!}>{children}</RealLayout>;
});

const getPageTitle = (props: Props & { language: Language }) => {
  const { isStaticPage, isAdminPage, ui, page, language } = props;
  if (isStaticPage) {
    return isAdminPage ? "Administration" : "Settings";
  } else if (ui) {
    const uiTitle = getTranslatedTexts(language, ui.i18n).label || ui.name;
    const pageTitle = page
      ? getTranslatedTexts(language, page.i18n).label || page.url
      : "";
    return page ? `${pageTitle} - ${uiTitle}` : uiTitle;
  }
  return "Loading";
};

const determineContent = (props: Props) => {
  const {
    appMetadata,
    children,
    isStaticPage,
    isAdminPage,
    isLoggedIn,
    isAdmin,
  } = props;

  if (isStaticPage) {
    if (isAdminPage) {
      return <AdminLayout>{children}</AdminLayout>;
    } else if (isLoggedIn) {
      return appMetadata ? (
        <Component appMetadata={appMetadata}>{children}</Component>
      ) : (
        <StaticPagesLayout>{children}</StaticPagesLayout>
      );
    }
    return children;
  } else if (appMetadata) {
    const component = (
      <Component appMetadata={appMetadata}>{children}</Component>
    );
    // in current version only isAdmin has access to EditorLayout
    return isAdmin ? <EditorLayout>{component}</EditorLayout> : component;
  }
  return children; // Default case if none of the conditions match
};

export const Layout = memo<Props>((props) => {
  const {
    loadAppMetadata: { inProgress },
  } = props;
  const { language } = useSessionContext();

  const pageTitle = getPageTitle({ ...props, language });
  const content = determineContent(props);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {inProgress ? <LoadingComponent /> : content}
    </>
  );
});

Layout.displayName = "Layout";
