import { ASYNC_ACTION_INITIAL_STATE, handleActions } from "../../utils/redux";

import { types } from "./actions";
import { ActionTypes, IState } from "./types";
import { getInitialLanguage } from "./utils";

const INITIAL_STATE: IState = {
  ui: null,
  uiList: null,
  appMetadata: null,
  loadAppMetadata: ASYNC_ACTION_INITIAL_STATE,
  token: null,
  error: null,
  loggingIn: false,
  notifications: [],
  currentLanguage: getInitialLanguage(),
  isAdmin: false,
  additionalInformation: null,
};

export const reducer = handleActions<IState, ActionTypes>(INITIAL_STATE, {
  [types.APP_METADATA_LOAD]: (state) => ({
    ...state,
    loadAppMetadata: {
      inProgress: true,
      error: null,
    },
  }),
  [types.APP_METADATA_LOAD_SUCCESS]: (
    state,
    action: ActionTypes["loadAppMetadataSuccess"],
  ) => ({
    ...state,
    appMetadata: action.payload.appMetadata,
    ui: action.payload.ui,
    loadAppMetadata: {
      ...state.loadAppMetadata,
      inProgress: false,
    },
  }),
  [types.APP_METADATA_LOAD_ERROR]: (
    state,
    action: ActionTypes["loadAppMetadataError"],
  ) => ({
    ...state,
    loadAppMetadata: {
      inProgress: false,
      error: action.payload.error,
    },
  }),
  [types.APP_METADATA_REPLACE]: (
    state,
    { payload }: ActionTypes["replaceAppMetadata"],
  ) => ({
    ...state,
    ...(state.appMetadata && {
      appMetadata: {
        ...state.appMetadata,
        release: {
          ...state.appMetadata.release,
          definition: payload.nextDefinition,
        },
      },
    }),
    ui: {
      ...state.ui!,
      name: payload.name,
      description: payload.nextSavePoint.description,
      lastUpdated: payload.nextSavePoint.createdAt,
      lastUpdatedBy: payload.nextSavePoint.createdBy,
    },
  }),
  [types.APP_METADATA_FINISHED]: (state) => ({
    ...state,
    loadAppMetadata: {
      ...state.loadAppMetadata,
      inProgress: false,
    },
  }),
  [types.LOGIN]: (state) => ({
    ...state,
    loggingIn: true,
    error: null,
    loadAppMetadata: {
      inProgress: true,
      error: null,
    },
  }),
  [types.LOGIN_SUCCESS]: (state, action: ActionTypes["loginSuccess"]) => ({
    ...state,
    loggingIn: false,
    token: action.payload.token,
    // Fix overlapping layout issue in logged out
    ui: null,
    uiList: [],
    appMetadata: null,
  }),
  [types.USER_LOAD]: (state, action: ActionTypes["loadUser"]) => ({
    ...state,
    token: action.payload.token,
  }),
  [types.USER_SUCCESS]: (state, action: ActionTypes["loadUserSuccess"]) => ({
    ...state,
    isAdmin: action.payload.isAdmin,
    additionalInformation: action.payload.additionalInformation ?? {},
  }),
  [types.USER_ERROR]: (state, action: ActionTypes["loadUserError"]) => ({
    ...state,
    token: null,
    loadAppMetadata: {
      ...state.loadAppMetadata,
      inProgress: false,
      error: action.payload.error,
    },
  }),
  [types.LOGIN_ERROR]: (state, action: ActionTypes["loginError"]) => ({
    ...state,
    loggingIn: false,
    error: action.payload.error,
    loadAppMetadata: {
      inProgress: false,
      error: null,
    },
  }),
  [types.LOGOUT]: (state) => ({
    ...state,
    token: null,
    isAdmin: false,
    uiList: null,
    ui: null,
    additionalInformation: null,
    // Fix overlapping layout issue in logged out
    loadAppMetadata: {
      ...state.loadAppMetadata,
      inProgress: true,
    },
  }),
  [types.UI_LIST_SET]: (state, action: ActionTypes["setUiList"]) => ({
    ...state,
    uiList: action.payload,
  }),
  [types.SNACKBAR_LIST_SET]: (
    state,
    action: ActionTypes["setSnackbarList"],
  ) => ({
    ...state,
    notifications: action.payload,
  }),
  [types.LANGUAGE_CHANGE]: (state, action: ActionTypes["changeLanguage"]) => ({
    ...state,
    currentLanguage: action.payload.lang,
  }),
  [types.BOOTSTRAP]: (state) => ({
    ...state,
    loadAppMetadata: {
      inProgress: true,
      error: null,
    },
  }),
});
