import { createUseTranslation } from "core/session/translation";

export const rolePagesTranslation = {
  en: {
    pageTitle: "Roles",
    createBtnLabel: "Create",
    createPageTitle: "Create Role",
    inputNameLabel: "Name",
    successMsg: "Role * has ben create",
    deletedMessage: "Role was deleted successfully",
    DeleteButton: "Delete",
    CancelButton: "Cancel",
    dialogTitle: "Delete Role",
    tableNameLabel: "Name",
    tableActionLabel: "Actions",
    dialogContentText: "Are you sure you want to delete the role",
    deleteTooltip: "Delete",
  },
};

export const useRolePagesTranslation =
  createUseTranslation(rolePagesTranslation);
