import { VariantType } from "notistack";
import { put, select } from "redux-saga/effects";

import { IPage } from "core";
import { getPushArguments } from "core/router";
import {
  actions as routerActions,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import { actions as sessionActions } from "core/session/reduxModule";
import {
  AutocompleteInterface,
  ChartInterface,
  DisplayDataInterface,
  FormDataSource,
  GeoJSONInterface,
  ModalDialog,
  TableDataSource,
} from "elementInterfaces";
import { ActionConfigType, ActionType } from "./types";

export function* elementActionEventHandler(action: ActionConfigType) {
  if (action?.notification?.message?.trim()) {
    yield put(
      sessionActions.enqueueSnackbar({
        message: action.notification.message,
        options: {
          variant: (action?.notification?.variant as VariantType) ?? "default",
          persist: action?.notification?.persist ?? false,
        },
      }),
    );
  }

  if (action.type === ActionType.reload_table) {
    const dataSource = TableDataSource.get(action.elementId);
    yield put(dataSource.reload());
  } else if (action.type === ActionType.save_form) {
    const dataSource = FormDataSource.get(action.elementId);
    yield put(dataSource.save());
  } else if (action.type === ActionType.refresh_chart) {
    const chartInterface = ChartInterface.get(action.elementId);
    yield put(chartInterface.refresh());
  } else if (action.type === ActionType.refresh_display_data) {
    const displayDataInterface = DisplayDataInterface.get(action.elementId);
    yield put(displayDataInterface.reload());
  } else if (action.type === ActionType.toggle_modal) {
    const dialogInterface = ModalDialog.get(action.elementId);
    yield put(dialogInterface.toggleModal());
  } else if (action.type === ActionType.reset_input) {
    const autocompleteInterface = AutocompleteInterface.get(action.elementId);
    yield put(autocompleteInterface.reset());
  } else if (action.type === ActionType.navigate) {
    const { params, pageId } = action.linkTo;
    if (pageId) {
      const pages: Record<string, IPage> = yield select(
        routerSelectors.allPages,
      );

      const pageParams: Record<string, any> = yield Object.keys(
        params ?? {},
      ).reduce(function* (p, k) {
        if (!k) {
          return p;
        }
        const paramSelector = params![k];
        const param = (yield select(paramSelector)) as string;
        return {
          ...p,
          [k]: param,
        };
      }, {});

      const page = pages[pageId];

      if (page) {
        yield put(routerActions.push(...getPushArguments(page, pageParams)));
      }
    }
  } else if (action.type === ActionType.go_back) {
    yield put(routerActions.goBack());
  } else if (action.type === ActionType.reload_form) {
    const dataSource = FormDataSource.get(action.elementId);
    yield put(dataSource.reload());
  } else if (action.type === ActionType.refresh_geojson_field) {
    const geoJSONInterface = GeoJSONInterface.get(action.elementId);
    yield put(geoJSONInterface.reload());
  }
}
