import { lazy, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import deepEqual from "fast-deep-equal";
import { JSONSchema6, JSONSchema6Definition } from "json-schema";
import cloneDeep from "lodash/cloneDeep";
import { assocPath, omit } from "ramda";
import { FixedSizeList } from "react-window";
import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import { usePrevious } from "utils/hooks";

import IconButton from "../../../../common/IconButton";
import DialogWrapper from "../../../../helpers/HOC/DialogWrapper";
import { withLazyLoading } from "../../../../helpers/HOC/LazyLoading";
import { FormConfig } from "../../../types";
import { useEditorFormTranslation } from "../../translation";

import { Field, FieldRow } from "./FieldRow";
import { JsonSchemaProvider } from "./context";
import {
  convertValuesToData,
  fieldSchemaToFormData,
  getFormSchemas,
  omitProps,
  transformProps,
} from "./utils";

const DialogContent = withLazyLoading(
  lazy(() => import("./SchemaDialogContent")),
  true,
);

export const JsonSchema = memo(() => {
  const {
    elementModel: {
      config: {
        dataSource: { viewName },
        jsonSchema,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<FormConfig>();
  const {
    addSchemaTooltip,
    deleteSchemaTooltip,
    editSchemaTitle,
    validationTitle,
  } = useEditorFormTranslation();
  const { cancelButton, editButton, updateButton } = useEditorTranslation();
  const prevViewName = usePrevious(viewName);
  const { getViewByName } = useObjectViewList();

  const [fieldSchema, setFieldSchema] = useState<Field | null>(null);
  const initialFieldSchema = useRef<Field | null>(null);

  const handleEditClick = (nextField: Field) => {
    if (!jsonSchema) {
      return;
    }
    const fieldData = fieldSchemaToFormData(nextField, jsonSchema) as Field;
    setFieldSchema(fieldData);
    initialFieldSchema.current = {
      ...nextField,
    };
  };

  const handleClose = () => {
    setFieldSchema(null);
    initialFieldSchema.current = null;
  };

  const changeValue = useCallback(
    (newJsonSchema: FormConfig["jsonSchema"]) =>
      changeConfigValue("jsonSchema", newJsonSchema),
    [changeConfigValue],
  );

  const currentView = useMemo(
    () => getViewByName(viewName),
    [getViewByName, viewName],
  );

  const { viewSchema, defaultSchema } = useMemo(
    () => getFormSchemas(currentView),
    [currentView],
  );

  const isChanged = useMemo(
    () => !deepEqual(viewSchema, jsonSchema),
    [viewSchema, jsonSchema],
  );

  useEffect(
    () => {
      // if view name has been changed, change the schema
      if (isChanged && jsonSchema && prevViewName !== viewName) {
        changeValue(viewSchema ?? defaultSchema);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewName],
  );

  const onActionClick = () => {
    const nextValue = jsonSchema ? undefined : viewSchema ?? defaultSchema;
    changeValue(nextValue);
  };

  const items = useMemo(() => {
    const fields = (jsonSchema as JSONSchema6)?.properties;
    const cachedRequiredFields = (jsonSchema as JSONSchema6)?.required ?? [];
    const schemaRequiredFields = (viewSchema as JSONSchema6)?.required ?? [];
    return (
      fields &&
      Object.keys(fields).map((item) => ({
        name: item,
        isIdentifyingField: item === currentView?.identifyingField?.name,
        isRequired: cachedRequiredFields.includes(item),
        canBeChanged: !schemaRequiredFields.includes(item),
        ...((fields[item] as JSONSchema6) ??
          defaultSchema?.properties?.[item] ??
          {}),
      }))
    );
  }, [defaultSchema, currentView, jsonSchema, viewSchema]);

  const formDataToSchema = ({
    data,
    schema,
  }: {
    data: Record<string, any>;
    schema?: JSONSchema6;
  }) => {
    let nextSchema = schema
      ? cloneDeep(schema)
      : { type: "object", required: [] };

    for (const fieldName in data) {
      const { name, ...fieldProperties } = data[fieldName];
      const propName = (name ?? fieldName).toString();

      if (fieldProperties.isRequired !== undefined) {
        let nextRequired = (nextSchema?.required ?? []).filter(
          (field: string) => field !== fieldName && field !== name,
        );
        nextRequired = fieldProperties.isRequired
          ? [...nextRequired, propName]
          : nextRequired;

        nextSchema = {
          ...nextSchema,
          required: nextRequired,
        } as JSONSchema6;
      }

      const nextProps = (nextSchema as JSONSchema6).properties ?? {};

      const transformedProps = transformProps(fieldProperties);

      nextSchema = {
        ...nextSchema,
        properties: {
          ...omit([fieldName], nextProps),
          [propName]: {
            ...(transformedProps as JSONSchema6["properties"]),
            ...(fieldProperties.properties && {
              ...omit(
                omitProps,
                formDataToSchema({
                  data: fieldProperties.properties,
                  schema: nextProps[fieldName] as JSONSchema6,
                }),
              ),
            }),
          },
        } as Record<string, JSONSchema6Definition>,
      };
    }

    return nextSchema;
  };

  const onSubmit = (data: Record<string, unknown>) => {
    if (!fieldSchema) {
      return;
    }
    const updatedSchema = assocPath(
      ["properties", fieldSchema.name],
      fieldSchema,
      jsonSchema,
    );

    const nextValue = formDataToSchema({
      data,
      schema: updatedSchema,
    });

    !deepEqual(nextValue, jsonSchema) && changeValue(nextValue);

    handleClose();
  };

  const handleDeleteProp = (name: string, values: Record<string, unknown>) => {
    if (!fieldSchema) {
      return;
    }

    const data = convertValuesToData(
      Object.keys(values).reduce(
        (result, key) =>
          key.startsWith(name) ? result : { ...result, [key]: values[key] },
        {},
      ),
    );

    setFieldSchema((prevFieldSchema) => ({
      ...prevFieldSchema,
      ...data?.[fieldSchema.name],
      properties: data?.[fieldSchema.name]?.properties,
    }));
  };

  const handleAddProp = (
    name: string,
    values: Record<string, unknown>,
    newProp: { name: string; type: string | string[] },
  ) => {
    if (!fieldSchema) {
      return;
    }
    const data = convertValuesToData(values);
    const path = name ? name.split(".") : [];
    const nextData = assocPath(path, newProp, data);
    const nextFieldSchema = (nextData?.[fieldSchema.name] ?? {}) as Field;

    if (nextFieldSchema) {
      setFieldSchema(
        (prevSchemaField) =>
          ({ ...prevSchemaField, ...nextFieldSchema }) as Field,
      );
    }
  };

  const itemSize = 48;

  const hasChanges =
    fieldSchema &&
    !deepEqual(fieldSchema.properties, initialFieldSchema.current?.properties);

  return (
    <>
      {currentView && (
        <>
          <Section
            title={validationTitle}
            headerAction={
              <IconButton
                icon={jsonSchema ? "delete_outline" : "add"}
                tooltip={jsonSchema ? deleteSchemaTooltip : addSchemaTooltip}
                onClick={onActionClick}
              />
            }
            // add prop for easy access in cypress tests
            data-cypress-selector={"fields-validation-list"}
          >
            {!!items?.length && (
              <FixedSizeList
                height={itemSize * Math.min(items.length, 7)}
                itemCount={items.length}
                itemSize={itemSize}
                width="100%"
                itemData={items as any}
              >
                {(props) => (
                  <FieldRow
                    {...props}
                    onEditClick={handleEditClick}
                    editTooltip={editButton}
                  />
                )}
              </FixedSizeList>
            )}
          </Section>
          <DialogWrapper
            data-testid="editor-form-field-validation-dialog"
            isForm={true}
            keepMounted={false}
            open={Boolean(fieldSchema)}
            title={editSchemaTitle}
            submitTitle={updateButton}
            cancelTitle={cancelButton}
            handleClose={handleClose}
            handleSubmit={onSubmit}
            hasChanges={Boolean(hasChanges)}
            fullWidth={true}
            maxWidth={
              fieldSchema?.type === "object" ||
              fieldSchema?.type?.includes("object")
                ? "md"
                : "sm"
            }
          >
            {fieldSchema && (
              <JsonSchemaProvider
                value={{
                  onDelete: handleDeleteProp,
                  onCreate: handleAddProp,
                }}
              >
                <DialogContent {...fieldSchema} />
              </JsonSchemaProvider>
            )}
          </DialogWrapper>
        </>
      )}
    </>
  );
});
