import { createUseTranslation } from "core/session/translation";

export const internalLinkFieldEditorTranslation = {
  en: {
    linkLabel: "Link",
    labelLabel: "Label",
    isButtonLabel: "Display as a button",
    pageSelectorLabel: "Link To",
  },
};

export const useInternalLinkFieldEditorTranslation = createUseTranslation(
  internalLinkFieldEditorTranslation,
);
