import { HTMLAttributes, memo, useCallback } from "react";
import { ListItem, ListItemText, Tooltip } from "@mui/material";
import IconButtonLink from "@mui/material/IconButton";

import { Autocomplete } from "elementTypes/common/Autocomplete";
import {
  AutocompleteOption,
  IAutocompleteValue,
} from "elementTypes/common/Autocomplete/types";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import {
  BuiltinResponseType,
  ResponseType,
} from "services/api/types/ResponseType";

import { useResponseFormatTranslation } from "./translation";

type Props = {
  value: BuiltinResponseType;
  onChange: (value: BuiltinResponseType) => void;
  recomended?: BuiltinResponseType;
};

export const ResponseFormatAutocomplete = memo<Props>(
  ({ value = "json", onChange, recomended }) => {
    const handleChange = useCallback(
      (nextValue: IAutocompleteValue) =>
        onChange(nextValue as BuiltinResponseType),
      [onChange],
    );

    const formatValue = value ?? recomended ?? "json";

    const t = useResponseFormatTranslation();

    const options = Object.keys(ResponseType.keys).map((type) => ({
      label: t[`${type}Label`],
      secondary: t[`${type}LabelHint`],
      value: type,
      recomended: recomended ? type === recomended : type === "json",
    }));

    const customRenderOption = (
      props: HTMLAttributes<HTMLLIElement>,
      option: AutocompleteOption,
    ) => (
      <ListItem
        {...props}
        {...(option?.recomended && {
          secondaryAction: (
            <IconButton icon="star" tooltip="recomended" color="info" />
          ),
        })}
      >
        <ListItemText
          id={`${option.value}`}
          primary={option.label}
          secondary={option.secondary}
        />
      </ListItem>
    );

    return (
      <Autocomplete
        label={t.responseFormatLabel}
        value={formatValue}
        onChange={handleChange}
        options={options}
        isClearable={false}
        customRenderOption={customRenderOption}
        startAdornment={
          <Tooltip title={t.docsTooltip}>
            <IconButtonLink
              href="https://postgrest.org/en/stable/references/api/resource_representation.html#response-format"
              target="_blank"
              rel="noopener noreferrer"
              size="small"
            >
              <MuiIcon icon="info" />
            </IconButtonLink>
          </Tooltip>
        }
      />
    );
  },
);
