import { PropsWithChildren, memo, useState } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";

import { AdminStateProvider } from "../context";
import useStyles from "../styles";

import { AdminFooter } from "./Footer";
import { AdminHeader } from "./Header";
import { AdminMenu } from "./Menu";
import { adminTheme } from "./adminTheme";
import logoTextPath from "./logo-text.svg";
import logoPath from "./logo.svg";

const AdminThemeProvider = memo<PropsWithChildren>(({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={adminTheme}>{children}</ThemeProvider>
  </StyledEngineProvider>
));

const AdminLayoutComponent = memo<PropsWithChildren>(({ children }) => {
  const {
    classes: {
      root,
      content,
      logo,
      withClosedMenu,
      logoText,
      invisible,
      contentWrapper,
    },
    cx,
  } = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const toggleMenu = () => setOpen((prevOpen) => !prevOpen);

  return (
    <div
      className={cx(root, {
        [withClosedMenu]: !open,
      })}
    >
      <AdminHeader />
      <AdminMenu open={open} toggleMenu={toggleMenu} />

      <div className={logo}>
        <img src={logoPath} alt="CYPEX" />
        <img
          src={logoTextPath}
          alt="CYPEX"
          className={cx(logoText, {
            [invisible]: !open,
          })}
        />
      </div>
      <main className={content}>
        <div className={contentWrapper}>{children}</div>
        <AdminFooter />
      </main>
    </div>
  );
});

export const AdminLayout = memo<PropsWithChildren>(({ children }) => (
  <AdminStateProvider>
    <AdminThemeProvider>
      <AdminLayoutComponent>{children}</AdminLayoutComponent>
    </AdminThemeProvider>
  </AdminStateProvider>
));
