import { createUseTranslation } from "core/session/translation";

// **do not indent markdown**:
const mde = `
— refer to the underlying database tables. 

For enhanced security, CYPEX interacts with these tables through an abstraction layer, **not directly**. 

This layer consists of **Queries** — essentially views in PostgreSQL — which you need to set up **before** creating applications. 

This setup helps safeguard your data by controlling access and modifications.
`;

const databaseTranslations = {
  en: {
    databasePageTitle: "Database - Administration",
    databaseOverviewTitle: "Database",
    entities: "Entities",
    queries: "Queries",
    layoutPlacement: "Node placement layout",
    horizontalTooltip: "Align Horizontally",
    verticalTooltip: "Align Vertically",
    noDataLabel: "No Data",
    createQuery: "Create Query",
    successDelete: "Query deleted successfully",
    successAllDelete: "Queries deleted successfully",
    successUpdate: "Query updated successfully",
    generateAllQueriesTitle: "Generate default queries",
    refreshModelTooltip: "Refresh Data",
    treeItemSchemaTableSingular: "table",
    treeItemSchemaTablePlural: "tables",
    treeItemSchemaViewSingular: "view",
    treeItemSchemaViewPlural: "views",
    closePreviewTitle: "Close",
    previewTitle: "Data preview of",
    closeDiffDefinition: "Close",
    diffDefinitionTitle: "Definition differences in",
    diffLeftTitle: "Previous query definition",
    diffRightTitle: "Current query definition",
    diffToolTip: "Invalid Query. Click to see more details",
    diffNotes:
      "Some changes were made on depending views, and it might cause unexpected behaviour. Please update or recreate this query.",
    deleteQuery: "Delete query",
    deleteQueriesTitle: "Delete All Queries",
    editQuery: "Edit query",
    defaultQuery: "Edit default query",
    tables: "Tables",
    views: "Views",
    enableFocusing: "Enable Focusing",
    disableFocusing: "Disable Focusing",
    searchLabel: "Search...",
    removeQueryWarning:
      "Be aware all objects that depend on view and all objects that depend on those objects will be automatically removed",
    approveDeleteTitle: "Are you sure you want to delete?",
    filterQueriesTitle: "Filter",
    defaultQueriesLabel: "Default",
    customQueriesLabel: "Custom",
    noGroupLabel: "No Group",
    groupViewLabel: "Group View",
    listViewLabel: "List View",
    editQueryGroupTitle: "Edit Group",
    groupLabel: "Title",
    queryGroupLabel: "Group",
    createQueryGroupTitle: "Create Query Group",
    deleteQueryGroupTitle: "Delete Group",
    successEditQueryGroup: "Group edited successfully",
    successDeleteQueryGroup: "Group deleted successfully",
    approveDeleteQueryGroup: "Are you sure you want to delete this group?",
    editdefaultQueryTitle: "Edit Default Query",
    delete: "Delete",
    save: "Save",
    expandAllTooltip: "Expand All",
    collapseAllTooltip: "Collapse all",
    internalPageTitle: "CYPEX Internal DB - Administration",
    internalTitle: "CYPEX Internal DB",
    generateRefreshTooltip: "Refresh",
    filterByRoleTitle: "By Role",
    showMenuTitle: "Show Menu",
    createCustomQueryTitle: "Create Query",
    emptyQueryGroupTitle: "No Queries",
    queryDefinitionTitle: "Query Definition",
    copiedTooltip: "Copied",
    copyTooltip: "Copy",
    deleteAllTitle: "All Queries",
    saveButton: "Save",
    cancelButton: "Cancel",
    dbTooltipTitle: "Entities in CYPEX ",
    dabTooltipText: mde,
  },
  es: {
    databaseOverviewTitle: "Vista de Base de Datos",
    entities: "Entidades",
    queries: "Consultas",
    layoutPlacement: "Disposición de nodos",
    horizontalTooltip: "Horizontal",
    verticalTooltip: "Vertical",
    successDelete: "Consulta eliminada",
    successUpdate: "Consulta actualizada",
    refreshModelTooltip: "Refrescar Datos",
    closePreviewTitle: "Cerrar",
    previewTitle: "Vista previa de datos de",
    closeDiffDefinition: "Cerrar",
    diffDefinitionTitle: "Diferencias en la definición de la consulta",
    diffLeftTitle: "Definición anterior",
    diffRightTitle: "Definición actual",
    diffToolTip: "Consulta Invalidad. Haz click para mas detalles",
    diffNotes:
      "Se realizaron cambios en las vistas dependientes, esto puede causar comportamiento intestperado. Por favor, actualizar o recrear la consulta.",
    deleteQuery: "Borrar consulta",
    editQuery: "Editar consulta",
    defaultQuery: "Editar consulta predeterminada",
    tables: "Tablas",
    views: "Vistas",
    enableFocusing: "Enable Focusing",
    disableFocusing: "Disable Focusing",
    filterQueriesTitle: "Filtrar",
    defaultQueriesLabel: "Default",
    customQueriesLabel: "Custom",
    editdefaultQueryTitle: "Editar",
    delete: "Borrar",
    save: "Guardar",
  },
};

export const useDatabaseTranslation =
  createUseTranslation(databaseTranslations);
