import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  text: {
    width: "100%",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      filter: "brightness(1.2)",
    },
  },
  button: {
    width: "100%",
    height: "100%",
  },
}));

export default useStyles;
