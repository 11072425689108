import { HTMLAttributes, memo } from "react";
import ListItemText from "@mui/material/ListItemText";
import { RefCallBack } from "react-hook-form";

import { Autocomplete } from "../../../../elementTypes/common/Autocomplete";
import {
  AutocompleteOption,
  IAutocompleteValue,
} from "../../../../elementTypes/common/Autocomplete/types";

type Props = {
  options: AutocompleteOption[];
  onChange?: (pageValue: string) => void;
  innerRef?: RefCallBack;
  value?: string | null;
  label?: string;
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
};

export const PageAutocomplete = memo<Props>(
  ({
    options,
    value = null,
    label = "",
    clearable,
    error,
    onChange,
    ...rest
  }) => {
    const handleChange = (pageValue: IAutocompleteValue) =>
      onChange?.(pageValue as string);

    const customRenderOption = (
      props: HTMLAttributes<HTMLLIElement>,
      option: AutocompleteOption,
    ) => (
      <li {...props}>
        <ListItemText
          primary={option.label}
          secondary={option.url}
          secondaryTypographyProps={{ variant: "caption", display: "block" }}
        />
      </li>
    );

    return (
      <Autocomplete
        value={value}
        options={options}
        onChange={handleChange}
        name="pages"
        label={label}
        isLoading={false}
        customRenderOption={customRenderOption}
        defaultItemSize={48}
        virtualizedList={true}
        error={error}
        isClearable={clearable}
        {...rest}
      />
    );
  },
);
