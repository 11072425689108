import { MouseEvent, forwardRef, memo } from "react";
import MLink, { LinkProps as MLinkProps } from "@mui/material/Link";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";

export type ILinkProps = {
  href?: string;
  component?: string;
} & MLinkProps;

const CypexLink = memo(
  forwardRef<HTMLAnchorElement, ILinkProps>((props, ref) => {
    const dispatch = useDispatch();

    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
      if (props.onClick) {
        props.onClick(event);
      }

      if (props.href) {
        event.preventDefault();
        dispatch(routerActions.push(props.href));
      }
    };

    return <MLink {...props} onClick={handleClick} ref={ref} />;
  }),
);

export const Link = forwardRef<HTMLAnchorElement, ILinkProps>((props, ref) => (
  <CypexLink ref={ref} {...props} />
));
