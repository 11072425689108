import { IDefaultElement } from "core/types/element";

import { GridConfig } from "../types";

export const defaultElement: IDefaultElement<GridConfig> = {
  children: {
    content: {
      elements: [],
    },
  },
};
