import * as t from "io-ts";
import { selectors } from "core/editor/reduxModule";
import { Type, buildObjectViewType, types } from "core/runtime-typing";
import {
  ConnectedReduxModuleProps,
  IElement,
  TypeFactory,
  UntransformedConfig,
  customExpression,
} from "core/types";
import { SelectorTypes } from "core/types/element";
import { FixedFilterGroup } from "elementTypes/default_table/toolsPanel/types";

import { EChartsTranslationKeys } from "../common/Echarts";

import { ReduxModule } from "./reduxModule";

const getDataSingleRowType: TypeFactory<CustomChartConfig> = ({
  config,
  state,
}) => {
  let type: Type;
  const viewName = config.dataSource?.viewName;
  const viewList = selectors.viewList(state);
  if (!viewList) {
    // view list still loading, do not show any typing errors
    type = types.any();
  } else if (!viewName) {
    type = types.optional(types.null(), "No source query set");
  } else {
    const view = viewList.find((v) => v.name === viewName);
    if (!view) {
      throw new Error(`Invalid query ${viewName}`);
    }
    type = buildObjectViewType(view, "The data for the row");
    type = types.array(type, "the data rows");
  }
  return type;
};

export const customChartSelectors: SelectorTypes<CustomChartConfig> = {
  loading: types.boolean(),
  data: getDataSingleRowType,
  error: types.nullable(types.string()),
};

export const CustomChartConfig = t.intersection([
  t.type({
    dataSource: t.type({
      viewName: t.string,
    }),
    option: customExpression(t.UnknownRecord),
  }),
  t.partial({
    showBackground: t.boolean,
    filter: customExpression(t.union([t.null, FixedFilterGroup])),
    sort: t.array(
      t.type({
        fieldName: t.string,
        asc: t.boolean,
      }),
    ),
  }),
]);

export type CustomChartConfig = t.TypeOf<typeof CustomChartConfig>;

export type UntransformedCustomChartConfig =
  UntransformedConfig<CustomChartConfig>;

export type CustomChart = IElement<
  CustomChartConfig,
  any,
  EChartsTranslationKeys
>;

export type EchartsCustomProps = ConnectedReduxModuleProps<
  ReduxModule,
  CustomChart
>;
