import { memo, useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";

import { DataPreview } from "./DataPreview";
import { TableColumns } from "./TableColumns";
import { ViewNodeData } from "./ViewNode";
import { useERDTranslation } from "./translation";
import { NodeData } from "./types";

type TableDetailsProps = {
  nodeData: NodeData | ViewNodeData;
};

enum Tabs {
  Preview = "preview",
  Columns = "columns",
}

export const TableDetails = memo<TableDetailsProps>(({ nodeData }) => {
  const translation = useERDTranslation();

  const [value, setValue] = useState<Tabs>(Tabs.Columns);

  const handleChange = (_event: any, newValue: Tabs) => {
    setValue(newValue);
  };

  const tabTitle = {
    [Tabs.Columns]: translation.ColumnDetails,
    [Tabs.Preview]: translation.PreviewData,
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="Table details tabs">
          <Tab label={tabTitle[Tabs.Columns]} value={Tabs.Columns} />
          <Tab label={tabTitle[Tabs.Preview]} value={Tabs.Preview} />
        </TabList>
      </Box>
      <TabPanel
        value={Tabs.Columns}
        style={{ paddingRight: 0, paddingLeft: 0 }}
      >
        <TableColumns columns={nodeData.columns} />
      </TabPanel>
      <TabPanel value={Tabs.Preview}>
        <DataPreview
          schemaName={nodeData.schemaName}
          tableName={
            (nodeData as NodeData)?.tableName ??
            (nodeData as ViewNodeData)?.viewName
          }
        />
      </TabPanel>
    </TabContext>
  );
});
