import { AnyRecordType } from "./AnyRecordType";
import { AnyType } from "./AnyType";
import { ArrayType } from "./ArrayType";
import { BooleanType } from "./BooleanType";
import { DateType } from "./DateType";
import { DeferredTypeType } from "./DeferType";
import { EnumType } from "./EnumType";
import { FileType } from "./FileType";
import { InterfaceType } from "./InterfaceType";
import { LiteralType } from "./LiteralType";
import { NullType } from "./NullType";
import { NullableType } from "./NullableType";
import { NumberType } from "./NumberType";
import { OptionalType } from "./OptionalType";
import { RecordType } from "./RecordType";
import { StringType } from "./StringType";
import { UnionType } from "./UnionType";

function getClassFactory<T extends new (...args: any[]) => any>(cls: T) {
  return ((...args: any[]) => new cls(...args)) as T extends new (
    ...args: infer Args
  ) => infer I
    ? (...args: Args) => I
    : never;
}

export const types = {
  boolean: getClassFactory(BooleanType),
  number: getClassFactory(NumberType),
  string: getClassFactory(StringType),
  null: getClassFactory(NullType),
  date: getClassFactory(DateType),
  file: getClassFactory(FileType),
  literal: getClassFactory(LiteralType),
  enum: getClassFactory(EnumType),
  union: getClassFactory(UnionType),
  optional: getClassFactory(OptionalType),
  nullable: getClassFactory(NullableType),
  interface: getClassFactory(InterfaceType),
  array: getClassFactory(ArrayType),
  record: getClassFactory(RecordType),
  anyRecord: getClassFactory(AnyRecordType),
  any: getClassFactory(AnyType),
  defer: getClassFactory(DeferredTypeType),
};

export { AnyType } from "./AnyType";
export { AnyRecordType } from "./AnyRecordType";
export { ArrayType } from "./ArrayType";
export { BooleanType } from "./BooleanType";
export { DateType } from "./DateType";
export { DeferredTypeType } from "./DeferType";
export { EnumType } from "./EnumType";
export { FileType } from "./FileType";
export { InterfaceType } from "./InterfaceType";
export { LiteralType } from "./LiteralType";
export { NullType } from "./NullType";
export { NullableType } from "./NullableType";
export { NumberType } from "./NumberType";
export { OptionalType } from "./OptionalType";
export { RecordType } from "./RecordType";
export { Type } from "./Type";
export { TypeError } from "./TypeError";
export { StringType } from "./StringType";
export { UnionType } from "./UnionType";
export { buildObjectViewType, buildReferencesType } from "./utils";
export type { IAutocompleteKey } from "./types";
