import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplateAreas: `
    "logo top"
    "menu content"
    `,
    gridTemplateColumns: "240px 1fr",
    gridTemplateRows: "48px 1fr",
    height: "100vh",
    transition: theme.transitions.create("gridTemplateColumns", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
    willChange: "gridTemplateColumns",
  },
  withClosedMenu: {
    gridTemplateColumns: "60px 1fr",
  },
  logo: {
    gridArea: "logo",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img:first-of-type": {
      height: "100%",
      placeSelf: "center",
    },
  },
  logoText: {
    width: "auto",
    height: "26px",
    margin: `0 ${theme.spacing(1.75)}`,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
    willChange: "width",
  },
  invisible: {
    width: 0,
    margin: 0,
  },
  toolbar: {
    height: "48px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    gridArea: "top",
  },
  menu: {
    gridArea: "menu",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  content: {
    overflow: "auto",
    gridArea: "content",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    padding: theme.spacing(1),
    flexGrow: 1,
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    minHeight: 240,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  nested: {
    paddingLeft: `${theme.spacing(4)} !important`,
  },
  listNavContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  addUiFormContainer: {
    marginTop: theme.spacing(3),
  },
  menuIcon: {
    minWidth: "auto !important",
  },
  itemTextClass: {
    margin: "0 !important",
  },
  horizontallyCenter: {
    display: "flex",
    alignItems: "center",
  },
  inlineAndEndFlex: {
    display: "inline-flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  applicationsHeaderButton: {
    marginRight: theme.spacing(),
  },
  warningStyle: {
    marginBottom: theme.spacing(1),
  },
  footer: {
    height: theme.spacing(5),
    minHeight: theme.spacing(5),
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(),
    "& a": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bolder",
      "&:hover": {
        color: theme.palette.primary.contrastText,
      },
    },
    "& p": {
      margin: 0,
    },
  },
  toggleButtonTitle: {
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  blur: {
    filter: "blur(5px)",
    userSelect: "none",
  },
}));

export default useStyles;
