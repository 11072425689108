import { MouseEvent, memo, useContext } from "react";
import TableCell from "@mui/material/TableCell";
import { IElementComponentProps } from "core";

import { useTableContext } from "../default_table";

import { SortLabel } from "./SortLabel";
import { i18nContext } from "./i18nContext";
import { useStyles } from "./style";
import { TableHeaderCell } from "./types";

type TProps = IElementComponentProps<{}, TableHeaderCell>;

const DefaultTableHeaderCell = memo<TProps>(({ element }) => {
  const { getColOrder, orderIndexed, changeOrder, disableSort } =
    useTableContext();

  const colOrder = getColOrder(orderIndexed, element);
  const showOrder =
    colOrder && !colOrder.hidden ? (colOrder.asc ? "asc" : "desc") : undefined;

  let onClick, sortable;
  const { dataSource, width, align } = element.config;
  if (dataSource) {
    onClick = (e: MouseEvent) =>
      changeOrder(dataSource.fieldName, { multi: e.shiftKey });
    sortable = dataSource.sortable;
  } else {
    sortable = false;
  }

  const {
    classes: { cell },
  } = useStyles({ width });
  const { textSortTooltip } = useContext(i18nContext);

  return (
    <TableCell
      onClick={onClick}
      sortDirection={showOrder}
      align={align}
      className={cell}
    >
      <SortLabel
        active={Boolean(colOrder?.asc !== undefined && !colOrder?.hidden)}
        label={element.i18n.label}
        ableToSort={!disableSort && sortable}
        tooltip={textSortTooltip}
        direction={showOrder}
        align={align}
      />
    </TableCell>
  );
});

DefaultTableHeaderCell.displayName = "DefaultTableHeaderCell";

export default DefaultTableHeaderCell;
