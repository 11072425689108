import { ChangeEvent, MouseEvent, memo, useCallback, useEffect } from "react";
import { FormControl, FormLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  ColorSelector,
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { VariantSelector } from "core/editor/common/VariantSelector";
import { IColor } from "core/editor/types";
import { usePrevious } from "utils/hooks";

import { IconNameType, MuiIcon } from "../../common/MuiIcon";
import { ToggleButton } from "../../common/ToggleButton";
import { UntransformedDataDisplayConfig } from "../types";

import { defaultElement } from "./defaultElement";

const colors = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
] as IColor[];

export const DisplayEditor = memo(() => {
  const {
    elementModel: {
      config,
      config: {
        barPosition,
        icon,
        format,
        dataSource: { viewName },
        cardVariant = "outlined",
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDataDisplayConfig>();
  const { config: { format: defaultFormat } = {} } = defaultElement;

  const handleChange = useCallback(
    (...params: Parameters<typeof changeConfigValue>) =>
      changeConfigValue(...params),
    [changeConfigValue],
  );

  const handleFormatChange = (newFormat: string) =>
    handleChange("format", newFormat);

  const prevViewName = usePrevious(viewName);
  useEffect(() => {
    if (viewName !== prevViewName && format !== defaultFormat) {
      handleChange("format", defaultFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewName]);

  const changeIcon = (newIcon: UntransformedDataDisplayConfig["icon"]) =>
    handleChange("icon", newIcon!);

  const handleColorChange = (newColor: string) =>
    handleChange("color", newColor);

  const handleBarPositionChange = (
    _: MouseEvent<HTMLElement>,
    value: string | null,
  ) => handleChange("barPosition", value);

  const handleVariantChange = (
    _: MouseEvent<HTMLElement>,
    value: string | null,
  ) => handleChange("cardVariant", value);

  const customFormatInput = ({
    value: formatValue,
    onChange: onFormatChange,
  }: NonExpressionEditorProps) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onFormatChange(e.target.value);

    return (
      <TextField
        label="Format"
        value={formatValue}
        onChange={onCustomChange}
        fullWidth={true}
      />
    );
  };

  return (
    <Section title="Display" wrapped={true}>
      <CustomExpressionEditor
        value={format}
        config={config}
        onChange={handleFormatChange}
        label="Format"
        nonExpressionEditor={customFormatInput}
      />
      <IconAutocomplete
        label="Icon"
        value={icon as IconNameType}
        onChange={changeIcon}
      />
      <ColorSelector
        config={config}
        onChange={handleColorChange}
        colors={colors}
        label="Color"
        defaultValue="info"
      />
      <VariantSelector />
      <FormControl fullWidth={true}>
        <FormLabel component="p">{"Bar position"}</FormLabel>
        <ToggleButtonGroup
          value={barPosition}
          exclusive
          onChange={handleBarPositionChange}
          fullWidth
        >
          <ToggleButton value="left" aria-label="left">
            <MuiIcon icon="border_left" fontSize="large" />
          </ToggleButton>
          <ToggleButton value="top" aria-label="top">
            <MuiIcon icon="border_top" fontSize="large" />
          </ToggleButton>
          <ToggleButton value="right" aria-label="right">
            <MuiIcon icon="border_right" fontSize="large" />
          </ToggleButton>
          <ToggleButton value="bottom" aria-label="bottom">
            <MuiIcon icon="border_bottom" fontSize="large" />
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel component="p">{"Card variant"}</FormLabel>
        <ToggleButtonGroup
          value={cardVariant}
          exclusive
          onChange={handleVariantChange}
          fullWidth
        >
          <ToggleButton value="outlined" aria-label="outlined">
            Outlined
          </ToggleButton>
          <ToggleButton value="elevation" aria-label="elevation">
            Elevation
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </Section>
  );
});
