import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  root: {
    width: "100%",
  },
  inputEndAdornment: {
    top: "calc(50% - 12px)",

    "& [class*='MuiButtonBase-root']": {
      color: "inherit",
    },
  },
}));

export const useListStyles = makeStyles()((theme: Theme) => ({
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
    },
    "& [class*='MuiAutocomplete-option']": {
      right: 0,
      width: "auto",
      boxSizing: "border-box",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    "& [class*='MuiListItemText-root']": {
      width: "100%",
    },
    "& span[class*='MuiTypography-root']": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
}));
