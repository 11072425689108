import { memo } from "react";
import { Box, Typography } from "@mui/material";

import Button from "../../../../../elementTypes/common/Button";
import {
  IconNameType,
  MuiIcon,
} from "../../../../../elementTypes/common/MuiIcon";

export const EmptyData = memo<{
  title: string;
  icon?: IconNameType;
  onClick?: () => void;
}>(({ title, icon, onClick }) => {
  return (
    <Box
      p={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={24}
      color="action.disabled"
      height="100%"
    >
      <Box display="flex" alignItems="center" gap={16}>
        {icon && <MuiIcon icon={icon} fontSize="large" />}
        <Typography color="inherit" variant="h6">
          {title}
        </Typography>
      </Box>
      {onClick && (
        <Button
          label="Create New"
          color="primary"
          size="large"
          onClick={onClick}
        />
      )}
    </Box>
  );
});
