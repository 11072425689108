import { memo, useMemo } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";
import { TElementModelWithPosition } from "core";
import { useElementTypesContext } from "core/ElementTypesContext";
import { useElementEditorContext } from "core/editor";
import { Language } from "core/types";
import { getTranslatedTexts } from "core/utils/element-utils";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";
import { usePage } from "utils/hooks";

import { CellAlignment, UntransformedTableConfig } from "../../../types";

import { ColumnConfig } from "./ColumnConfig";
import { ColumnRow } from "./ColumnRow";
import { useColumnsContext } from "./ColumnsContext";

export type IColumn = TableHeaderCell & {
  position: {
    column: number;
  };
};

export type ColumnDetails =
  | (Omit<IColumn, "i18n"> & {
      label: string;
      index: number;
      isNew?: boolean;
      isHidden?: string | null;
    })
  | ReturnType<typeof newColumnParams>;
export type ExtendedColumnConfig = ReturnType<typeof getHeaderConfig>;

type IItem = IColumn | string | null;

const columnItemSize = 48;

export const Columns = memo(() => {
  const {
    elementModel,
    elementModel: {
      children: {
        body: { elements: bodyColumns },
        header: { elements: columns },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTableConfig>();
  const page = usePage();
  const {
    language,
    hidden,
    updateChildren,
    setColumnDetails,
    deleteColumn,
    selectElement,
  } = useColumnsContext();
  const { elementTypes: allElementTypes } = useElementTypesContext();

  const { filteredColumns, searchValue } = useColumnsContext();

  function onDragEnd({ destination, source }: DropResult) {
    if (!destination || source.index === destination.index) {
      return;
    }
    updateChildren(
      elementModel,
      reorderList(columns, source.index, destination.index) as IColumn[],
      page!,
      "header",
    );
    updateChildren(
      elementModel,
      reorderList(bodyColumns, source.index, destination.index) as IColumn[],
      page!,
      "body",
    );
    if (hidden.length > 1) {
      changeConfigValue(
        "hidden",
        reorderList(hidden, source.index, destination.index),
      );
    }
  }

  function selectBodyColumn(index: number) {
    const columnElement: TElementModelWithPosition | undefined =
      bodyColumns[index];

    if (columnElement) {
      const elementType = allElementTypes[columnElement.type.name];

      // check if the element type actually exists
      // this is just an additional check for the case that an unknown element
      // type is set (e.g. when the app definition was manually changed)
      if (elementType) {
        selectElement(columnElement, elementType, page!);
      }
    }
  }

  type Action = "edit" | "delete" | "select";

  function handleBtnClick(type: Action, params: ColumnDetails | number) {
    const handleClick = {
      edit: setColumnDetails,
      delete: deleteColumn,
      select: selectBodyColumn,
    };

    handleClick[type](params as any);
  }

  function isDisabledColumn(index: number) {
    const columnElement: TElementModelWithPosition | undefined =
      bodyColumns[index];

    if (columnElement) {
      // if the editorComponent is defined return disabled as false,
      // if not return disabled as true.
      return !allElementTypes[columnElement.type.name].editorComponent;
    }

    return true;
  }

  const listHeight = useMemo(
    () => columnItemSize * Math.min(filteredColumns.length, 5),
    [filteredColumns.length],
  );

  const dropDisabled = !!searchValue.trim();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        isDropDisabled={dropDisabled}
        renderClone={(provided, snapshot, { source: { index } }) => {
          const { i18n, ...rest } = filteredColumns[index];
          const label = getTranslatedTexts(language, i18n).label;
          const disabled = isDisabledColumn(index);

          return (
            <ColumnConfig
              {...rest}
              label={label}
              provided={provided}
              snapshot={snapshot}
              onBtnClick={handleBtnClick}
              index={index}
              disabled={disabled}
            />
          );
        }}
      >
        {(provided) => (
          <FixedSizeList
            height={listHeight}
            itemCount={filteredColumns.length}
            itemSize={columnItemSize}
            width="100%"
            outerRef={provided.innerRef}
            itemData={{
              columns: filteredColumns,
              handleBtnClick,
              isDisabledColumn,
            }}
          >
            {ColumnRow}
          </FixedSizeList>
        )}
      </Droppable>
    </DragDropContext>
  );
});

function newColumnParams(order: number) {
  return {
    name: "",
    label: "",
    config: {
      dataSource: {
        fieldName: "",
        sortable: false,
      },
      align: "center" as CellAlignment,
    },
    position: {
      column: order,
      row: 1,
      width: 1,
      height: 1,
    },
    isNew: true,
    index: order - 1,
    isHidden: null as null | string,
  };
}

function getHeaderConfig(
  data: Record<string, any>,
  lang: Language,
  order: number,
) {
  return {
    name: data.name,
    i18n: { [lang.code]: { label: data.label } },
    type: "default_table_header_cell",
    config: {
      dataSource: {
        fieldName: data.fieldName || "",
        sortable: data.sortable,
      },
      align: data.align,
      ...(!(data.width === "auto") && { width: "1px" }),
    },
    position: {
      column: order,
      height: 1,
      width: 1,
      row: 1,
    },
  };
}

const reorderList = (
  list: (IItem | string | null)[],
  startIndex: number,
  endIndex: number,
): (IItem | string | null)[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (removed !== undefined) {
    result.splice(endIndex, 0, removed);
  }

  return result.map((item, index) => {
    if (item === null || typeof item === "string") {
      return item;
    } else if (typeof item === "object") {
      return {
        ...item,
        position: {
          ...item.position,
          column: index + 1,
        },
      };
    }
    return item;
  });
};
