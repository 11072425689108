import { memo } from "react";
import { useSelector } from "react-redux";

import { Element, IElementComponentProps } from "core";

import { selectors as editorSelectors } from "core/editor/reduxModule";

import {
  AnyElementModelWithPosition,
  AnyElementWithPosition,
} from "core/types";

import { StaticGrid } from "./components";
import { EditorGrid } from "./editor/EditorGrid";
import { Grid, GridChildren } from "./types";

type Props = IElementComponentProps<Record<string, unknown>, Grid>;

export type ChildElement = AnyElementWithPosition;
export type ChildElementModel = AnyElementModelWithPosition;

const DefaultGrid = memo<Props>(({ element: initialElement, elementModel }) => {
  const editModeOn = useSelector(editorSelectors.editModeOn);
  /**
   * At the moment, we need to access `updatedElements` inside `DefaultGrid`
   * because this is the only way to manage grid elements while editing.
   * The parent grid has no information about changes
   * taking place inside the child <Element /> while it renders.
   */
  const updatedElements = useSelector(editorSelectors.updatedElements);

  const element = (updatedElements[initialElement.originalId] ||
    initialElement) as any;
  const { children } = element;

  const elements = (children as GridChildren).content.elements.map(
    (child: ChildElementModel) => updatedElements[child.id] || child,
  );

  const elementsWithoutMeta = elements.filter(
    (e): e is ChildElementModel => "position" in e && e.position.row > 0,
  );
  const metaElements = elements.filter(
    (e): e is ChildElementModel => "position" in e && e.position.row <= 0,
  );

  const isFormGrid = initialElement.name === "form_grid";

  const component = editModeOn ? (
    <EditorGrid
      elementModel={elementModel}
      element={{
        ...element,
        children: {
          ...element.children,
          content: {
            ...element.children.content,
            elements: elementsWithoutMeta,
          },
        },
      }}
    />
  ) : (
    <StaticGrid
      elementProps={element.props}
      elements={elementsWithoutMeta}
      isFormGrid={isFormGrid}
    />
  );

  return (
    <>
      {metaElements.map((child) => (
        <div
          key={child.id}
          style={{
            display: "none",
          }}
        >
          <Element element={child} elementProps={element.props} />
        </div>
      ))}
      {component}
    </>
  );
});

DefaultGrid.displayName = "DefaultGrid";

export default DefaultGrid;
