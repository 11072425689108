import { Theme, alpha } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<void, "wrapper" | "label">()(
  (theme: Theme, _params, classes) => ({
    wrapper: {
      width: "100%",
      height: "100%",
      overflowY: "unset",
      scrollbarWidth: "thin",
      minHeight: "20px",
      "&::-webkit-scrollbar": {
        width: 6,
        background: "#f0f0f0",
        opacity: 0,
      },
      position: "relative",
      outlineOffset: "-1px",
      "&.isSelected": {
        outline: "",
      },
      "&.isGrid": {
        overflowY: "scroll",
      },
      ".react-grid-item:hover > &, &:hover, &.isSelected, &.shouldHighlightBorders":
        {
          outline: `1px dashed ${theme.palette.divider}`,
        },
      ".react-grid-item.isGrid:hover > &, &.isGrid:hover, &.isSelected.isGrid, &.shouldHighlightBorders.isGrid":
        {
          outline: "unset",
        },
      "&:hover > .EditButton": {
        display: "flex",
      },
      "&.isActiveGrid:hover > .EditButton": {
        display: "none",
      },
      "& ~ .react-resizable-handle": {
        opacity: 0,
        zIndex: theme.zIndex.modal - 50,
      },
      ".react-grid-layout > .react-grid-item:hover & ~ .react-resizable-handle, &.isSelected ~ .react-resizable-handle":
        {
          opacity: 1,
        },
    },
    label: {
      visibility: "hidden",
      position: "absolute",
      top: 0,
      left: 0,
      transform: "translateY(-90%)",
      height: "auto",
      zIndex: 999,
      opacity: 0,
      transition: "opacity 0.2s ease",
      background: alpha(theme.palette.info.light, 0.05),
      color: theme.palette.info.light,
      padding: theme.spacing(0, 0.5),
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
      [`.${classes.wrapper}:not(.isGrid).isSelected > &`]: {
        visibility: "visible",
        opacity: 1,
        background: alpha(theme.palette.info.main, 0.05),
        color: theme.palette.info.main,
      },
      [`.react-grid-item:hover > .${classes.wrapper}:not(.isGrid) > &, .${classes.wrapper}:not(.isGrid):hover > &`]:
        {
          visibility: "visible",
          opacity: 1,
        },
      userSelect: "none",
    },
    handle: {
      // only show the handle if in the active grid
      // this also hides the handle for table columns
      display: "none",
      [`.react-grid-layout > .react-grid-item > .${classes.wrapper} > .${classes.label} &`]:
        {
          display: "block",
        },
      cursor: "grab",
      "&:active": {
        cursor: "grabbing",
      },
    },
    topPadding: {
      paddingTop: theme.spacing(1.25),
    },
  }),
);
