import { memo } from "react";
import { TreeItem } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { DroppableProvided } from "react-beautiful-dnd";
import IconButton from "elementTypes/common/IconButton";
import { useStyles } from "staticPages/admin/pages/modelBuilder/components/styles.ts";
import {
  useQueriesPanelContext,
  useQueryGroupEmptyDroppableContext,
} from "staticPages/admin/pages/modelBuilder/context/queriesPanel/QueriesPanelContext.utils.ts";
import { useDatabaseTranslation } from "staticPages/admin/pages/modelBuilder/translation.ts";
import { useQueryGroupStyles } from "../../styles.ts";

export const EmptyQueryGroup = memo(() => {
  const {
    index,
    label,
    id,
    handleDeleteClick,
    handleEditClick,
    provided,
    queryGroup,
    isDraggingGlobal,
    snapshot: { isDraggingOver },
  } = useQueryGroupEmptyDroppableContext();
  const { showActions } = useQueriesPanelContext();
  const { emptyQueryGroupTitle, editQueryGroupTitle, deleteQueryGroupTitle } =
    useDatabaseTranslation();
  const {
    classes: { textOverflow },
  } = useStyles();
  const {
    classes: { droppableStyle, droppablePadding },
    cx,
  } = useQueryGroupStyles();

  return (
    <>
      <span style={{ display: "none" }}>
        {(provided as DroppableProvided).placeholder}
      </span>
      <TreeItem
        nodeId={String(queryGroup?.id ?? id)}
        key={`${label}-${index}-tree-item`}
        data-testid={`query-group-${String(queryGroup?.id ?? id)}`}
        ref={provided.innerRef}
        label={
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
            className={cx({
              [droppablePadding]: isDraggingGlobal,
              [droppableStyle]: isDraggingOver,
            })}
          >
            <Typography
              noWrap
              className={textOverflow}
              sx={{
                py: 0,
                pl: 1,
                flex: 1,
              }}
            >
              {label}
            </Typography>
            <Box display="flex" flexDirection="row">
              {showActions && (
                <>
                  <IconButton
                    icon="edit"
                    onClick={handleEditClick(queryGroup)}
                    size="small"
                    tooltip={editQueryGroupTitle}
                    data-testid={`query-group-edit-${id}`}
                  />
                  <IconButton
                    icon="delete_outline"
                    onClick={handleDeleteClick(queryGroup)}
                    size="small"
                    tooltip={deleteQueryGroupTitle}
                  />
                </>
              )}
            </Box>
          </Box>
        }
      >
        <Typography
          noWrap
          className={textOverflow}
          sx={{
            py: 1,
            pl: 5,
            flex: 1,
          }}
        >
          {emptyQueryGroupTitle}
        </Typography>
      </TreeItem>
    </>
  );
});
