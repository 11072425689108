import { ReactNode, memo } from "react";
import Alert, { AlertProps } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box, { BoxProps } from "@mui/material/Box";

import { useStyles } from "./style";

const customColors = ["teal"] as const;

type CustomColor = (typeof customColors)[number];

type AlertWithMsg = {
  message: string | ReactNode;
  alertTitle?: string | ReactNode;
};

type AlertWithTitle = {
  alertTitle: string | ReactNode;
  message?: string | ReactNode;
};

type AlertColor = CustomColor | AlertProps["color"];

export type AlertBoxProps = {
  color?: AlertColor;
  boxProps?: BoxProps;
} & Omit<AlertProps, "color"> &
  (AlertWithMsg | AlertWithTitle);

export const AlertBox = memo<AlertBoxProps>(
  ({
    message,
    alertTitle,
    color = "error",
    variant = "outlined",
    boxProps = {},
    ...rest
  }) => {
    const { classes, cx } = useStyles();
    const isCustom = customColors.includes(color as CustomColor);
    return (
      <Box {...boxProps}>
        <Alert
          className={cx({
            [classes[color as CustomColor]]: isCustom,
          })}
          color={isCustom ? undefined : (color as AlertProps["color"])}
          variant={variant}
          {...rest}
        >
          {alertTitle && (
            <AlertTitle
              {...(!message && {
                mb: 0,
              })}
            >
              {alertTitle}
            </AlertTitle>
          )}
          {message}
        </Alert>
      </Box>
    );
  },
);
