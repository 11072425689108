import { MouseEvent, lazy, memo, useCallback, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { openSaveFileDialog } from "utils/csv";
import { useSnackbar } from "utils/hooks/useSnackbar";

import IconButton from "../../common/IconButton";
import { MuiIcon } from "../../common/MuiIcon";
import { withLazyLoading } from "../../helpers/HOC/LazyLoading";
import { useTableTranslation } from "../translation";

import { CreateButtonProps } from "../types";
import { TableToolsProvider } from "./TableToolsContext";
import { useStyles } from "./style";
import { IFilterField, IFilterGroup } from "./types";
import { dataToCSV, getFilterLength } from "./utils";

interface IProps {
  filter: IFilterGroup;
  fields: IFilterField[];
  searchInputValue: string;
  data: any[] | null;
  applyFilter: () => void;
  changeSearchValue: (value: string) => void;
  filterDisabled?: boolean;
  loading?: boolean;
  simpleFilter?: string[];
  isPaper?: boolean;
  createButton?: CreateButtonProps;
}

const DefaultTableFilter = withLazyLoading(
  lazy(() => import("./DefaultTableFilter")),
  true,
);
const SimpleSearch = withLazyLoading(
  lazy(() => import("./SimpleSearch")),
  true,
);

const CreateButton = withLazyLoading(
  lazy(() =>
    import("../components/CreateButton").then((module) => ({
      default: module.CreateButton,
    })),
  ),
  true,
);

const ToolsPanel = memo<IProps>(
  ({
    filter,
    fields,
    filterDisabled,
    simpleFilter,
    searchInputValue,
    loading,
    data,
    applyFilter,
    changeSearchValue,
    createButton,
  }) => {
    const [openDialogFilter, setOpenDialogFilter] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const showSnackbar = useSnackbar();

    const { actionsTitleButton, filterTitle, exportTitle } =
      useTableTranslation();

    const handleToggle = useCallback(
      (event: MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget),
      [],
    );

    const handleClose = useCallback(() => setAnchorEl(null), []);

    const handleToggleDialogFilter = useCallback(() => {
      handleClose();

      setOpenDialogFilter((prevOpen) => !prevOpen);
    }, [handleClose]);

    const handleExportData = useCallback(() => {
      if (data) {
        const csv = dataToCSV(data);
        if (typeof csv === "string") {
          openSaveFileDialog(csv, "download.csv");
          handleClose();
        } else {
          showSnackbar(csv.message, "error");
        }
      }
    }, [data, handleClose, showSnackbar]);

    const filterLength = getFilterLength(filter);

    const {
      classes: { menuIcon },
    } = useStyles();

    const filterMenuItem = useMemo(
      () => (
        <MenuItem
          onClick={handleToggleDialogFilter}
          disabled={filterDisabled}
          selected={Boolean(filterLength)}
        >
          <MuiIcon icon="filter_list" fontSize="small" className={menuIcon} />
          <ListItemText primary={filterTitle} />
          {!!filterLength && (
            <Box ml={0.5}>
              <Chip label={filterLength} color="secondary" size="small" />
            </Box>
          )}
        </MenuItem>
      ),
      [
        filterDisabled,
        filterLength,
        filterTitle,
        menuIcon,
        handleToggleDialogFilter,
      ],
    );

    const exportMenuItem = useMemo(
      () => (
        <MenuItem disabled={!data?.length} onClick={handleExportData}>
          <MuiIcon
            icon="cloud_download"
            fontSize="small"
            className={menuIcon}
          />
          <ListItemText primary={exportTitle} />
        </MenuItem>
      ),
      [data, exportTitle, handleExportData, menuIcon],
    );

    return (
      <TableToolsProvider
        value={{
          fields,
          filter,
          openDialogFilter,
          searchInputValue,
          loading: Boolean(loading),
          filterDisabled: Boolean(filterDisabled),
          simpleFilter,
          applyFilter,
          handleToggleDialogFilter,
          changeSearchValue,
        }}
      >
        <Stack
          p={0.5}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          {simpleFilter && <SimpleSearch />}
          {createButton && <CreateButton {...createButton} />}
          <IconButton
            icon="more_horiz"
            aria-controls={anchorEl ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            tooltip={actionsTitleButton}
            placement="top"
            badgeProps={{
              color: "secondary",
              variant: "dot",
              invisible: !filterLength,
            }}
          />
        </Stack>
        <Menu
          id="menu-list-grow"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted={true}
          onClose={handleClose}
        >
          {filterMenuItem}
          {exportMenuItem}
        </Menu>
        {filter && <DefaultTableFilter />}
      </TableToolsProvider>
    );
  },
);

ToolsPanel.displayName = "ToolsPanel";

export default ToolsPanel;
