import { MouseEvent, memo, useCallback, useMemo, useState } from "react";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { IUi } from "core";
import { useSessionContext } from "core/session";
import { getTranslatedTexts } from "core/utils/element-utils";
import { MuiIcon } from "elementTypes/common/MuiIcon";

import { PropsFromRedux } from "./container";
import { useAppSwitchTranslation } from "./translations";

export type IProps = PropsFromRedux & IconButtonProps;

const AppSwitch = memo<IProps>(({ changeUi, uiOptions, color, editModeOn }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { language } = useSessionContext();

  const { switchApp } = useAppSwitchTranslation();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    [],
  );

  const handleClose = useCallback(
    (ui?: IUi) => () => {
      setAnchorEl(null);

      if (ui) {
        changeUi(ui);
      }
    },
    [changeUi],
  );

  const options = useMemo(
    () =>
      uiOptions?.map((uiO) => (
        <MenuItem onClick={handleClose(uiO)} key={uiO.name}>
          {getTranslatedTexts(language, uiO.i18n).label}
        </MenuItem>
      )),
    [uiOptions, language, handleClose],
  );

  if (!(options && options.length)) {
    return null;
  }

  return (
    <>
      <Tooltip title={switchApp}>
        <IconButton color={color} onClick={handleClick} disabled={editModeOn}>
          <MuiIcon icon="apps" fontSize="large" />
        </IconButton>
      </Tooltip>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose()}
      >
        {options}
      </Menu>
    </>
  );
});

AppSwitch.displayName = "AppSwitch";

export default AppSwitch;
