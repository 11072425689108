import { Key, pathToRegexp } from "path-to-regexp";
import { v4 as uuid } from "uuid";

import { TStaticCompiledRoute } from "../types";

import { createRouterComponent } from "./Router";
import { RouterService } from "./RouterService";
import { IStaticRoutesConfig, TStaticRoutesComponentsMap } from "./types";

const generateRoutes = (routes: IStaticRoutesConfig) => {
  let staticRoutesComponentsMap: TStaticRoutesComponentsMap = {};
  let routesCompiled: TStaticCompiledRoute[] = [];

  const paths = Object.keys(routes);

  for (const path of paths) {
    const id = uuid();
    const keys: Key[] = [];
    const re = pathToRegexp(path, keys);
    const staticRoute = routes[path];

    routesCompiled.push({
      keys,
      id,
      pattern: re,
      auth: staticRoute.auth,
      isAdmin: !!staticRoute.isAdmin,
    });

    if (staticRoute.routes) {
      const routesGenerated = generateRoutes(staticRoute.routes);

      routesCompiled = routesCompiled.concat(routesGenerated.routes);
      staticRoutesComponentsMap = Object.assign(
        {},
        staticRoutesComponentsMap,
        routesGenerated.staticRoutesComponentsMap,
      );
    }
    staticRoutesComponentsMap[id] = staticRoute;
  }

  return { routes: routesCompiled, staticRoutesComponentsMap };
};

export function createRouter(staticRoutes: IStaticRoutesConfig) {
  const routes = generateRoutes(staticRoutes);

  const service = new RouterService(routes.routes);
  const Router = createRouterComponent(routes.staticRoutesComponentsMap);

  return { service, Router };
}

export { getSampleUrl } from "./reduxModule";
export { prefixPageUrl } from "./reduxModule/utils";
export * from "./urlService";
