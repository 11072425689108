import { actions as routerActions } from "core/router/reduxModule";
import {
  IElementComponentProps,
  PropsFromConnector,
  createColorSelector,
} from "core/types";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ExternalLinkField } from "./types";

const mapStateToProps = (
  state: any,
  {
    element,
  }: IElementComponentProps<Record<string, unknown>, ExternalLinkField>,
) => ({
  link: element.config.link(state),
  color: createColorSelector(element.config.color)(state),
});

const connector = connectElement<
  Record<string, unknown>,
  ExternalLinkField,
  typeof mapStateToProps,
  typeof routerActions
>(mapStateToProps, routerActions);

export type Props = PropsFromConnector<typeof connector>;

export default connector(Component);
