import { connect } from "react-redux";

import { selectors as routerSelectors } from "../../router/reduxModule";
import { selectors as sessionSelectors } from "../reduxModule";
import { selectors } from "../reduxModule";

import { Layout as LComponent } from "./component";

const mapStateToProps = (state: any) => ({
  loadAppMetadata: selectors.loadAppMetadata(state),
  appMetadata: selectors.appMetadata(state),
  ui: selectors.ui(state),
  page: routerSelectors.page(state),
  isStaticPage: routerSelectors.isStaticPage(state),
  isAdminPage: routerSelectors.isAdminPage(state),
  isLoggedIn: selectors.isLoggedIn(state),
  isAdmin: sessionSelectors.isAdmin(state),
});

export type MapStateToProps = typeof mapStateToProps;

export const Layout = connect(mapStateToProps)(LComponent);
