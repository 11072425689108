import { memo } from "react";
import TipsAndUpdates from "@mui/icons-material/TipsAndUpdates";
import IconButton from "@mui/material/IconButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { lime, teal } from "@mui/material/colors";
import { Theme, keyframes, styled } from "@mui/material/styles";
import Markdown from "react-markdown";

const colorShift = keyframes`
  0%, 100% {
    color: ${teal[600]};
  }
  50% {
    color: ${lime[500]};
  }
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }: { theme: Theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: teal[50],
    color: teal[900],
    maxWidth: "auto",
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${teal[900]}`,
  },
}));

const TealIconButton = styled(IconButton)({
  color: teal[400],
  animation: `${colorShift} 5s ease-in-out 3`,
});

type Props = {
  title?: string;
  text?: string;
};

export const Tip = memo<Props>(({ title, text }) => {
  return (
    <HtmlTooltip
      title={
        <>
          {title ? (
            <Typography variant="h6" color="inherit">
              {title}
            </Typography>
          ) : null}
          {text ? <Markdown>{text}</Markdown> : null}
        </>
      }
    >
      <TealIconButton>
        <TipsAndUpdates color="inherit" />
      </TealIconButton>
    </HtmlTooltip>
  );
});
