import { FocusEventHandler, HTMLAttributes, memo } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AutocompleteGetTagProps,
  AutocompleteRenderOptionState,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import matchSorter from "match-sorter";

import { AutocompleteOption } from "../types";

import { CommonProps } from "./BaseAutocomplete";
import { useListStyles, useStyles } from "./styles";

type MultiAutocompleteProps = {
  value: AutocompleteOption[];
  onChange: (value: string[] | null, reason?: string) => void;
  onBlur?: FocusEventHandler<HTMLElement>;
  allowSameValue?: boolean;
  withCheckbox?: boolean;
  chipVariant?: any;
};

type Props = CommonProps & MultiAutocompleteProps;

export const MultiAutocomplete = memo<Props>(
  ({
    value,
    isClearable,
    options,
    disabled,
    isLoading,
    size,
    chipVariant,
    allowSameValue,
    autosuggestHighlight,
    withCheckbox,
    virtualizedList,
    Listbox,
    searchInputValue,
    disablePortal,
    onChange,
    renderInput,
    getOptionLabel,
    highlightedOption,
    customRenderOption,
    onInputChange,
    getOptionDisabled,
    onBlur,
  }) => {
    const {
      classes: { root },
    } = useStyles();

    const handleChange = (_: any, valueObject: any, reason: string) => {
      const newValue = valueObject
        ? valueObject.map((obj: AutocompleteOption) => obj.value)
        : null;
      onChange(newValue, reason);

      // If the user handle click on the clear icon button
      // It will clear the input value
      if (reason === "clear") {
        onInputChange?.("");
      }
    };

    const renderTags = (
      tags: AutocompleteOption[],
      getTagProps: AutocompleteGetTagProps,
    ) =>
      tags?.length
        ? tags.map(
            (
              { label: tagLabel, value: tagValue }: AutocompleteOption,
              index: number,
            ) => (
              <Chip
                variant={chipVariant}
                label={tagLabel}
                data-tab-index={getTagProps({ index })["data-tag-index"]}
                size={size}
                {...getTagProps({ index })}
                key={`${index}-${tagValue}`}
              />
            ),
          )
        : undefined;

    const renderOption = (
      { className, ...props }: HTMLAttributes<HTMLLIElement>,
      option: any,
      { inputValue, selected }: AutocompleteRenderOptionState,
    ) => {
      const highlighted = highlightedOption(option, inputValue);

      return (
        <ListItem {...props} disablePadding>
          <ListItemButton
            role={undefined}
            dense
            className={className}
            sx={{
              width: "100% !important",
            }}
          >
            {option.value !== Infinity && withCheckbox && !allowSameValue && (
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": option.value }}
                />
              </ListItemIcon>
            )}
            <ListItemText
              id={option.id}
              primary={autosuggestHighlight ? highlighted : option.label}
            />
          </ListItemButton>
        </ListItem>
      );
    };

    const filterOptions = (
      selectOptions: AutocompleteOption[],
      { inputValue }: any,
    ) => {
      const opts =
        allowSameValue || withCheckbox
          ? selectOptions
          : selectOptions.filter(
              (o: AutocompleteOption) =>
                !value.some((v) => v.value === o.value),
            );
      const moreValuesOption = selectOptions.find(
        (option) => option.value === Infinity,
      );
      if (moreValuesOption) {
        return [
          ...matchSorter(
            selectOptions.filter((option) => option.value !== Infinity),
            inputValue,
            {
              keys: ["label", "value"],
            },
          ),
          moreValuesOption as AutocompleteOption,
        ];
      } else {
        return matchSorter(opts, inputValue, { keys: ["label", "value"] });
      }
    };

    const getOptionSelected = (
      option: AutocompleteOption,
      valueOption: AutocompleteOption,
    ) => !allowSameValue && option.value === valueOption.value;

    const { classes } = useListStyles();
    const portal = disablePortal ?? false;

    return (
      <Autocomplete
        classes={classes}
        multiple={true}
        className={root}
        options={options}
        disableClearable={!isClearable}
        inputValue={searchInputValue ?? ""}
        value={value}
        disabled={disabled}
        loading={isLoading}
        ListboxComponent={virtualizedList ? Listbox : undefined}
        size={size}
        renderOption={
          autosuggestHighlight || withCheckbox
            ? renderOption
            : customRenderOption
        }
        renderInput={renderInput}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        disableCloseOnSelect={withCheckbox}
        isOptionEqualToValue={getOptionSelected}
        onChange={handleChange}
        renderTags={renderTags}
        getOptionDisabled={getOptionDisabled}
        onBlur={onBlur}
        ListboxProps={{
          "aria-labelledby": "autocomplete-options-popup",
        }}
        disablePortal={portal}
      />
    );
  },
);

MultiAutocomplete.displayName = "MultiAutocomplete";
