import { memo } from "react";
import Box from "@mui/material/Box";
import { IElementComponentProps } from "core";

import { StyledTypography } from "../common/StyledTypography";
import { Color } from "../common/StyledTypography/utils";

import { TextField } from "./types";

export type Alignment = "start" | "center" | "end";

interface IProps
  extends IElementComponentProps<Record<string, unknown>, TextField> {
  text: string | number | null;
  color?: Color;
  background?: Color;
}

const DefaultTextField = memo<IProps>(
  ({
    text,
    color,
    background,
    element: {
      config: {
        rounded,
        fitContent,
        verticalAlignment = "start",
        horizontalAlignment = "start",
        variant,
        size,
        fontVariant = "body1",
        wrap = true,
      },
    },
  }) =>
    text ? (
      <Box
        display="flex"
        height="100%"
        {...(fitContent && {
          flexDirection: "column",
          alignItems: horizontalAlignment,
          justifyContent:
            verticalAlignment === "end" ? "flex-end" : verticalAlignment,
        })}
      >
        <StyledTypography
          text={text}
          color={color}
          fitContent={fitContent}
          boxProps={{
            px: background ? (size === "small" ? 0.75 : 1) : 0,
            py: background ? (size === "small" ? 0.25 : 1) : 0,
            ...(variant === "outlined" && background
              ? {
                  border: 1.5,
                  borderColor: background,
                }
              : {
                  bgcolor: background,
                }),
            borderRadius: rounded ? 28 : "borderRadius",
            ...(!fitContent && {
              alignItems:
                verticalAlignment === "end" ? "flex-end" : verticalAlignment,
              justifyContent: horizontalAlignment,
            }),
            whiteSpace: wrap ? "pre-wrap" : "pre",
            overflow: "auto",
          }}
          typographyProps={{
            variant: fontVariant,
          }}
        />
      </Box>
    ) : (
      <span />
    ),
);

export default DefaultTextField;
