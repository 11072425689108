import { memo } from "react";
import {
  Filter,
  Legend,
  ToolboxAndColors,
} from "elementTypes/common/Echarts/editor";
import { LegendLabels } from "elementTypes/common/Echarts/editor/legend/translation/LegendLabels.tsx";

import {
  SetupComponent,
  SortComponent,
  TranslationComponent,
} from "./components";

export const EchartsBarChartEditor = memo(() => {
  return (
    <>
      <SetupComponent />
      <LegendLabels />
      <Legend />
      <ToolboxAndColors />
      <TranslationComponent />
      <SortComponent />
      <Filter />
    </>
  );
});
