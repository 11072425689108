import { useStyles } from "./styles";

{
  // Previous styles, now we are using CI colors
  // https://cybertec.atlassian.net/wiki/spaces/MAR/pages/2082045953/Corporate+Design#Farben
  /* <style type="text/css">
      .st0{fill:#41404A;}
      .st1{fill:#4EC1EC;}
    </style> */
}

export const Logo = () => {
  const {
    classes: { st0, st1 },
  } = useStyles();
  return (
    <svg
      version="1.1"
      viewBox="0 0 1001.4 216.96"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
    >
      <g transform="translate(-249.31,-641.52)">
        <g className={st1}>
          <path
            className={st0}
            d="m549.5 709.03c-11.52 10.98-17.28 25.42-17.28 43.33 0 18.02 5.82 32.94 17.46 44.76s26.35 17.72 44.13 17.72h69.11v-21.31h-69.11c-11.7 0-21.34-3.64-28.91-10.92-7.58-7.28-11.37-16.77-11.37-28.47 0-11.82 3.79-21.48 11.37-29s17.22-11.28 28.91-11.28h69.11v-21.31h-69.11c-18.03 0.01-32.8 5.5-44.31 16.48z"
          />
          <polygon
            className={st0}
            points="682.34 692.56 744.64 774.02 744.64 814.84 765.77 814.84 765.77 774.38 826.46 692.56 798.89 692.56 755.74 752.71 709.73 692.56"
          />
          <path
            className={st0}
            d="m951.88 704.19c-9.07-7.76-20.47-11.64-34.2-11.64h-76.71v122.28h21.31v-100.97h58.8c7.04 0 12.95 2.03 17.73 6.09 4.77 4.06 7.16 9.55 7.16 16.47s-2.39 12.47-7.16 16.65c-4.78 4.18-10.68 6.27-17.73 6.27h-34.4v21.13h31c13.72 0 25.12-3.82 34.2-11.46 9.79-8.24 14.68-19.04 14.68-32.41 0-13.49-4.89-24.29-14.68-32.41z"
          />
          <polygon
            className={st0}
            points="1071.4 742.15 1061.6 742.15 1003.1 742.15 1000.8 742.15 981.78 742.15 981.78 814.84 1087.4 814.84 1087.4 793.53 1003.1 793.53 1003.1 763.46 1071.4 763.46"
          />
          <polygon
            className={st0}
            points="1061.6 713.86 1087.4 713.86 1087.4 692.56 981.78 692.56 981.78 713.86 1003.1 713.86"
          />
          <polygon
            className={st0}
            points="1250.7 815.02 1198.9 761.94 1184 777.76 1220.1 815.02"
          />
          <polygon
            className={st0}
            points="1184.3 730.15 1199.1 745.73 1250.7 692.56 1221 692.56"
          />
          <polygon
            className={st0}
            points="493.6 858.48 407.71 792.76 379.63 813.62 440.03 858.48"
          />
          <polygon
            className={st0}
            points="293.97 750 335.11 780.56 363.43 758.89 352.69 750.67 371.45 736.13 400.03 714 493.6 641.52 441.37 641.52 371.45 692.98 342.88 714"
          />
        </g>
        <g className={st1}>
          <polygon
            className={st1}
            points="1162.8 754.09 1103.3 815.17 1133.6 815.17 1191.3 753.71 1132.9 692.26 1103.3 692.26"
          />
          <polygon
            className={st1}
            points="249.31 641.52 334.87 707.8 363.23 686.92 301.54 641.52"
          />
          <polygon
            className={st1}
            points="382.33 756.71 379.04 759.22 371.45 765.03 343.25 786.6 249.31 858.48 302.88 858.48 371.45 807.55 399.66 786.6 410.3 778.7 419.19 772.1 448.94 750 408.19 720.01 379.41 742.3 390.22 750.67"
          />
        </g>
      </g>
    </svg>
  );
};
