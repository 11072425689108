import { stringify } from "query-string";
import request from "superagent";
import { IAppMetadata, IElementModel, IObjectView, IUi } from "core/types";
import {
  getResponseError,
  onTokenExpired,
  withAuthHeader,
  withCommonHeaders,
} from "utils/api";

import { getServerError } from "../../core/utils/api";

import { FILE_STORAGE_PREFIX } from "./constants";
import { StorageFileMetadata } from "./types/FileStorage";
import { BuiltinResponseType } from "./types/ResponseType";

export { onTokenExpired };

export const loadUIList = async (token: string): Promise<IUi[]> => {
  try {
    const res = await withCommonHeaders(request.get("/app/ui"), token);
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const loadViewList = async (
  token: string,
  { role }: { role?: string },
): Promise<IObjectView[]> => {
  try {
    const query = role ? `?${stringify({ role })}` : "";
    const res = await withCommonHeaders(
      request.get("/app/view/" + query),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getViewMetadata = async (
  { token }: { token?: string } = {},
  params: {
    objectViewName: string;
    stateNames?: string[];
  },
) => {
  try {
    const query = `?${stringify(
      { stateNames: params.stateNames },
      { arrayFormat: "bracket" },
    )}`;
    const { body } = await withCommonHeaders(
      request.get(`/app/view/${params.objectViewName}/metadata/many${query}`),
      token,
    );

    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const loadAppMetadata = async (
  token: string,
  { uiName, latest }: { uiName: string; latest?: boolean },
): Promise<IAppMetadata> => {
  try {
    const url = latest ? `/app/ui/${uiName}/latest` : `/app/ui/${uiName}`;
    const { body } = await withCommonHeaders(request.get(url), token);
    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getUIReleases = async (token: string, uiName: string) => {
  try {
    const res = await withCommonHeaders(
      request.get(`/app/ui/${uiName}/release`),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getUISavePoints = async (token: string, uiName: string) => {
  try {
    const res = await withCommonHeaders(
      request.get(`/app/ui/${uiName}/save`),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const generateUIElement = async (
  token: string,
  {
    uiName,
    elementName,
    element,
  }: { uiName: string; elementName: string; element?: IElementModel },
): Promise<IElementModel<{}, {}, never>> => {
  try {
    const res = await withCommonHeaders(
      request
        .post(`/app/ui/${uiName}/generate/element`)
        .send({ name: elementName, element }),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

interface LoginBody {
  identifier: string;
  password: string;
}

export const login = async (payload: LoginBody): Promise<string> => {
  try {
    const res = await request
      .post("/app/auth/login")
      .send(payload)
      .set("Content-Type", "application/json");
    return res.body.jwt;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const loadViewData = async (
  token: string,
  {
    viewName,
    params,
    responseFormat,
  }: {
    viewName: string;
    params?: Record<string, unknown>;
    responseFormat?: BuiltinResponseType;
  },
) => {
  try {
    const queryString = params ? `?${stringify(params)}` : "";
    const res = await withCommonHeaders(
      request.get(`/view/${viewName}${queryString}`),
      token,
      responseFormat,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const createViewData = async (
  token: string,
  viewName: string,
  data: any,
) => {
  try {
    const res = await withCommonHeaders(
      request
        .post(`/view/${viewName}`)
        .set("Prefer", "return=representation")
        .send(data),
      token,
    );
    return res.body[0];
  } catch (err) {
    throw getResponseError(err);
  }
};

export const updateViewData = async (
  token: string,
  viewName: string,
  data: any,
  identifierName: string,
  identifierValue: any,
) => {
  try {
    const res = await withCommonHeaders(
      request
        .patch(`/view/${viewName}?${identifierName}=eq.${identifierValue}`)
        .set("Prefer", "return=representation")
        .send(data),
      token,
    );
    return res.body[0];
  } catch (err) {
    throw getResponseError(err);
  }
};

export const insertMultipleViewDataRows = async (
  token: string,
  viewName: string,
  data: any[],
) => {
  try {
    const res = await withCommonHeaders(
      request.post(`/view/${viewName}`).send(data),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const updateMultipleViewDataRows = async (
  token: string,
  viewName: string,
  data: any[],
) => {
  try {
    const res = await withCommonHeaders(
      request
        .post(`/view/${viewName}`)
        .set("Prefer", "resolution=merge-duplicates")
        .send(data),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const deleteMultipleViewDataRows = async (
  token: string,
  viewName: string,
  ids: any[],
  identifierName: string,
) => {
  try {
    const res = await withCommonHeaders(
      request.delete(
        `/view/${viewName}?"${identifierName}"=in.(${ids
          .map((id) => `"${id}"`)
          .join(",")})`,
      ),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const deleteViewData = async (
  token: string,
  viewName: string,
  identifierName: string,
  identifierValue: any,
) => {
  try {
    await withCommonHeaders(
      request
        .delete(`/view/${viewName}?${identifierName}=eq.${identifierValue}`)
        .set("Prefer", "return=representation"),
      token,
    );
  } catch (err) {
    throw getResponseError(err);
  }
};

export type CurrentUserIntegrated = {
  type: "integrated";
  email: string;
  userName?: string;
};

export type CurrentUserOther = {
  type: "other";
  name: string;
};

export type AuthMeResponse = {
  id: string;
  role: string;
  language: string;
  additionalInformation: CurrentUserIntegrated | CurrentUserOther;
  isActive: boolean;
  isAdmin: boolean;
};

export const getUser = async (token: string): Promise<AuthMeResponse> => {
  try {
    const res = await withCommonHeaders(request.get("/app/auth/me"), token);

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const updateUser = async (
  token: string,
  userData: Record<string, unknown>,
) => {
  try {
    const { body } = await withCommonHeaders(
      request.put("/app/auth/me").send(userData),
      token,
    );
    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export * from "./types/stateTransition";

export const runProcedure = async (
  token: string | null,
  name: string,
  schema: string,
  args: any,
) => {
  try {
    const { body } = await withCommonHeaders(
      request.post(`/${schema}/rpc/${name}`).send(args),
      token,
    );
    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const uploadFile = async (
  token: string,
  file: File,
  groupName: string,
  typeGroupName: string,
) => {
  try {
    const req = request.post(FILE_STORAGE_PREFIX);
    req.attach("file", file as never, file.name);
    req.field("groupName", groupName);
    req.field("typeGroupName", typeGroupName);
    const { body } = await withAuthHeader(req, token);

    return body as StorageFileMetadata;
  } catch (err) {
    throw getServerError(err);
  }
};

export const uploadMultipleFiles = async (
  token: string,
  files: File[],
  groupName: string,
  typeGroupName: string,
) => {
  try {
    let body: StorageFileMetadata[] = [];
    for (const file of files) {
      const uploadedFile = await uploadFile(
        token,
        file,
        groupName,
        typeGroupName,
      );
      body = [...body, uploadedFile];
    }

    return body;
  } catch (err) {
    throw getServerError(err);
  }
};

export const fileMetadata = async (
  token: string,
  { fileName }: { fileName: string },
): Promise<StorageFileMetadata | undefined> => {
  try {
    const req = request.get(`${FILE_STORAGE_PREFIX}${fileName}/details`);
    const { body } = await withAuthHeader(req, token);
    return body as StorageFileMetadata;
  } catch (err) {
    throw getServerError(err);
  }
};

export const multipleFileMetadata = async (
  token: string,
  fileNames: string[],
) => {
  try {
    let body: StorageFileMetadata[] = [];

    for (const fileName of fileNames) {
      const fileMdata = await fileMetadata(token, { fileName });
      if (fileMdata) {
        body = [...body, fileMdata];
      }
    }

    return body;
  } catch (err) {
    throw getServerError(err);
  }
};

export interface LoginConfig {
  logoPath: string | null;
  identifierInputTitle: string | null;
}

export const getLoginConfig = async (token: string): Promise<LoginConfig> => {
  try {
    const res = await withCommonHeaders(
      request.get("/app/auth/config/login"),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getViewCreateStateChanges = async (
  token: string,
  objectViewName: string,
) => {
  try {
    const { body } = await withCommonHeaders(
      request.get(`/app/view/${objectViewName}/state-changes/create`),
      token,
    );

    return body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const getUserNotifications = async (token: string) => {
  try {
    const res = await withCommonHeaders(
      request.get("/app/users/notifications"),
      token,
    );

    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};

export const readUserNotifications = async (
  token: string,
  params: {
    notificationIds: number[];
  },
) => {
  try {
    const notifications = { ids: params.notificationIds };
    const res = await withCommonHeaders(
      request.post("/app/users/update/notifications").send(notifications),
      token,
    );
    return res.body;
  } catch (err) {
    throw getResponseError(err);
  }
};
