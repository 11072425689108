import { memo } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";
import Button from "elementTypes/common/Button";

import { RoutePaths } from "staticPages/routes";
import { Autocomplete } from "../../../../../../../elementTypes/common/Autocomplete";
import { useEditUser } from "../../../../../../../queries/admin";
import { getApiError } from "../../../../../../../queries/utils";
import { useSnackbar } from "../../../../../../../utils/hooks/useSnackbar";
import { User } from "../../../types";
import useStyles from "../styles";
import { EditUserForm } from "../types";

export const Form = memo<{ user: User; roleOptions: any[] }>(
  ({ roleOptions, user }) => {
    const showSnackbar = useSnackbar();
    const dispatch = useDispatch();
    const {
      classes: { formPaper, footer, dividerFooter },
    } = useStyles();
    const {
      formState: { isSubmitting, isValid },
      control,
      handleSubmit,
    } = useForm<EditUserForm>({
      mode: "onChange",
      defaultValues: {
        isActive: user!.isActive,
        password: "",
        role: user!.role,
      },
    });

    const editUser = useEditUser({
      onSuccess: () => {
        showSnackbar("User edited successfully", "success");
        dispatch(routerActions.push(RoutePaths.Users));
      },
      onError: (er) =>
        showSnackbar(`User was not edited: ${getApiError(er)}`, "error"),
    });

    const submit = (data: EditUserForm) => {
      const userData: EditUserForm = {};

      if (data.isActive !== user!.isActive) {
        userData.isActive = data.isActive;
      }

      if (data.role !== user!.role) {
        userData.role = data.role;
      }

      if (data.password) {
        userData.password = data.password;
      }

      editUser.mutate({ userId: user!.id, user: userData });
    };

    const defaultValue = roleOptions.find(
      (option) => option.value === user!.role,
    );

    return (
      <form autoComplete="off" onSubmit={handleSubmit(submit)}>
        <Paper variant="outlined" className={formPaper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="role"
                control={control}
                defaultValue={defaultValue}
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    options={roleOptions}
                    label="Role"
                    {...field}
                    innerRef={ref}
                    variant="standard"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    fullWidth={true}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={field.value ?? false} />}
                    label={"Active"}
                    {...field}
                  />
                )}
                name="isActive"
                control={control}
              />
            </Grid>
            <Grid item xs={12} className={footer}>
              <Divider className={dividerFooter} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  color="primary"
                  label="Cancel"
                  href={RoutePaths.Users}
                />
                <Button
                  color="secondary"
                  disabled={isSubmitting || !isValid}
                  processing={isSubmitting}
                  iconRight="forward"
                  type="submit"
                  label="Edit"
                  flex={1}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </form>
    );
  },
);
