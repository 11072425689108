import { createUseTranslation } from "core/session/translation";

export const useLoginFormTranslation = createUseTranslation({
  en: {
    buttonLogin: "Login",
  },
});

export const useRootTranslation = createUseTranslation({
  en: {
    method: "Method",
  },
});
