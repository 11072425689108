import {
  MouseEvent as ReactMouseEvent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Theme, useTheme } from "@mui/material";
import classNames from "classnames";
import deepEqual from "fast-deep-equal";
import ReactGridLayout, {
  Layout,
  ReactGridLayoutProps,
} from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useResizeDetector } from "react-resize-detector";
import {
  AnyElementWithPosition,
  Element,
  IElement,
  IElementModel,
  IElementType,
  TElementModelWithPosition,
} from "core";
import { useElementTypesContext } from "core/ElementTypesContext";
import { getNearestParentElement, getParentElement } from "core/editor";
import { copyElement } from "core/editor/EditorLayout/utils";
import { invalidElementType } from "core/editor/invalidElementType";
import {
  actions as editorActions,
  selectors as editorSelectors,
} from "core/editor/reduxModule";
import { IRawElementType, TElementWithPosition } from "core/types";

import { usePage } from "utils/hooks";
import { pxToNumber } from "utils/string";
import { ChildElement } from "../component";
import { GRID_SPACING_FACTOR } from "../components";
import { SPACING_MULTIPLICATOR } from "../components/utils";
import { GridChildren } from "../types";
import { sortElements } from "../utils";

import { useStyles } from "./styles";

type Props = {
  elementModel: IElementModel | TElementModelWithPosition;
  element: IElement;
};

const getPositionFromLayout = (
  layout: {
    x: number;
    y: number;
    w: number;
    h: number;
  } = {
    x: 0,
    y: 0,
    w: 1,
    h: 1,
  },
) => ({
  column: layout!.x + 1,
  row: layout!.y + 1,
  width: layout!.w,
  height: layout!.h,
});

const getGridData = (
  { id, position: { column, row, width, height } }: TElementWithPosition,
  { editorMetadata: meta }: Pick<IRawElementType, "editorMetadata"> = {},
) => ({
  i: id,
  x: column - 1,
  y: row - 1,
  w: width,
  h: height,
  minW: meta?.minSize?.width ?? 1,
  maxW: meta?.maxSize?.width ?? 12,
  minH: meta?.minSize?.height ?? 1,
  maxH: meta?.maxSize?.height ?? undefined,
});

const specialBackgroundElements = [
  "default_form",
  "default_container",
  "default_tabs",
  "default_modal_dialog",
];

export const EditorGrid = memo<Props>(({ element, elementModel }) => {
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: "debounce",
    refreshRate: 1000,
  });

  const page = usePage();
  const isPageElement = elementModel.id === page?.element.id;

  const theme = useTheme<Theme>();
  const draggableElementParams = useSelector(
    editorSelectors.draggableElementParams,
  );
  const copiedElements = useSelector(editorSelectors.copiedElements);
  const updatedElements = useSelector(editorSelectors.updatedElements);
  const editorActiveGrid = useSelector(editorSelectors.activeGrid);
  const selected = useSelector(editorSelectors.selected);
  const activeGrid = editorActiveGrid
    ? updatedElements[editorActiveGrid!.id] ?? editorActiveGrid
    : null;
  const { children, props: elementProps } = element;

  const parentName = getParentElement(
    page!.element,
    updatedElements,
    elementModel.id,
  )?.type.name;
  const isSpecialBackgroundGrid =
    parentName !== undefined && specialBackgroundElements.includes(parentName);
  const isFormGrid = parentName !== undefined && parentName === "default_form";

  const { elements } = (children as GridChildren).content;

  const dispatch = useDispatch();

  const nextActiveGrid = activeGrid
    ? {
        ...activeGrid,
        children: {
          ...activeGrid.children,
          content: {
            elements: (
              activeGrid.children as GridChildren
            ).content.elements.map(
              (child: ChildElement) => updatedElements[child.id] ?? child,
            ),
          },
        },
      }
    : null;

  const setActiveGrid = useCallback(
    (activeElement: any) => {
      dispatch(editorActions.setActiveGrid(activeElement, page!));
    },
    [page, dispatch],
  );

  useEffect(() => {
    if (
      editorActiveGrid &&
      nextActiveGrid &&
      !deepEqual(editorActiveGrid, nextActiveGrid)
    ) {
      setActiveGrid(nextActiveGrid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, page, activeGrid, nextActiveGrid, setActiveGrid]);

  const {
    classes: { root },
  } = useStyles();
  const { getElementType, elementTypes } = useElementTypesContext();

  const isActive = Boolean(
    activeGrid?.id === elementModel.id ||
      elementModel.id.endsWith(`.${activeGrid?.id}`),
  );

  const gridLayout = useMemo(() => {
    let grid: ReactNode[] = [];
    let layout: Layout[] = [];
    let positions: number[] = [];

    if (elements.length) {
      const sortedElements = sortElements(elements);
      for (const child of sortedElements) {
        let elementType: IElementType;
        try {
          elementType = getElementType(child);
        } catch {
          elementType = invalidElementType;
        }
        grid = [
          ...grid,
          <div key={child.id}>
            <Element element={child} elementProps={elementProps} />
          </div>,
        ];
        layout = [
          ...layout,
          { ...getGridData(child as TElementWithPosition, elementType) },
        ];

        positions = [...positions, child.position.height + child.position.row];
      }
    }

    if (isPageElement) {
      // since in the current grid implementation, it's hard to add the element
      // to the page bottom if some other element is there
      // "hidden" element will be always added to have 3 extra rows at the bottom of the page in edit mode
      // will be fixed and can be removed via CN-98
      grid = [...grid, <div key="hidden" />];
      layout = [
        ...layout,
        {
          i: "hidden",
          x: 0,
          y: Math.max(...positions) + 3,
          w: 0,
          h: 0,
          minW: 0,
          maxW: 0,
          minH: 0,
          maxH: 0,
          static: true,
        },
      ];
    }

    return {
      grid,
      layout,
    };
  }, [isPageElement, elements, elementProps, getElementType]);

  const selectElement = (elementId: string) => {
    if (elementId !== selected?.element.id) {
      const selectedElement = elements.find(
        (el: ChildElement) => el.id === elementId,
      );
      if (selectedElement) {
        let elementType: IElementType;
        try {
          elementType = getElementType(selectedElement);
        } catch {
          elementType = invalidElementType;
        }

        const parent = getNearestParentElement(
          page!.element,
          updatedElements,
          elementId,
          "default_grid",
        );
        if (parent) {
          dispatch(editorActions.setActiveGrid(parent, page!));
        }
        dispatch(
          editorActions.selectElement(selectedElement, elementType, page!),
        );
      }
    }
  };

  const onDragStart: ReactGridLayoutProps["onDragStart"] = (
    _layout,
    oldItem,
  ) => {
    dispatch(editorActions.setIsElementDragging(true));
    selectElement(oldItem.i);
  };

  const onResizeStart: ReactGridLayoutProps["onResizeStart"] = (
    _layout,
    oldItem,
  ) => {
    dispatch(editorActions.setIsElementResizing(true));

    selectElement(oldItem.i);
  };

  const onDrop: ReactGridLayoutProps["onDrop"] = (layout, item, e) => {
    let draggableName =
      (e as any).dataTransfer.getData("text") ?? draggableElementParams?.i;
    dispatch(editorActions.setIsElementDragging(false));
    if (draggableName && page) {
      const position = getPositionFromLayout(item);

      if (draggableElementParams?.isCopy) {
        draggableName = draggableElementParams?.id ?? "";
        const el = {
          ...copiedElements[draggableName],
          position,
        } as AnyElementWithPosition;
        const copiedElement = copyElement(el);
        dispatch(
          editorActions.addElement(
            copiedElement,
            elementTypes,
            page!,
            activeGrid!,
          ),
        );
        handleLayoutChange(layout);
      } else {
        dispatch(
          editorActions.createElement(
            elementTypes,
            elementTypes[draggableName],
            page,
            position,
            elementModel,
          ),
        );
      }
      dispatch(editorActions.setDraggableElement(null));

      e.preventDefault();
    }
  };

  const handleLayoutChange = (layout: Layout[]) => {
    const updatedGrid = elements.reduce((all: any, el: ChildElement) => {
      const updated = layout.find((l: Layout) => el.id === l.i);
      const position = getPositionFromLayout(updated);

      return {
        ...all,
        ...(updated &&
          "position" in el &&
          !deepEqual(position, el.position) && {
            [el.id]: {
              ...el,
              position,
            },
          }),
      };
    }, {});

    const selectedElement =
      selected && selected.type && selected.element
        ? {
            ...selected,
            element: updatedGrid[selected.element.id] ?? selected.element,
          }
        : null;

    if (Object.keys(updatedGrid).length) {
      dispatch(
        editorActions.updateElements(updatedGrid, selectedElement, page!),
      );
    }
  };

  const onDragStop: ReactGridLayoutProps["onDragStop"] = (
    layout: Layout[],
    oldItem: Layout,
    newItem: Layout,
    _: Layout,
    event: MouseEvent,
  ) => {
    if (deepEqual(oldItem, newItem)) {
      event.preventDefault();
    } else {
      handleLayoutChange(layout);
    }
    dispatch(editorActions.setIsElementDragging(false));
  };

  const onResizeStop: ReactGridLayoutProps["onResizeStop"] = (
    layout: Layout[],
    oldItem: Layout,
    newItem: Layout,
    _: Layout,
    event: MouseEvent,
  ) => {
    if (deepEqual(oldItem, newItem)) {
      event.preventDefault();
    } else {
      handleLayoutChange(layout);
    }
    dispatch(editorActions.setIsElementResizing(false));
  };

  const droppingItem = draggableElementParams ?? {
    i: "new-element",
    w: 1,
    h: 1,
  };

  const rowHeight = pxToNumber(
    theme.spacing(SPACING_MULTIPLICATOR(isFormGrid)),
  );

  const { layout, grid } = gridLayout;

  const handleGridClick = (e: ReactMouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if ((e.target as HTMLDivElement).classList.contains("react-grid-layout")) {
      dispatch(editorActions.unselectElement(page!));
      dispatch(editorActions.setActiveGrid(elementModel, page!));
    }
  };
  if (!page || (!width && isPageElement)) {
    return <div style={{ width: "100%", height: "100%" }} ref={ref} />;
  }

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      onClick={handleGridClick}
      ref={ref}
    >
      <ReactGridLayout
        width={width!}
        className={classNames(
          root,
          { ActiveGrid: isActive },
          { SpecialBackground: isSpecialBackgroundGrid },
          { IncreasedCellHeight: isFormGrid },
        )}
        layout={layout}
        cols={12}
        draggableCancel=".no-drag"
        rowHeight={rowHeight}
        style={{
          // this breaks the automatic scrolling if you drag an element to the
          // bottom of a grid to extend it
          minHeight: "100%",
        }}
        isDroppable={isActive}
        draggableHandle=".rgl-handle"
        onDrop={onDrop}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        useCSSTransforms={false}
        margin={[
          pxToNumber(theme.spacing(GRID_SPACING_FACTOR)),
          pxToNumber(theme.spacing(GRID_SPACING_FACTOR)),
        ]}
        droppingItem={droppingItem}
        autoSize={true}
        preventCollision={true}
        compactType={null}
        {...{
          children: grid,
        }}
      />
    </div>
  );
});
