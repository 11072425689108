import { useCallback, useEffect } from "react";
import { useElementTypesContext } from "core/ElementTypesContext";
import { useElementEditorContext } from "core/editor";
import {
  IElementModel,
  IElementModelArrayChild,
  TElementModelWithPosition,
} from "core/types";

import { useElement, usePage } from ".";

type ElementChildren = { content: IElementModelArrayChild };

export const useCreateInitialGridElement = <T extends Record<string, unknown>>(
  gridName: string,
) => {
  const page = usePage();
  const { elementModel } = useElementEditorContext<T>();
  const {
    content: { elements },
  } = elementModel.children as ElementChildren;
  const { elementTypes } = useElementTypesContext();
  const { createElement } = useElement();

  const createGridElement = useCallback(() => {
    if (elementModel) {
      createElement(
        elementTypes,
        elementTypes.default_grid,
        page!,
        {
          row: 1,
          column: 1,
          width: 12,
          height: (elementModel as TElementModelWithPosition).position.height,
        },
        elementModel! as IElementModel,
        undefined,
        gridName,
      );
    }
  }, [elementModel, elementTypes, gridName, page, createElement]);

  useEffect(() => {
    if (!elements.length) {
      createGridElement();
    }
  }, [elements, createGridElement]);
};
