import { FC, memo, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  RefCallBack,
  useFormContext,
} from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { AlertBox } from "elementTypes/common/AlertBox";
import { RenderProps } from "elementTypes/common/HookForm";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { useColumnValues } from "queries/admin";

import { WorkflowSetupColumns } from "./WorkflowSetupColumns";
import { useERDTranslation } from "./translation";
import { Column, NodeData } from "./types";
import { sortAndFilterColumns } from "./utils";

const ITEM_SIZE = 44;

type WorkFlowProps = {
  nodeData: NodeData;
};

const WorkflowSetup: FC<WorkFlowProps> = ({ nodeData }) => {
  const { columns, schemaName, tableName, stateColumn } = nodeData;
  const { control, watch, setValue } = useFormContext();
  const t = useERDTranslation();

  const selectedColumn = watch("stateColumn") ?? "";
  const cleanUpPrevious = watch("cleanUpPrevious") ?? false;

  const options = useMemo(() => sortAndFilterColumns(columns) ?? [], [columns]);

  const { data, isFetching } = useColumnValues(
    {
      schema: schemaName,
      table: tableName,
      column: selectedColumn,
    },
    { enabled: selectedColumn !== "" },
  );

  const showWarning = (data?.length ?? 0) >= 15;

  const renderAlertBox = ({
    field: { onChange, ref, ...rest },
  }: RenderProps) => {
    const handleChange = (_: unknown, newValue: boolean) => {
      if (!newValue) {
        setValue("transitionToAll", false);
      }
      newValue !== undefined && onChange(newValue);
    };
    return (
      <AlertBox
        boxProps={{ py: 1 }}
        message={
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            {t.removePreviousWorkDataWarning}
            <ToggleButtonGroup
              {...{
                ...rest,
                value: rest.value ?? false,
              }}
              onChange={handleChange}
              ref={ref}
              size="small"
              exclusive
            >
              <ToggleButton color="error" value={false}>
                {t.updateWorkflowBtn}
              </ToggleButton>
              <ToggleButton color="error" value={true}>
                {t.removeWorkflowBtn}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        }
      />
    );
  };

  return (
    <>
      {stateColumn && (
        <FormControl>
          <Controller
            render={renderAlertBox}
            name={"cleanUpPrevious" as never}
            control={control}
            defaultValue={false as any}
          />
        </FormControl>
      )}
      <FormControl fullWidth>
        <Controller
          render={({ field: { ref, ...field } }) => (
            <ColumnsList {...field} items={options} innerRef={ref} />
          )}
          name="stateColumn"
          control={control}
          defaultValue=""
        />
      </FormControl>
      <Box pt={1} px={1}>
        <WorkflowSetupColumns data={data} loading={isFetching} />
      </Box>
      <Box pt={1} px={1}>
        <FormControl>
          <Controller
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox color="primary" checked={field.value ?? false} />
                  }
                  label={t.transitionsToAllLabel}
                  {...field}
                  disabled={!cleanUpPrevious && !!stateColumn}
                />
                {field.value && showWarning ? (
                  <AlertBox message={t.transitionToAllWarning} />
                ) : null}
              </>
            )}
            name="transitionToAll"
            control={control}
            defaultValue={false}
          />
        </FormControl>
      </Box>
    </>
  );
};

export const WorkflowAlertComponent = ({
  isEmpty,
}: Record<"isEmpty", boolean>) => {
  const t = useERDTranslation();
  const [open, setOpen] = useState(false);
  const warningMessage = <ReactMarkdown>{t.helperAlertText}</ReactMarkdown>;

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  return (
    <AlertBox
      alertTitle={isEmpty ? t.emptyHelperAlertTitle : t.helperAlertTitle}
      message={open && !isEmpty ? warningMessage : undefined}
      color="info"
      {...(!isEmpty && {
        onClose: handleToggle,
        slots: {
          closeIcon: () => (
            <MuiIcon icon={open ? "expand_less" : "expand_more"} />
          ),
        },
      })}
    />
  );
};

function renderRow({
  index,
  style,
  item,
  value,
  onChange,
}: Omit<ListChildComponentProps, "data"> & {
  item: Column;
  value: string;
  onChange: (value: string) => void;
}) {
  const isSelected = !!(value === item.name);
  const handleClick = () => onChange(isSelected ? "" : item.name);

  return (
    <ListItem
      style={style}
      key={index}
      component="div"
      disablePadding
      onClick={handleClick}
      secondaryAction={<Typography variant="overline">{item.type}</Typography>}
      sx={{
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <ListItemButton selected={isSelected}>
        <ListItemText
          sx={{
            display: "flex",
            justifyItems: "space-between",
          }}
          primary={item.name}
        />
      </ListItemButton>
    </ListItem>
  );
}

const ColumnsList = memo<
  { items: Column[]; innerRef?: RefCallBack } & Omit<
    ControllerRenderProps<FieldValues, "stateColumn">,
    "ref"
  >
>(({ items, onChange, innerRef, ...rest }) => {
  const handleChange = (value: string) => onChange(value);
  const listHeight = ITEM_SIZE * Math.min(items.length ?? 0, 5);
  const getRow = ({ data, ...row }: ListChildComponentProps) =>
    renderRow({
      ...row,
      item: data[row.index],
      onChange: handleChange,
      ...rest,
    });

  return (
    <FixedSizeList
      width={"100%"}
      overscanCount={5}
      height={listHeight}
      itemCount={items.length}
      itemSize={ITEM_SIZE}
      itemData={items}
      innerRef={innerRef}
    >
      {getRow}
    </FixedSizeList>
  );
});

export const MemoizedWorkflowSetup = memo(WorkflowSetup);
