import { memo } from "react";
import { Typography } from "@mui/material";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { Translation } from "core";
import { useEditorTranslation } from "core/editor";
import { getTranslatedText } from "core/utils/element-utils.ts";
import { AlertBox } from "elementTypes/common/AlertBox";
import { HookForm } from "elementTypes/common/HookForm";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { LoadingComponent } from "layouts/common/Loading.tsx";
import { DataPreview } from "staticPages/admin/pages/modelBuilder/erd/DataPreview.tsx";
import { BasicQuery } from "staticPages/admin/pages/modelBuilder/erd/queryBuilder/BasicQuery.tsx";
import { useDatabaseTranslation } from "staticPages/admin/pages/modelBuilder/translation.ts";
import { RoutePaths } from "staticPages/routes";
import { useLocation, useRoute } from "utils/hooks";
import { MutateGroupForm } from "../../QueryGroupEdit.tsx";
import { ModalContentType } from "../../types.ts";
import { TDialogProps } from "../types.ts";

export const Dialog = memo<TDialogProps>(
  ({
    findQueryGroupByKey,
    handleClose,
    handleCreateQueryGroup,
    handleDeleteQueryGroup,
    handleEditQuery,
    handleEditQueryGroupName,
    handleQueryDelete,
    handleQueriesDelete,
    language,
    modal,
    queryGroups,
  }) => {
    const location = useLocation();
    const route = useRoute();
    const translation = useDatabaseTranslation();
    const editorTranslation = useEditorTranslation();

    const dialogCommonProps = {
      open: Boolean(modal),
      fullWidth: true,
      maxWidth: "lg" as const,
      submitTitle: undefined,
      handleClose,
    };

    const handleDelete = () => {
      handleQueryDelete(modal!.selected!.name!);
      if (location.params?.queryName === modal!.selected!.name!) {
        route("push", RoutePaths.Database);
      }
    };

    const sharedDeleteConfig = {
      ...dialogCommonProps,
      title: `${translation.delete} ${modal?.queries ? translation.deleteAllTitle : modal?.selected?.name}`,
      cancelTitle: translation.closeDiffDefinition,
      ...(modal?.selected?.name && {
        handleSubmit: handleDelete,
      }),
      ...(modal?.type === ModalContentType.queriesDelete && {
        handleSubmit: handleQueriesDelete,
      }),
      submitTitle: translation.delete,
      maxWidth: "sm" as const,
      submitButtonProps: {
        color: "error",
      },
      children: (
        <>
          <AlertBox message={translation.removeQueryWarning} color="info" />
          <Typography mt={1} display="flex" justifyContent="center">
            {translation.approveDeleteTitle}
          </Typography>
        </>
      ),
    };

    const dialogProps = {
      [ModalContentType.queryPreview]: {
        ...dialogCommonProps,
        title: `${translation.previewTitle} ${modal?.selected?.name}`,
        cancelTitle: translation.closePreviewTitle,
        children: (
          <DataPreview
            schemaName="cypex_generated"
            tableName={String(modal?.selected?.name)}
          />
        ),
      },
      [ModalContentType.definitionDiff]: {
        ...dialogCommonProps,
        title: `${translation.diffDefinitionTitle} ${modal?.selected?.name}`,
        cancelTitle: translation.closeDiffDefinition,
        children: (
          <>
            <ReactDiffViewer
              leftTitle={translation.diffLeftTitle}
              rightTitle={translation.diffRightTitle}
              oldValue={modal?.selected?.saved_definition}
              newValue={modal?.selected?.current_definition}
              splitView={true}
              compareMethod={DiffMethod.WORDS}
              styles={{
                diffRemoved: {
                  overflowX: "auto",
                  whiteSpace: "pre",
                },
                diffAdded: {
                  overflowX: "auto",
                  whiteSpace: "pre",
                },
              }}
            />
            <AlertBox
              message={
                <Typography variant="caption">
                  {translation.diffNotes}
                </Typography>
              }
              color="info"
              boxProps={{ mt: 2 }}
            />
          </>
        ),
      },
      [ModalContentType.queryDelete]: sharedDeleteConfig,
      [ModalContentType.queriesDelete]: sharedDeleteConfig,
      [ModalContentType.createQueryGroup]: {
        ...dialogCommonProps,
        title: translation.createQueryGroupTitle,
        maxWidth: "sm" as const,
        children: (
          <HookForm
            onSubmit={handleCreateQueryGroup}
            boxProps={{ bgcolor: "background.paper", height: "100%" }}
          >
            <MutateGroupForm onClose={handleClose} />
          </HookForm>
        ),
      },
      [ModalContentType.editQueryGroup]: {
        ...dialogCommonProps,
        title: translation.editQueryGroupTitle,
        maxWidth: "sm" as const,
        submitButtonProps: {
          id: modal?.selectedQueryGroup?.id,
        },
        children: (
          <HookForm
            onSubmit={handleEditQueryGroupName}
            boxProps={{ bgcolor: "background.paper", height: "100%" }}
          >
            <MutateGroupForm
              i18n={modal?.selectedQueryGroup?.i18n}
              onClose={handleClose}
            />
          </HookForm>
        ),
      },
      [ModalContentType.deleteQueryGroup]: {
        ...dialogCommonProps,
        title: translation.deleteQueryGroupTitle,
        cancelTitle: editorTranslation.cancelButton,
        submitTitle: editorTranslation.deleteButton,
        maxWidth: "sm" as const,
        submitButtonProps: {
          color: "error",
          id: modal?.selectedQueryGroup?.id,
        },
        ...(modal?.selectedQueryGroup?.id && {
          handleSubmit: handleDeleteQueryGroup,
        }),
        children: (
          <Typography variant="body1">
            {translation.approveDeleteQueryGroup}
          </Typography>
        ),
      },
      [ModalContentType.queryEdit]: {
        ...dialogCommonProps,
        title: translation.editdefaultQueryTitle,
        cancelTitle: translation.closeDiffDefinition,
        isForm: true,
        handleSubmit: handleEditQuery,
        submitTitle: translation.save,
        maxWidth: "sm" as const,
        children: modal?.selected ? (
          <BasicQuery
            data={{
              tableName: modal.selected.name,
              title: getTranslatedText(
                language,
                modal.selected.i18n as Translation<"title">,
                "title",
              ),
              queryGroupId:
                findQueryGroupByKey("queries", Number(modal.selected.id))?.id ??
                null,
            }}
            queryGroups={queryGroups ?? []}
            editAction={true}
            definition_code={modal.selected?.current_definition}
          />
        ) : (
          <LoadingComponent />
        ),
      },
    };

    return modal ? <DialogWrapper {...dialogProps[modal.type]} /> : null;
  },
);
