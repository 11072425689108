import { FC, memo, useEffect, useMemo } from "react";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SecurityIcon from "@mui/icons-material/Security";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Box, Card, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Handle, NodeProps, Position } from "reactflow";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";
import { QueryKeys } from "queries/admin";

import { useAdminContext } from "staticPages/admin/context";
import { useERDContext } from "../context/database/ERDContext.utils.ts";
import { DialogType } from "../context/database/consts.ts";

import {
  MAX_COLUMNS_QUANTITY,
  TABLE_BODY_ROW_HEIGHT,
  TABLE_HEADER_HEIGHT,
} from "./consts.ts";
import { useStyles } from "./styles";
import { useERDTranslation } from "./translation";
import { Column, NodeData } from "./types";
import { sortColumns } from "./utils";

const TableNode: FC<NodeProps<NodeData>> = ({ data, id }) => {
  const {
    lookupLabelColumn,
    columns,
    tableName,
    schemaName,
    fdw,
    historyTrackingConfigured,
    workflowActivated,
  } = data;

  const translation = useERDTranslation();
  const { selectedNode } = useAdminContext();
  const {
    classes: { node, nodeItem, handleClass, nodeHeaderBoxBackground },
  } = useStyles({ selected: Boolean(selectedNode?.id === id) });
  // if there are more than `MAX_COLUMNS_QUANTITY - 1` use the last column space
  // to display information that more columns exist
  const hasMoreColumns = columns.length >= MAX_COLUMNS_QUANTITY;
  const sortedColumns = useMemo(
    () =>
      sortColumns(columns).slice(
        0,
        hasMoreColumns ? MAX_COLUMNS_QUANTITY - 1 : MAX_COLUMNS_QUANTITY,
      ),
    [columns, hasMoreColumns],
  );

  const { menuData, onMenuOpen, isInternal, onModalOpen } = useERDContext();

  const stateColumn = columns.find(
    (column) => column.name === data?.stateColumn,
  );

  const isBrokenState = !!data.stateColumn && !stateColumn;

  const items = sortedColumns.map((column: Column, index: number) => (
    <Box
      display="grid"
      className={nodeItem}
      key={`${column.name}-${index}`}
      alignItems="center"
      gap={1}
      gridTemplateColumns="max-content 1fr max-content"
      position="relative"
      height={TABLE_BODY_ROW_HEIGHT}
    >
      <Handle
        type="source"
        isConnectable={false}
        id={`${id}.${column.name}`}
        position={Position.Right}
        className={handleClass}
      />
      <Handle
        type="target"
        isConnectable={false}
        id={`${id}.${column.name}`}
        position={Position.Left}
        className={handleClass}
      />
      <Box sx={{ lineHeight: 1, width: "fit-content" }}>
        {column.primaryKey && (
          <Tooltip title={translation.primaryKeyTooltip}>
            <VpnKeyIcon fontSize="small" sx={{ marginRight: 0 }} />
          </Tooltip>
        )}
        {column.foreignKey && (
          <Tooltip
            title={`${translation.foreignKeyTooltip}: ${column.foreignKey}`}
          >
            <CallReceivedIcon fontSize="small" />
          </Tooltip>
        )}
        {column.workflowConfigured && (
          <Tooltip title={translation.workflowTooltip}>
            <GroupWorkIcon
              fontSize="small"
              color={workflowActivated ? "primary" : "disabled"}
            />
          </Tooltip>
        )}
        {column.name === lookupLabelColumn && (
          <Tooltip title={translation.defaultLookupColumnTooltip}>
            <RemoveRedEyeIcon fontSize="small" />
          </Tooltip>
        )}
      </Box>
      <Box display="flex" flex={1} gap="8" alignItems="center">
        <Typography>{column.name}</Typography>
        {!column.primaryKey && column.unique && (
          <Tooltip title={translation.uniqueTooltip}>
            <FingerprintIcon fontSize="small" />
          </Tooltip>
        )}
      </Box>
      <Box display="inline-flex">
        <StyledTypography
          typographyProps={{
            variant: "overline",
          }}
          text={column.type}
        />
        {!column.nullable || column.primaryKey ? (
          <StyledTypography
            typographyProps={{
              variant: "overline",
            }}
            text="*"
            tooltip={translation.notNullTooltip}
          />
        ) : (
          <StyledTypography
            typographyProps={{
              variant: "overline",
            }}
            text={NON_BREAKING_SPACE}
          />
        )}
      </Box>
    </Box>
  ));

  const queryClient = useQueryClient();
  const isFetching =
    queryClient.isFetching([QueryKeys.fetchModel]) ||
    queryClient.isMutating({ mutationKey: [QueryKeys.deleteQuery] });

  useEffect(() => {
    if (
      menuData &&
      !isFetching &&
      data.schemaName === menuData?.schemaName &&
      data.tableName === (menuData as NodeData).tableName
    ) {
      onMenuOpen(data)(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  return (
    <Card variant="outlined" classes={{ root: node }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={TABLE_HEADER_HEIGHT}
        className={nodeHeaderBoxBackground}
      >
        {/* 48px - IconButton width */}
        <Box
          px={1}
          maxWidth="calc(100% - 48px)"
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <Tooltip
            title={`${schemaName}.${tableName}${
              fdw ? " (Foreign-data wrapper)" : ""
            }`}
          >
            <Typography variant="h5" noWrap>
              {tableName}
            </Typography>
          </Tooltip>
          {fdw && <MuiIcon color="success" icon="link" fontSize="small" />}
          {historyTrackingConfigured && (
            <Tooltip title={translation.historyTrackingConfiguredTitle}>
              <SecurityIcon fontSize="small" />
            </Tooltip>
          )}
          {isBrokenState && (
            <Tooltip
              title={translation.workflowBrokenTooltip.replace(
                "@columnName",
                data.stateColumn,
              )}
            >
              <GroupWorkIcon fontSize="small" color={"error"} />
            </Tooltip>
          )}
        </Box>
        {isInternal ? (
          <IconButton
            icon="visibility"
            onClick={onModalOpen(DialogType.tableDetails, data)}
            color="inherit"
            tooltip={translation.menuTableDetails}
          />
        ) : (
          <IconButton
            icon="more_vert"
            onClick={onMenuOpen(data)}
            color="inherit"
          />
        )}
      </Box>
      {items}
      {hasMoreColumns && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={nodeItem}
          height={TABLE_BODY_ROW_HEIGHT}
        >
          <Typography>
            +{columns.length - MAX_COLUMNS_QUANTITY + 1} more columns
          </Typography>
        </Box>
      )}
    </Card>
  );
};

const MemoizedTableNode = memo(TableNode);

export default MemoizedTableNode;
