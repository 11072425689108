import { FC, memo, useMemo } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { Handle, NodeProps, Position } from "reactflow";
import IconButton from "elementTypes/common/IconButton";
import { StyledTypography } from "elementTypes/common/StyledTypography";

import { useAdminContext } from "staticPages/admin/context";

import { useERDContext } from "../context/database/ERDContext.utils.ts";
import { DialogType } from "../context/database/consts.ts";
import { useStyles } from "./styles";
import { useERDTranslation } from "./translation";
import { Column } from "./types";
import { sortColumns } from "./utils";

export const TABLE_WIDTH = 400;
export const MAX_COLUMNS_QUANTITY = 10;
export const TABLE_HEADER_HEIGHT = 48;
export const TABLE_BODY_ROW_HEIGHT = 40;

export type ViewNodeData = {
  viewName: string;
  schemaName: string;
  columns: Column[];
  alias: string;
};

const ViewNode: FC<NodeProps<ViewNodeData>> = ({ data, id }) => {
  const { alias, columns, viewName, schemaName } = data;
  const { selectedNode } = useAdminContext();
  const {
    classes: { node, nodeItem, handleClass, nodeHeaderBoxBackground },
  } = useStyles({
    selected: Boolean(selectedNode?.id === id),
  });
  const { menuTableDetails } = useERDTranslation();

  // if there are more than `MAX_COLUMNS_QUANTITY - 1` use the last column space
  // to display information that more columns exist
  const hasMoreColumns = columns.length >= MAX_COLUMNS_QUANTITY;
  const sortedColumns = useMemo(
    () =>
      sortColumns(columns).slice(
        0,
        hasMoreColumns ? MAX_COLUMNS_QUANTITY - 1 : MAX_COLUMNS_QUANTITY,
      ),
    [columns, hasMoreColumns],
  );

  const { onModalOpen } = useERDContext();

  const items = sortedColumns.map((column: Column, index: number) => (
    <Box
      display="grid"
      className={nodeItem}
      key={`${column.name}-${index}`}
      alignItems="center"
      gap={1}
      gridTemplateColumns="max-content 1fr max-content"
      position="relative"
      height={TABLE_BODY_ROW_HEIGHT}
    >
      <Handle
        type="source"
        isConnectable={false}
        id={`${id}.${column.name}`}
        position={Position.Right}
        className={handleClass}
      />
      <Handle
        type="target"
        isConnectable={false}
        id={`${id}.${column.name}`}
        position={Position.Left}
        className={handleClass}
      />
      <Box display="flex" flex={1} gap="8" alignItems="center">
        <Typography>{column.name}</Typography>
      </Box>
      <Box display="inline-flex">
        <StyledTypography
          typographyProps={{
            variant: "overline",
          }}
          text={column.type}
        />
      </Box>
    </Box>
  ));

  return (
    <Card variant="outlined" classes={{ root: node }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        height={TABLE_HEADER_HEIGHT}
        px={1}
        className={nodeHeaderBoxBackground}
      >
        {/* 48px - IconButton width */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Tooltip title={`View: ${schemaName}.${viewName}`}>
            <Typography variant="h5" noWrap>
              {viewName}
            </Typography>
          </Tooltip>
          {alias ?? <Typography variant="caption">{alias}</Typography>}
        </Box>
        <IconButton
          icon="visibility"
          onClick={onModalOpen(DialogType.tableDetails, data)}
          color="inherit"
          edge="end"
          tooltip={menuTableDetails}
        />
      </Box>
      {items}
      {hasMoreColumns && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={nodeItem}
          height={TABLE_BODY_ROW_HEIGHT}
        >
          <Typography>
            +{columns.length - MAX_COLUMNS_QUANTITY + 1} more columns
          </Typography>
        </Box>
      )}
    </Card>
  );
};

const MemoizedViewNode = memo(ViewNode);

export default MemoizedViewNode;
