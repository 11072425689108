import { ComponentType, Suspense } from "react";
import { LoadingComponent } from "layouts/common/Loading";

export const withLazyLoading =
  <P extends Record<string, any>>(
    Component: ComponentType<P>,
    hideLoader?: boolean,
  ): ComponentType<P> =>
  ({ ...props }: any) => (
    <Suspense fallback={hideLoader ? null : <LoadingComponent />}>
      <Component {...(props as P)} />
    </Suspense>
  );
