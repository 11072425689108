import * as t from "io-ts";
import { selectors } from "core/editor/reduxModule";
import { Type, buildObjectViewType, types } from "core/runtime-typing";
import {
  IElement,
  TypeFactory,
  UntransformedConfig,
  customExpression,
} from "core/types";
import { SelectorTypes } from "core/types/element";

const getDataSingleRowType: TypeFactory<InternalLinkFieldConfig> = ({
  config,
  state,
}) => {
  let type: Type;
  const viewName = config.dataSource?.viewName;
  const viewList = selectors.viewList(state);
  if (!viewList) {
    // view list still loading, do not show any typing errors
    type = types.any();
  } else if (!viewName) {
    type = types.optional(types.null(), "No source query set");
  } else {
    const view = viewList.find((v) => v.name === viewName);
    if (!view) {
      throw new Error(`Invalid view ${viewName}`);
    }
    type = buildObjectViewType(view, "The data for the row");
    type = types.nullable(type);
  }
  return type;
};

export const internalLinkFieldSelectors: SelectorTypes<InternalLinkFieldConfig> =
  {
    loading: types.boolean(),
    data: getDataSingleRowType,
    error: types.nullable(types.string()),
    identifier: types.any(),
    hasStarted: types.boolean(),
  };

export const InternalLinkFieldConfig = t.intersection([
  t.type({
    linkTo: t.type({
      pageId: customExpression(t.string),
      params: t.record(t.string, customExpression(t.unknown)),
    }),
    label: customExpression(t.union([t.string, t.number, t.null])),
  }),
  t.partial({
    dataSource: t.type({
      viewName: t.string,
      identifierName: t.string,
    }),
    identifierValue: customExpression(t.unknown),
    isButton: t.boolean,
  }),
]);

export type InternalLinkFieldConfig = t.TypeOf<typeof InternalLinkFieldConfig>;

export type UntransformedInternalLinkFieldConfig =
  UntransformedConfig<InternalLinkFieldConfig>;

export type InternalLinkField = IElement<InternalLinkFieldConfig>;
