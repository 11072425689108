import { memo } from "react";

import { Switcher } from "./Switcher";
import { ReduxProps } from "./container";
import { ColorVariant, useStyles } from "./styles";

type OwnProps = {
  color: ColorVariant;
  className?: string;
};

type Props = ReduxProps & OwnProps;

export const LanguageSwitch = memo<Props>(
  ({ language, changeLanguage, color, className }) => {
    const { cx } = useStyles({});

    return (
      <Switcher
        changeLanguage={changeLanguage}
        language={language}
        colorVariant={color}
        className={cx(className, "language-switch")}
      />
    );
  },
);
