import {
  ComponentPropsWithoutRef,
  MouseEvent,
  ReactElement,
  forwardRef,
  memo,
} from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";

import { isDefaultSupportedColor } from "../IconButton/utils";
import { IconNameType, MuiIcon } from "../MuiIcon";

interface IHrefProps {
  href: string;
  external?: boolean;
}

interface IOwnProps {
  /**
   * label of the button
   *
   * automatically handles text overflow
   */
  label?: string;
  tooltip?: string;
  iconRight?: IconNameType;
  iconLeft?: IconNameType;
  disabled?: boolean;
  component?: any;
  className?: string;
  processing?: boolean;
  color?: string;
}

export type IButtonProps = (
  | (IHrefProps & Partial<ComponentPropsWithoutRef<"a">>)
  | Record<string, unknown>
) &
  IOwnProps &
  Omit<ButtonProps, "color">;

const Button = memo<IButtonProps>(
  ({
    onClick,
    tooltip,
    iconRight,
    iconLeft,
    ref,
    color,
    variant,
    disabled,
    className,
    type,
    href,
    external,
    processing,
    ...props
  }) => {
    const dispatch = useDispatch();
    const leftIconCode = iconLeft && <MuiIcon icon={iconLeft} />;
    const rightIconCode = iconRight && <MuiIcon icon={iconRight} />;

    const withTooltip = (children: ReactElement<IButtonProps>) =>
      tooltip && !disabled ? (
        <Tooltip title={tooltip}>{children}</Tooltip>
      ) : (
        children
      );

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (href && !external) {
        event.preventDefault();

        dispatch(routerActions.push(href));
      }
    };

    const canHandleColor = !color || isDefaultSupportedColor(color);

    const buttonChildren =
      "label" in props
        ? !!props.label?.length && (
            <Typography color="inherit" component="div">
              {props.label}
            </Typography>
          )
        : props.children;

    const button = (
      <MuiButton
        {...props}
        onClick={handleClick}
        disabled={disabled}
        variant={variant}
        color={canHandleColor ? (color as ButtonProps["color"]) : undefined}
        ref={ref}
        className={className}
        type={type}
        startIcon={leftIconCode}
        endIcon={
          processing ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            rightIconCode
          )
        }
        href={href}
      >
        {buttonChildren}
      </MuiButton>
    );

    return withTooltip(button);
  },
);

Button.displayName = "CommonButton";

const ButtonComponent = forwardRef<HTMLButtonElement, IButtonProps>(
  (props, ref) => <Button ref={ref} {...props} />,
);

export default ButtonComponent;
