import { memo, useRef, useState } from "react";
import {
  Box,
  BoxProps,
  CardContent,
  CardHeader,
  Divider,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { Controller } from "react-hook-form";
import { useEditorTranslation } from "core/editor";
import { CodeEditor } from "elementTypes/common/CodeEditor";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { useSnackbar } from "utils/hooks";
import Button from "../../Button";

import { useStyles } from "../style";
import { useTranslation } from "../translation";

import { LogoType, Props } from "../types.ts";
import { getFileFromURL, getInitialUploadState } from "../utils.ts";
import { Advanced } from "./Advanced";
import { InputColor } from "./InputColor";
import { InputText } from "./InputText";
import { LogoEditor } from "./Logo/LogoEditor.tsx";
import { LogoUpload } from "./Logo/LogoUpload.tsx";

const colors = ["primary", "secondary"];

export const LayoutForm = memo<Props>(({ layoutDefinition, dense }) => {
  const [logoType, setLogoType] = useState<LogoType>(
    getInitialUploadState(layoutDefinition?.logo),
  );

  const [modal, setModal] = useState(false);
  const logoEditorRef = useRef<AvatarEditor | null>(null);
  const translation = useTranslation();
  const { closeButton, saveButton } = useEditorTranslation();
  const { control, watch, setValue } = useHookFormContext();
  const { classes } = useStyles();
  const showSnackbar = useSnackbar();

  const logoValue: File | string =
    watch("logo.fileName") ?? layoutDefinition?.logo?.fileName ?? "";

  const colorInputs = colors.map((color) => (
    <InputColor
      key={color}
      name={color}
      layoutDefinition={layoutDefinition}
      data-value={color}
    />
  ));

  // FIXME: discarding the editor changes does not reset the debounced value

  const flexBoxStyles: BoxProps = {
    display: "flex",
    flexDirection: "column",
  };

  const handleLogoTypeChange = (_e: any, value: LogoType | null) => {
    if (value === LogoType.PATH) {
      deleteLogo();
    } else if (value === LogoType.UPLOAD) {
      setValue("logo.path", undefined);
    }

    setLogoType(value ?? LogoType.UPLOAD);
  };

  const logoTypeButtons = Object.values(LogoType).map((type) => (
    <ToggleButton
      value={type}
      key={type}
      tooltip={translation[`logoType${type}Tooltip`]}
      placement="top"
    >
      {translation[`logoType${type}Label`]}
    </ToggleButton>
  ));

  const logoTypeSwitcher = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography>{translation.logoTypeLabel}</Typography>
      <ToggleButtonGroup
        size="small"
        value={logoType}
        exclusive
        onChange={handleLogoTypeChange}
      >
        {logoTypeButtons}
      </ToggleButtonGroup>
    </Box>
  );

  const toggleModal = () => setModal((prevState) => !prevState);

  const deleteLogo = () => setValue("logo.fileName", undefined);

  const handleDeleteLogo = () => {
    deleteLogo();
    toggleModal();
  };

  const deleteLogoButton = (
    <Button
      label={translation.deleteLabel}
      color="error"
      onClick={handleDeleteLogo}
    />
  );

  const handleEditLogo = async () => {
    const canvasScaled = logoEditorRef.current?.getImage();

    try {
      const dataUrl = canvasScaled?.toDataURL() ?? "";
      const newLogo = await getFileFromURL(dataUrl);
      setValue("logo.fileName", newLogo);
    } catch (e) {
      showSnackbar(`${translation.errorText}-${String(e)}`, "error");
    } finally {
      toggleModal();
    }
  };

  const handleCodeChange =
    (onChange: (...ev: any[]) => void) => (_: any, __: any, value: string) =>
      onChange(value);

  return (
    <>
      <Box {...flexBoxStyles}>
        <CardHeader
          className={classes.header}
          subheader={translation.colorTitle}
          subheaderTypographyProps={{ variant: "overline", align: "center" }}
        />
        <Divider />
        <CardContent className={classes.container}>{colorInputs}</CardContent>
        <Divider />
        <CardHeader
          className={classes.header}
          subheader={translation.logoTitle}
          subheaderTypographyProps={{ variant: "overline", align: "center" }}
        />
        <Divider />
        <CardContent>
          <Box display="flex" flexDirection="column" gap={2}>
            {logoTypeSwitcher}
            {logoType === LogoType.PATH ? (
              <InputText
                name="path"
                layoutDefinition={layoutDefinition}
                descriptionText={translation.pathHelperText}
              />
            ) : (
              <LogoUpload
                openModal={toggleModal}
                defaultValue={logoValue}
                dense={dense}
              />
            )}
            <InputText name="url" layoutDefinition={layoutDefinition} />
          </Box>
        </CardContent>
        <Divider />
        <CardHeader
          className={classes.header}
          subheader={
            <Tooltip
              title={
                <span>
                  {translation.customStylesTooltip}
                  <pre>{translation.customStylesTooltipExample}</pre>
                </span>
              }
              placement="bottom"
              classes={{ tooltip: classes.tooltipNoMaxWidth }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="2%"
              >
                {translation.customStyles}
                <MuiIcon fontSize="medium" icon="help_outline" />
              </Box>
            </Tooltip>
          }
          subheaderTypographyProps={{ variant: "overline", align: "center" }}
        />
        <Divider />
        <CardContent>
          <Controller
            render={({ field: { ref, onChange, ...fieldInput } }) => (
              <CodeEditor
                language={"text/css"}
                {...fieldInput}
                onChange={handleCodeChange(onChange)}
                innerRef={ref}
              />
            )}
            name="customStyles"
            control={control}
            defaultValue={layoutDefinition.customStyles ?? ""}
          />
        </CardContent>
        <Divider />
        <CardHeader
          className={classes.header}
          subheader={translation.advancedTitle}
          subheaderTypographyProps={{ variant: "overline", align: "center" }}
        />
        <Divider />
        <CardContent>
          <Advanced {...{ layoutDefinition, name: "borderRadius" }} />
        </CardContent>
      </Box>
      <DialogWrapper
        open={modal}
        handleClose={toggleModal}
        title={translation.editLogoLabel}
        keepMounted={false}
        fullWidth={true}
        maxWidth="lg"
        cancelTitle={closeButton}
        submitTitle={saveButton}
        handleSubmit={handleEditLogo}
        subActions={deleteLogoButton}
      >
        <LogoEditor fileName={logoValue} innerRef={logoEditorRef} />
      </DialogWrapper>
    </>
  );
});
