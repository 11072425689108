import { SuperAgentRequest } from "superagent";
import { BuiltinResponseType } from "services/api/types/ResponseType";

let nextListenerId = 0;

const tokenExpiredListeners: Record<number, () => void> = [];

export const onTokenExpired = (callback: () => void) => {
  const id = ++nextListenerId;
  tokenExpiredListeners[id] = callback;
  return () => delete tokenExpiredListeners[id];
};

const format = {
  json: "application/json",
  geo: "application/geo+json",
  text: "text/plain",
};

const field = {
  json: "Content-Type",
  geo: "Accept",
  text: "Content-Type",
};

export function withCommonHeaders(
  req: SuperAgentRequest,
  token?: string | null,
  responseFormat?: BuiltinResponseType,
) {
  const reqField = field[responseFormat ?? "json"];
  const reqFormat = format[responseFormat ?? "json"];
  req = req.set(reqField, reqFormat);
  if (token) {
    req = withAuthHeader(req, token);
  }
  return req;
}

export function withAuthHeader(req: SuperAgentRequest, token: string) {
  return req.set("Authorization", `Bearer ${token}`);
}

/**
 * TODO:
 * When decorators become standard, can be a decorator for all request methods.
 * For now we can change it to a method that receives a callback and wraps it.
 */
export function getResponseError(err: any) {
  /**
   * If the server responds with a status 500 and message: "'NoneType' object has no attribute 'language_id'",
   * it means the model was reloaded and the internals id change, so we treat it as a token expired scenario.
   * https://github.com/cybertec-postgresql/cypex-gui/issues/268
   */
  if (
    err.response?.body &&
    ((err.status === 401 &&
      (err.response.body.name === "AuthorizationRequiredError" ||
        err.response.body.message === "JWT expired")) ||
      (err.response &&
        err.response.body.message &&
        err.response.body.message.indexOf(
          "'NoneType' object has no attribute 'language_id'",
        ) !== -1))
  ) {
    notifyTokenExpiredListeners();
  }

  return err.response
    ? err.response?.body ?? err.response.error
    : new Error(err.toString());
}

function notifyTokenExpiredListeners() {
  for (const id of Object.keys(tokenExpiredListeners)) {
    tokenExpiredListeners[+id]();
  }
}
