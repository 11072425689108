import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormLabel } from "@mui/material";

import * as L from "leaflet";
import "leaflet/dist/leaflet.css";

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import "elementTypes/common/leaflet/DefaultIcon";

import { ConnectedReduxModuleProps } from "core";
import {
  OSM_ATTRIBUTION,
  OSM_TILE_LAYER_URL,
} from "elementTypes/common/leaflet/constants";

import { DEFAULT_CONFIG } from "./constants";
import { ReduxModule } from "./reduxModule";
import { useStyles } from "./styles";
import { GeoJSONInput, GeoJSONInputConfig } from "./types";

/**
 * calculate a config value, fallback to DEFAULT_CONFIG.
 * returns false if `disabled` is false.
 *
 * @param key the config key
 * @param config the element config
 * @param disabled whether the element is disabled
 */
function calculateConfig<
  Key extends keyof typeof DEFAULT_CONFIG & GeoJSONInputConfig,
>(key: Key, config: GeoJSONInputConfig, disabled = false) {
  return !disabled && (config[key] ?? DEFAULT_CONFIG[key]);
}

const DefaultGeoJSONInput = memo<
  ConnectedReduxModuleProps<ReduxModule, GeoJSONInput>
>(
  ({
    value,
    element: {
      id,
      config: { tileLayerUrl, maximumFeatures },
      config,
      i18n: { label = "" },
    },
    disabled,
    required,
    changeValue,
    changeTouched,
  }) => {
    const { classes } = useStyles();
    const [focused, setFocused] = useState<boolean>(false);
    const mapRef = useRef<L.Map | null>(null);

    const initializedDataRef = useRef(false);

    const handleValueChange = useCallback(
      (newValue: any) => {
        changeValue(newValue);
        changeTouched(true);
      },
      [changeTouched, changeValue],
    );

    useEffect(() => {
      const map = (mapRef.current = L.map(id));

      if (config.geoJSONdefaultValue) {
        const defaultJsonLayer = L.geoJSON(
          JSON.parse(config.geoJSONdefaultValue),
        );
        defaultJsonLayer.addTo(map);
      }

      map.setView(
        [
          config.latitude ?? DEFAULT_CONFIG.latitude,
          config.longitude ?? DEFAULT_CONFIG.longitude,
        ],
        config.zoom ?? DEFAULT_CONFIG.zoom,
      );

      L.tileLayer(tileLayerUrl ?? OSM_TILE_LAYER_URL, {
        attribution: tileLayerUrl ? undefined : OSM_ATTRIBUTION,
      }).addTo(map);

      map.pm.addControls({
        drawCircle: false,
        drawCircleMarker: false,
        // drawMarker: false,
        // TODO allow this to be configured as well
        cutPolygon: false,

        // configured dynamically
        editMode: !disabled,
        // configured by user
        removalMode: calculateConfig("allowDelete", config, disabled),
        drawPolygon: calculateConfig("allowPolygon", config, disabled),
        drawPolyline: calculateConfig("allowLine", config, disabled),
        drawRectangle: calculateConfig("allowRectangle", config, disabled),
        dragMode: calculateConfig("allowDrag", config, disabled),
        drawMarker: calculateConfig("allowMarker", config, disabled),
        drawText: calculateConfig("allowText", config, disabled),
      });

      if (!disabled) {
        map.pm.enableGlobalEditMode({});
      }

      map.on("pm:create", () => {
        const newValue = L.featureGroup();

        (map.pm as any).getGeomanLayers().forEach((l: L.Polygon) => {
          newValue.addLayer(l);
        });

        handleValueChange(newValue.toGeoJSON());
      });

      map.on("pm:remove", () => {
        const newValue = L.featureGroup();

        (map.pm as any).getGeomanLayers().forEach((l: L.Polygon) => {
          newValue.addLayer(l);
        });

        handleValueChange(newValue.toGeoJSON());
      });

      map.on("focus", () => {
        if (label || required) {
          setFocused(true);
        }
      });

      map.on("blur", () => {
        if (label || required) {
          setFocused(false);
        }
      });

      return () => {
        mapRef.current?.remove();
      };
    }, [
      config,
      disabled,
      handleValueChange,
      id,
      tileLayerUrl,
      label,
      required,
    ]);

    useEffect(() => {
      const map = mapRef.current;

      if (!map) {
        // should never happen, used to satisfy TS
        return;
      }

      const geoJsonLayer = L.geoJSON(value as any);
      geoJsonLayer.addTo(map);

      if (!initializedDataRef.current) {
        const bounds = geoJsonLayer.getBounds();
        // invalid when initially empty
        if (bounds.isValid()) {
          map.fitBounds(geoJsonLayer.getBounds());
        }
        initializedDataRef.current = true;
      }

      geoJsonLayer.on("pm:edit", () => {
        const newValue = L.featureGroup();

        (map.pm as any).getGeomanLayers().forEach((l: L.Polygon) => {
          newValue.addLayer(l);
        });

        handleValueChange(newValue.toGeoJSON());
      });

      return () => {
        // delete layers if they are not the main two layers
        // I guess this can be checked with _container, but I'm not sure
        // there might be a better way to check this
        map.eachLayer((l) => {
          if (!(l as any)._container) {
            l.removeFrom(map);
          }
        });
      };
    }, [value, id, handleValueChange]);

    // handle creation enable / disable based on current count of features and maximumFeatures
    useEffect(() => {
      const map = mapRef.current;
      if (!map) {
        return;
      }

      if (maximumFeatures) {
        // https://stackoverflow.com/questions/45328054/how-to-disable-not-remove-toolbar-button-in-leaflet-draw
        const featureCount = (map.pm as any).getGeomanLayers().length;
        const controlContainer: HTMLElement | null = (
          map as any
        )._container.querySelector(".leaflet-control-container");
        if (controlContainer) {
          if (featureCount >= maximumFeatures) {
            // disable creating
            controlContainer.classList.add(classes.createDisabled);
            // stop creating, enable editing
            map.pm.enableGlobalEditMode({});
          } else {
            // enable creating
            controlContainer.classList.remove(classes.createDisabled);
          }
        }
      }
      // update this whenever the value changes
    }, [classes.createDisabled, maximumFeatures, value]);

    return (
      <>
        <FormLabel required={required} focused={focused}>
          {label}
        </FormLabel>
        <div id={id} className={classes.root} />
      </>
    );
  },
);

DefaultGeoJSONInput.displayName = "DefaultGeoJSONInput";

export default DefaultGeoJSONInput;
