import { IStaticRouteConfig } from "core/router/types";

import { isDevMode } from "utils/other";
import { RoutePaths } from "../routes";
import {
  ApplicationsPageRoute,
  AppsPageRoute,
  AuditsPageRoute,
  DataApiRoute,
  DatabasePageRoute,
  ExtensionsPageRoute,
  FilesPageRoute,
  InternalPageRoute,
  LdapPageRoute,
  RepositoryConfigurationRoute,
  RolesPageRoute,
  SettingsPageRoute,
  UsersPageRoute,
} from "./pages";
import { AdminPage } from "./pages/home";

const devMode = isDevMode();

export const route: IStaticRouteConfig = {
  Component: AdminPage,
  auth: true,
  isAdmin: true,
  routes: {
    [RoutePaths.Roles]: RolesPageRoute,
    [RoutePaths.Database]: DatabasePageRoute,
    [RoutePaths.Apps]: AppsPageRoute,
    [RoutePaths.Users]: UsersPageRoute,
    [RoutePaths.Ldap]: LdapPageRoute,
    [RoutePaths.AdminSettings]: SettingsPageRoute,
    [RoutePaths.Audits]: AuditsPageRoute,
    [RoutePaths.Files]: FilesPageRoute,
    [RoutePaths.DataApi]: DataApiRoute,
    [RoutePaths.AppRepos]: ApplicationsPageRoute,
    [RoutePaths.RepositoryConfig]: RepositoryConfigurationRoute,
    [RoutePaths.Extensions]: ExtensionsPageRoute,
    ...(devMode && {
      [RoutePaths.Internal]: InternalPageRoute,
    }),
  },
};
