import { ChangeEvent, memo } from "react";
import { RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Switch from "@mui/material/Switch";
import {
  ColorSelector,
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";

import { IconNameType } from "elementTypes/common/MuiIcon";
import { UntransformedExternalLinkFieldConfig } from "../../types";
import { useExternalLinkFieldEditorTranslation } from "../translation";

enum Variants {
  contained = "contained",
  outlined = "outlined",
}

export const Display = memo(() => {
  const { displayConfigTitle, isButtonLabel } =
    useExternalLinkFieldEditorTranslation();

  const {
    elementModel: {
      config: { isButton = false, icon, variant = "outlined" },
      config,
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedExternalLinkFieldConfig>();

  const toggleIsButton = () => changeConfigValue("isButton", !isButton);

  const changeIcon = (newIcon: UntransformedExternalLinkFieldConfig["icon"]) =>
    changeConfigValue("icon", newIcon);

  const handleColorChange = (newColor: string) =>
    changeConfigValue("color", newColor);

  const handleVariantChange = (_e: ChangeEvent, value: string) => {
    changeConfigValue("variant", value);
  };

  const variants = Object.keys(Variants).map((itemVariant) => (
    <FormControlLabel
      key={itemVariant}
      control={<Radio color="primary" />}
      label={itemVariant}
      value={itemVariant}
    />
  ));

  return (
    <Section title={displayConfigTitle} wrapped={true}>
      <ColorSelector config={config} onChange={handleColorChange} />
      <FormControlLabel
        control={<Switch checked={isButton} onChange={toggleIsButton} />}
        label={isButtonLabel}
      />

      {isButton && (
        <>
          <IconAutocomplete
            label="Icon"
            value={icon as IconNameType}
            onChange={changeIcon}
          />

          <RadioGroup row={true} value={variant} onChange={handleVariantChange}>
            {variants}
          </RadioGroup>
        </>
      )}
    </Section>
  );
});
