import { connect } from "react-redux";
import { actions, selectors } from "core/editor/reduxModule";

import { EditorSwitch as ESComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  editModeOn: selectors.editModeOn(state),
  layoutChanged: selectors.isLayoutChanged(state),
});

export const EditorSwitch = connect(mapStateToProps, actions)(ESComponent);
