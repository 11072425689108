import { createUseTranslation } from "core/session/translation";

export const responseFormatTranslation = {
  en: {
    responseFormatLabel: "Builtin Response Format",
    jsonLabel: "JSON",
    geoLabel: "GEO+JSON",
    textLabel: "CSV",
    docsTooltip: "Resource Representation Docs",
    jsonLabelHint: "Standard format for API endpoints",
    geoLabelHint: "A GeoJSON FeatureCollection object",
    textLabelHint: "CSV format",
  },
};

export const useResponseFormatTranslation = createUseTranslation(
  responseFormatTranslation,
);
