import { connect } from "react-redux";

import { actions, selectors } from "../reduxModule";

import { EditorLayout as EEComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  editModeOn: selectors.editModeOn(state),
  draggableElementParams: selectors.draggableElementParams(state),
  isLayoutChanged: selectors.isLayoutChanged(state),
});

const mapDispatchToProps = {
  changeConfigValue: actions.changeConfigValue,
  changePositionValue: actions.changePositionValue,
  changeTranslationValue: actions.changeTranslationValue,
  setDraggableElement: actions.setDraggableElement,
  unselectElement: actions.unselectElement,
};

export const EditorLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EEComponent);
