import { ChangeEvent, memo, useCallback } from "react";
import { Box, Link, TextField } from "@mui/material";
import {
  buildCustomExpressionValue,
  getExpression,
  isCustomExpression,
} from "core";
import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import ActionConfigEditor from "elementTypes/common/ActionConfig";
import {
  ActionConfigType,
  ActionConfigsType,
} from "elementTypes/common/ActionConfig/types";
import { AlertBox } from "elementTypes/common/AlertBox";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import { IAutocompleteValue } from "elementTypes/common/Autocomplete/types";
import { Spacer } from "elementTypes/common/Spacer";
import { useFunctionOptions } from "queries/admin";

import { UntransformedCallButtonConfig } from "../../types";
import { useCallButtonEditorTranslation } from "../translation";

type ConfigType = "callArgs" | "callName";

const PROCEDURES_URL =
  "https://postgrest.org/en/stable/references/api/stored_procedures.html#stored-procedures";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { callName, callArgs, onSuccess },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCallButtonConfig>();
  const { configTitle } = useEditorTranslation();
  const translation = useCallButtonEditorTranslation();

  const { data, isInitialLoading } = useFunctionOptions();

  const handleExpressionValueChange = useCallback(
    (configType: ConfigType) => (newValue: string) => {
      changeConfigValue(configType, newValue);

      if (configType === "callName") {
        const args = data?.find((func) => func?.value === newValue)?.argnames;

        changeConfigValue(
          "callArgs",
          args?.length
            ? args.reduce(
                (res, funcName) => ({ ...res, [funcName]: "" }),
                {} as Record<string, unknown>,
              )
            : {},
        );
      }
    },
    [data, changeConfigValue],
  );

  const customAutocomplete = ({
    onChange: onTextChange,
  }: NonExpressionEditorProps) => (
    <Autocomplete
      options={data}
      onChange={
        onTextChange as (
          value: IAutocompleteValue,
          reason?: string | undefined,
        ) => void
      }
      label={translation[`${callName}InputLabel`]}
      isLoading={isInitialLoading}
      value={callName}
    />
  );

  const args = data?.find((func) => func?.value === callName);

  const customArgEditor = (argsProps: NonExpressionEditorProps) => {
    const argsValue = (callArgs ?? {}) as Record<string, unknown>;
    const handleArgChange = (ev: ChangeEvent<HTMLInputElement>) => {
      argsProps.onChange({
        ...argsValue,
        [ev.target.name]: ev.target.value,
      } as any);
    };

    return args?.argnames?.map((arg, index) => (
      <Box key={arg ?? index} pb={0.5}>
        <TextField
          label={arg}
          name={arg}
          value={argsValue?.[arg] ?? ""}
          onChange={handleArgChange}
          fullWidth
        />
      </Box>
    ));
  };

  const handleToggleArgsMode = (isExpression: boolean) => {
    const nextVal =
      isExpression && typeof callArgs === "string"
        ? JSON.parse(getExpression(callArgs))
        : buildCustomExpressionValue(JSON.stringify(callArgs, null, 2));

    handleExpressionValueChange("callArgs")(nextVal);
  };

  const handleToggleNameMode = (isExpression: boolean) => {
    const nextVal = isExpression
      ? ""
      : buildCustomExpressionValue(`"${callName}"`);

    handleExpressionValueChange("callName")(nextVal);
  };

  const handleOnSuccessChange = (newValue: ActionConfigType[]) =>
    changeConfigValue("onSuccess", newValue);

  return (
    <Section title={configTitle} wrapped={true}>
      <CustomExpressionEditor
        label={translation.callNameLabel}
        value={callName}
        config={config}
        onChange={handleExpressionValueChange("callName")}
        nonExpressionEditor={customAutocomplete}
        onToggleMode={handleToggleNameMode}
      />
      {(args?.argnames?.length || isCustomExpression(callName)) && (
        <CustomExpressionEditor
          label={translation.callArgsLabel}
          value={
            isCustomExpression(callArgs)
              ? callArgs
              : isCustomExpression(callName)
                ? buildCustomExpressionValue(JSON.stringify(callArgs, null, 2))
                : JSON.stringify(callArgs, null, 2)
          }
          config={config}
          onChange={handleExpressionValueChange("callArgs")}
          disableSwitcher={!callName || isCustomExpression(callName)}
          nonExpressionEditor={customArgEditor as any}
          onToggleMode={handleToggleArgsMode}
        />
      )}
      <AlertBox
        color="info"
        message={
          <Link href={PROCEDURES_URL} target="_blank">
            {translation.functionDefinitionInfo}
          </Link>
        }
      />
      <Spacer vertical={1} />
      <ActionConfigEditor
        onActionConfigsChange={handleOnSuccessChange}
        actionConfigs={onSuccess as ActionConfigsType}
        elementConfig={config}
      />
    </Section>
  );
});
