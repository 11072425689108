import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import AdminService from "services/admin";
import { StorageFileMetadata } from "services/api/types/FileStorage";
import {
  FileForm,
  FileGroup,
  FileResponse,
  FileType,
  TFileUpdate,
} from "staticPages/admin/pages/files/pages/upload/types";

import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";

import { QueryKeys } from "./queryKeys";
import { getFileOptions, getOptions } from "./utils";

const services = AdminService.getInstance();

export const fetchFiles: Query<StorageFileMetadata[]> = {
  queryKey: QueryKeys.fetchFiles,
  queryFn: services.getAllFiles,
};

export const fetchFileTypes: Query<FileType[]> = {
  queryKey: QueryKeys.fetchFileTypes,
  queryFn: services.getFileTypes,
};

export const fetchFileGroups: Query<FileGroup[]> = {
  queryKey: QueryKeys.fetchFileGroups,
  queryFn: services.getFileGroups,
};

export const getFilesByGroupType: Query<
  StorageFileMetadata[],
  Parameters<typeof services.getFilesByGroupType>[1]
> = {
  queryKey: QueryKeys.getFilesByGroupType,
  queryFn: services.getFilesByGroupType,
};

export const useFiles = () => useAuthenticatedQuery(fetchFiles);

type SelectorOptions = { label: string; value: string | number }[];

export const useFileTypes = (
  options: UseQueryOptions<FileType[], Error, SelectorOptions>,
) => useAuthenticatedQuery(fetchFileTypes, undefined, options);

export const useFileGroups = (
  options: UseQueryOptions<FileGroup[], Error, SelectorOptions>,
) => useAuthenticatedQuery(fetchFileGroups, undefined, options);

export const useDeleteFile = (
  options: UseMutationOptions<unknown, unknown, { fileName: string }>,
) => useAuthenticatedMutation(services.deleteFile, options);

export const useSaveFile = (
  options: UseMutationOptions<FileResponse, unknown, FileForm>,
) => useAuthenticatedMutation(services.uploadFile, options);

export const useUpdateFile = (
  options: UseMutationOptions<unknown, unknown, TFileUpdate>,
) => useAuthenticatedMutation(services.updateFile, options);

export const useFileTypeOptions = () =>
  useFileTypes({
    select: getOptions("typeName"),
  });

export const useFileGroupOptions = () =>
  useFileGroups({
    select: getOptions("name"),
  });

export const useGetFilesByGroupType = <
  SelectorResult = ReturnType<typeof getFileOptions>,
>(
  params: Parameters<typeof services.getFilesByGroupType>[1],
  options?: UseQueryOptions<StorageFileMetadata[], Error, SelectorResult>,
) => useAuthenticatedQuery(getFilesByGroupType, params, options);
