import { memo } from "react";
import { Divider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDatabasePanelContext } from "../context/databasePanelPermission/DatabasePanelContext.utils.ts";
import { useDatabaseTranslation } from "../translation.ts";
import { DatabasePanelType } from "./types.ts";

export const ToggleDatabasePanel = memo(() => {
  const { type, setType } = useDatabasePanelContext();
  const translation = useDatabaseTranslation();

  const panelTypeButtons = Object.values(DatabasePanelType).map((panelType) => (
    <ToggleButton
      value={panelType}
      key={panelType}
      data-testid={`database-panel-button-${panelType}`}
      sx={{
        width: "50%",
      }}
    >
      {translation[panelType]}
    </ToggleButton>
  ));

  return (
    <Stack direction="row" gap={1} width={"100%"}>
      <Divider orientation="vertical" flexItem />
      <ToggleButtonGroup
        value={type}
        onChange={setType}
        exclusive
        size="medium"
        fullWidth
        color="primary"
      >
        {panelTypeButtons}
      </ToggleButtonGroup>
    </Stack>
  );
});
