import * as t from "io-ts";
import { Type, types } from "core/runtime-typing";
import { mapGeneralType } from "core/runtime-typing/utils";
import {
  ConnectedReduxModuleProps,
  DEFAULT_LANGUAGE_CODE,
  GeneralType,
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  SelectorTypes,
  TypeFactory,
} from "core/types";
import { WithFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { FormInputConfig } from "../common";

import { ReduxModule } from "./reduxModule";
import { getFieldRowType } from "./utils";

const getDataRowType: TypeFactory<SubFormTableConfig> = ({ config }) => {
  let type: Type = types.interface(
    config.fields.reduce((acc, next) => {
      const fieldType = mapGeneralType(next.generalType);
      return {
        ...acc,
        [next.name]: !next.nullable
          ? fieldType
          : types.optional(types.nullable(fieldType)),
      };
    }, {}),
  );

  type = types.array(type);

  return type;
};

export const FieldI18n = t.type({
  title: t.string,
});

export const FieldConfig = t.type({
  name: t.string,
  type: t.string,
  i18n: t.type({ [DEFAULT_LANGUAGE_CODE]: FieldI18n }),
  generalType: GeneralType,
  nullable: t.boolean,
});

export type FieldConfig = t.TypeOf<typeof FieldConfig>;

export type IState = {
  selected: {
    identifier: number | string | null;
    row: Record<string, unknown> | null;
  };
};

const getSelectedRowType: TypeFactory<SubFormTableConfig> = (params) => {
  return types.interface({
    identifier: types.nullable(types.union([types.string(), types.number()])),
    row: getFieldRowType(params),
  });
};

export const subFormTableSelectors: SelectorTypes<SubFormTableConfig> = {
  value: getDataRowType,
  errors: types.any(),
  touched: types.boolean(),
  disabled: types.boolean(),
  selected: getSelectedRowType,
};

export const SubFormTableConfig = t.intersection([
  WithFieldDataSourceConfig,
  FormInputConfig,
  t.type({
    fields: t.array(FieldConfig),
  }),
]);

export type SubFormTableConfig = t.TypeOf<typeof SubFormTableConfig>;

export const SubFormTableTranslationKeys = ["label"] as const;

export type SubFormTableTranslationKeys =
  (typeof SubFormTableTranslationKeys)[number];

export type SubFormTable = IElement<
  SubFormTableConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  SubFormTableTranslationKeys
>;

export type Props = ConnectedReduxModuleProps<ReduxModule, SubFormTable>;
