import { memo, useEffect } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
} from "@mui/material";

import { Controller } from "react-hook-form";
import { Autocomplete } from "elementTypes/common/Autocomplete";
import Button from "elementTypes/common/Button";
import { RenderProps } from "elementTypes/common/HookForm";
import { useHookFormContext } from "elementTypes/common/HookForm/utils";
import IconButton from "elementTypes/common/IconButton";
import { useRepositoryBranches } from "queries/admin/extensionsData";
import { RepositoryBranch, RepositoryConfiguration } from "queries/admin/types";
import { getApiError } from "queries/utils";
import { RoutePaths } from "staticPages/routes";
import { useHookFormError } from "utils/hooks/useHookFormError";
import { useRepositoryConfigurationTranslation } from "../translation";

const validationRules = {
  required: true,
  validate: (val: any) => !!val.toString().trim(),
};

const BranchesInput = memo(() => {
  const { control, setError, clearErrors, getValues } =
    useHookFormContext<Partial<RepositoryConfiguration>>();
  const t = useRepositoryConfigurationTranslation();
  const defaultBranch = getValues()?.repositoryBranch ?? "";

  const fetchBranches = useRepositoryBranches({
    onSuccess: (res) => {
      if ("message" in res && res.message) {
        setError("repositoryBranch", { message: `${res.message}` });
      } else {
        clearErrors("repositoryBranch");
      }
    },
    onError: (error) => {
      const msg = getApiError(error);

      const errorDetails =
        `${(error as Record<"errors", Record<string, any>[]>)?.errors?.map(
          (err: Record<string, any>) => {
            return err?.children?.map(
              (fieldError: Record<string, Record<string, unknown>>) => {
                return Object.values(fieldError?.constraints ?? {}).toString();
              },
            );
          },
        )}` ?? "";
      setError("repositoryBranch", { message: `${msg} ${errorDetails}` });
    },
  });

  const handleRefreshClick = () => {
    const values = getValues();
    fetchBranches.mutate({
      personalAccessToken: values?.personalAccessToken ?? "",
      repositoryPath: values?.repositoryPath ?? "",
      repositoryBaseUrl: values?.repositoryBaseUrl ?? "",
    });
  };

  useEffect(() => {
    if (defaultBranch.trim().length) {
      handleRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = Array.isArray(fetchBranches?.data)
    ? fetchBranches?.data ?? []
    : [];

  const options = data.map((branch: RepositoryBranch) => ({
    value: branch?.name,
    label: branch?.name,
  }));
  const rederBranchesInput = ({
    field,
    fieldState: { error },
  }: RenderProps<Partial<RepositoryConfiguration>, "repositoryBranch">) => {
    return (
      <Autocomplete
        value={field.value}
        innerRef={field.ref}
        onChange={field.onChange}
        options={options}
        label={t.repositoryBranch}
        error={error?.message}
        startAdornment={
          <>
            {fetchBranches.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                size="small"
                icon="refresh"
                tooltip={t.generateRefreshTooltip}
                onClick={handleRefreshClick}
                color="primary"
              />
            )}
          </>
        }
      />
    );
  };

  return (
    <FormControl fullWidth>
      <Controller
        render={rederBranchesInput}
        name="repositoryBranch"
        control={control}
        defaultValue={options?.[0]?.value ?? ""}
      />
    </FormControl>
  );
});

export const FormFields = memo(() => {
  const t = useRepositoryConfigurationTranslation();
  const getErrorMessage = useHookFormError();
  const { control } = useHookFormContext<Partial<RepositoryConfiguration>>();

  return (
    <Box py={1.5} px={2} display="flex" flexDirection="column" gap={1}>
      <FormControl fullWidth>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label={t.userName}
              error={Boolean(error)}
              helperText={getErrorMessage(error)}
              autoFocus
              spellCheck={false}
              InputProps={{
                autoComplete: "off",
              }}
              {...field}
            />
          )}
          name="userName"
          control={control}
          rules={validationRules}
          defaultValue=""
        />
      </FormControl>
      <FormControl fullWidth>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label={t.personalAccessToken}
              error={Boolean(error)}
              helperText={getErrorMessage(error)}
              autoComplete="new-password"
              spellCheck={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={t.personalAccessTokenHelper}>
                      <Link
                        target="_blank"
                        href={t.personalAccessTokenHelpUrl}
                        rel="noreferrer"
                      >
                        <IconButton
                          icon="help_outline"
                          aria-label="delete"
                          size="medium"
                        />
                      </Link>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              {...field}
            />
          )}
          name="personalAccessToken"
          control={control}
          rules={validationRules}
          defaultValue=""
        />
      </FormControl>
      <FormControl fullWidth>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label={t.repositoryBaseUrl}
              error={Boolean(error)}
              helperText={getErrorMessage(error)}
              spellCheck={false}
              InputProps={{
                autoComplete: "off",
              }}
              {...field}
            />
          )}
          name="repositoryBaseUrl"
          control={control}
          defaultValue=""
        />
      </FormControl>
      <FormControl fullWidth>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label={t.repositoryPath}
              error={Boolean(error)}
              helperText={getErrorMessage(error)}
              spellCheck={false}
              InputProps={{
                autoComplete: "off",
              }}
              {...field}
            />
          )}
          name="repositoryPath"
          control={control}
          rules={validationRules}
          defaultValue=""
        />
      </FormControl>

      <BranchesInput />
      <FormControl fullWidth>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label={t.repositoryName}
              error={Boolean(error)}
              helperText={getErrorMessage(error)}
              spellCheck={false}
              InputProps={{
                autoComplete: "off",
              }}
              {...field}
            />
          )}
          name="repositoryName"
          control={control}
          rules={validationRules}
          defaultValue=""
        />
      </FormControl>
    </Box>
  );
});

export const FormButtons = memo<{
  testConnection: (conf: RepositoryConfiguration) => void;
  isLoading?: boolean;
}>(({ isLoading, testConnection }) => {
  const {
    formState: { isSubmitting },
    watch,
  } = useHookFormContext();

  const currentConfig = watch() as RepositoryConfiguration;
  const testDisabled = [
    !currentConfig.personalAccessToken,
    !currentConfig.repositoryPath,
    !currentConfig.userName,
    !currentConfig.repositoryBaseUrl,
  ].includes(true);

  const t = useRepositoryConfigurationTranslation();

  const handleClick = () => testConnection(currentConfig);

  return (
    <Box p={1} display="flex" justifyContent="space-between">
      <Button
        label={"Cancel"}
        href={RoutePaths.RepositoryConfig}
        color="secondary"
      />
      <Box display="flex" gap={1}>
        <Button
          color="info"
          disabled={testDisabled || isLoading || isSubmitting}
          label={t.test}
          onClick={handleClick}
          processing={isLoading}
        />
        <Button
          color="primary"
          disabled={isSubmitting}
          processing={isSubmitting}
          iconRight="forward"
          type="submit"
          label={t.save}
        />
      </Box>
    </Box>
  );
});
