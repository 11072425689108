import { memo } from "react";
import { Box } from "@mui/material";
import { useDatabasePanelContext } from "../context/databasePanelPermission/DatabasePanelContext.utils.ts";
import { QueriesPanelProvider } from "../context/queriesPanel";

import { DataModel } from "../erd/types";
import { EntitiesPanel } from "./EntitiesPanel";
import { PanelSkeleton } from "./PanelSkeleton";
import { QueriesPanel } from "./QueriesPanel/component.tsx";
import { DatabasePanelType, QueryObject } from "./types";

type Props = {
  loading: {
    model: boolean;
    queries?: boolean;
  };
  entities: DataModel;
  showActions?: boolean;
  isInternal?: boolean;
  queries?: QueryObject[];
};

export const DatabaseListPanel = memo<Props>(
  ({ loading, entities, showActions, isInternal, queries }) => {
    const { type } = useDatabasePanelContext();

    const entitiesPanel = loading.model ? (
      <PanelSkeleton />
    ) : (
      <EntitiesPanel entities={entities} showActions={showActions} />
    );

    // !!!: when the internal DB is shown, we can't create queries, use this section to create a new one to interact with CYPEX DB
    const queriesPanel = isInternal ? (
      <span />
    ) : (
      <QueriesPanelProvider
        queries={queries ?? []}
        isLoading={loading.queries}
        isInternal={isInternal}
      >
        <QueriesPanel />
      </QueriesPanelProvider>
    );

    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        width="100%"
        minWidth="10vw" // used for limiting width change
        minHeight="80vh" // used for limiting height change
        height="100%"
      >
        {type === DatabasePanelType.entities ? entitiesPanel : queriesPanel}
      </Box>
    );
  },
);
