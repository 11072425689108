import { MouseEvent, memo, useMemo, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Radio,
  Tooltip,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import { FormattedDate, FormattedTime } from "react-intl";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { Color } from "elementTypes/common/StyledTypography/utils";
import {
  useNotifications,
  useReadNotifications,
} from "queries/app/notificationsData";
import { NotificationLevelIcons } from "queries/app/types";
import { QueryKeys } from "../../../queries/app/queryKeys";
import useStyles from "./styles";
import { useAppNotificationTranslation } from "./translations";

const REFRESH_INTERVAL = 10000;

const relativeValue = (createdAt: Date) =>
  formatDistanceToNow(new Date(createdAt), {
    addSuffix: true,
  });

export const Notifications = memo(() => {
  const { classes } = useStyles();
  const translation = useAppNotificationTranslation();
  const queryClient = useQueryClient();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const { data, isInitialLoading } = useNotifications({
    refetchInterval: REFRESH_INTERVAL,
  });

  const unreadNotificationsIds = useMemo(
    () =>
      data?.reduce(
        (acc: number[], curr) => (curr.readAt ? acc : [...acc, curr.id]),
        [],
      ),
    [data],
  );

  const { mutate } = useReadNotifications({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.fetchNotifications],
      });
    },
  });

  const markAllAsRead = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    unreadNotificationsIds?.length &&
      mutate({ notificationIds: unreadNotificationsIds });
  };

  const handleReadNotification = (readAtId: number) => () => {
    mutate({ notificationIds: [readAtId] });
  };

  const notificationList = useMemo(
    () =>
      data?.map((item) => (
        <ListItem key={item.id} selected={!item.readAt} divider>
          <ListItemAvatar>
            <Avatar
              classes={{
                colorDefault: classes.avatarColorDefault,
                root: classes.avatar,
              }}
              title={item.createdBy?.role || translation.noUser}
              alt={item.createdBy?.role || "U"}
            >
              {item.createdBy?.role[0] || (
                <MuiIcon icon="person" fontSize="large" color="disabled" />
              )}
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            primary={
              <Box display="inline-flex" gap={2} alignItems="center">
                <StyledTypography
                  text={
                    <MuiIcon
                      color="inherit"
                      fontSize="small"
                      icon={NotificationLevelIcons[item.level]}
                    />
                  }
                  boxProps={{
                    width: "auto",
                  }}
                  color={item.level as Color}
                />
                {item.message}
              </Box>
            }
            secondary={
              <Tooltip
                title={
                  <span>
                    <FormattedDate value={item.createdAt} />{" "}
                    <FormattedTime value={item.createdAt} />
                  </span>
                }
              >
                <span>{relativeValue(item.createdAt)}</span>
              </Tooltip>
            }
            secondaryTypographyProps={{
              variant: "caption",
            }}
          />
          {!item?.readAt && (
            <Tooltip title={translation.readNotificationTitle}>
              <Radio
                checked={!!item.readAt}
                value={item.id}
                size="small"
                onChange={handleReadNotification(item.id)}
              />
            </Tooltip>
          )}
        </ListItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  return (
    <>
      <Tooltip title={translation.notifications}>
        <IconButton
          id="notification_pop"
          onClick={handleClick}
          sx={{ color: "inherit", fontSize: "medium" }}
          aria-label="notifications"
          size="medium"
        >
          <Badge
            badgeContent={unreadNotificationsIds?.length}
            max={9}
            color="error"
          >
            <NotificationsNoneIcon fontSize="large" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id="notification_pop"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          variant: "outlined",
          elevation: 0,
        }}
      >
        <List disablePadding classes={{ root: classes.root }}>
          <ListItem divider>
            <ListItemText
              primaryTypographyProps={{
                variant: "subtitle2",
              }}
            >
              {translation.title}
            </ListItemText>
          </ListItem>
          {data?.length ? (
            <ListItem divider style={{ paddingBlock: 0.5, margin: 0 }}>
              <ListItemText
                primaryTypographyProps={{
                  variant: "overline",
                }}
              >
                {translation.latestLabel}
              </ListItemText>
              {!!unreadNotificationsIds?.length && (
                <Link href="#" variant="caption" onClick={markAllAsRead}>
                  {translation.readAllNotificationsTitle}
                </Link>
              )}
            </ListItem>
          ) : null}
          {data?.length ? (
            notificationList
          ) : isInitialLoading ? (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={1}
            >
              <CircularProgress size={25} color="inherit" />
            </Box>
          ) : (
            <ListItem>
              <ListItemText>{translation.noData}</ListItemText>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
});
