import { lazy, memo } from "react";

import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { IEchartsContainerMultiProps } from "./EchartsContainer";
import { IOptionEchartProps } from "./types";

export const Echarts = withLazyLoading(
  lazy(() => import("./Echarts")),
  true,
);

const EchartsContainerDataset = memo<IEchartsContainerMultiProps>(
  ({
    dataSource: {
      columns: { labelColumnName, valueColumnNames },
    },
    data,
    defaultOptions,
    type,
    title,
    i18n,
    ...rest
  }) => {
    const defaultSeries = defaultOptions.series ?? [{}];

    const option: IOptionEchartProps = {
      ...defaultOptions,
      yAxis: {
        ...defaultOptions.yAxis,
        type: "value",
      },
      legend: {
        bottom: 0,
        ...defaultOptions.legend,
      },
      xAxis: {
        ...defaultOptions.xAxis,
        type: "category",
      },
      dataset: {
        dimensions: [labelColumnName, ...valueColumnNames],
        source: data,
        sourceHeader: false,
      },
      series: valueColumnNames.map((columnName, i) => ({
        name: i18n?.[`${columnName}Label`] ?? columnName,
        ...(defaultSeries[i] ?? defaultSeries[0]),
        type,
      })),
      title: title
        ? {
            text: title,
            x: "center",
            // subtext,
          }
        : null,
    };
    return <Echarts option={option} {...rest} chartType={type} />;
  },
);

export default EchartsContainerDataset;
