import { MouseEvent, lazy, useState } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { Box, Theme, Tooltip, Typography } from "@mui/material";
import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";
import { makeStyles } from "tss-react/mui";

import { IElementModel } from "core";
import { DEBUG_MODE_ENABLED } from "core/debug";
import IconButton from "elementTypes/common/IconButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { ErrorsReport } from "utils/io-ts/errorReporter";
import { ValidationErrorObject } from "utils/io-ts/validate";
import { humanizeCamelCase } from "utils/string";

const Popover = withLazyLoading(
  lazy(() => import("elementTypes/common/Popover")),
  true,
);

const useErrorStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
    "& > .MuiAlert-message": {
      whiteSpace: "nowrap",
      overflowX: "scroll",
      scrollbarWidth: "none",
      MsOverflowStyle: "-ms-autohiding-scrollbar",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
}));

const configHints = {
  viewName: "Query Name",
};

const getHintTooltip = (hint: string) => {
  let title: string[] = [];
  for (const key of (hint.split(".") ?? []) as Array<
    keyof typeof configHints
  >) {
    const label: string | undefined =
      configHints[key] ?? humanizeCamelCase(key);
    const isNumberKey = Number(key);

    if (isNaN(isNumberKey) && label) {
      title = [...title, label];
    }
  }
  return title.toString().replace(",", " -> ");
};

export function ElementWithError({
  error,
  elementModel,
}: {
  error: Error | string | ValidationErrorObject;
  elementModel?: IElementModel;
}) {
  const {
    classes: { root },
  } = useErrorStyles();

  let errors: ErrorsReport<keyof typeof configHints>[] = [];
  let message = "This Element contains an error";

  if (typeof error === "string") {
    message = error.toString();
  } else if (typeof error === "object") {
    message = (error as Error).message;
    if ("errors" in (error as ValidationErrorObject)) {
      errors = Object.values(
        (error as ValidationErrorObject)?.errors ?? {},
      ) as ErrorsReport<keyof typeof configHints>[];
    }
  }

  if (DEBUG_MODE_ENABLED) {
    // eslint-disable-next-line no-console
    console.debug(JSON.stringify(error));
  }

  const table = errors.map((err) => {
    const label =
      configHints[err.hintKey] ?? humanizeCamelCase(`${err.hintKey}`);
    let subHint = "";
    const isUnionType =
      isNumber(Number(err.hintKey)) && Number(err.hintKey) > 0;

    if (isUnionType) {
      subHint = " - Union Type";
    }

    return (
      <Tooltip
        key={err.key}
        title={`${getHintTooltip(err.key)}${subHint}`}
        placement="left"
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap={10}
          py={0.5}
          alignItems="center"
          width="100%"
        >
          <Box display="flex" gap={4} width="100%">
            {isUnionType && <MuiIcon icon="merge_type" fontSize="medium" />}
            <Typography>
              {label?.trim() ? label : getHintTooltip(err.key)}
            </Typography>
          </Box>
          <Typography variant="caption">{err.hint}</Typography>
          <StyledTypography
            text={(err.actual as string) ?? "undefined"}
            color="error"
          />
        </Box>
      </Tooltip>
    );
  });

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (ev: MouseEvent<HTMLButtonElement>) =>
    setAnchor(ev.currentTarget);

  const handleClose = () => setAnchor(null);

  return (
    <>
      <Alert
        severity="error"
        className={root}
        aira-labelledby="element-with-error"
        {...(errors?.length && {
          action: (
            <IconButton
              icon="keyboard_arrow_down"
              onClick={handleOpen}
              color="default"
            />
          ),
        })}
      >
        {!!errors && (
          <AlertTitle>{`Error in ${elementModel?.name} - ID: ${elementModel?.id}`}</AlertTitle>
        )}
        <Typography>{message}</Typography>
      </Alert>
      {/* // To enhance performance move to the top level component */}
      <Popover anchorEl={anchor} onClose={handleClose}>
        <Box border="1px solid" borderColor="error.main" px={1}>
          <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={10}>
            <Typography variant="h6">Name</Typography>
            <Typography variant="h6">Expected Value</Typography>
            <Typography variant="h6">Current Value</Typography>
          </Box>
          {table}
        </Box>
      </Popover>
    </>
  );
}
