import { forwardRef, memo } from "react";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import { useStyles } from "../style";

type Props = {
  name: string;
  onChangeState: (value: any) => () => void;
  i18n: {
    title?: string;
    shortDescription?: string;
  };
};

export const ItemState = memo(
  forwardRef<HTMLLIElement, any>(
    ({ name, onChangeState, i18n }: Props, ref) => {
      const {
        classes: { itemClass },
      } = useStyles();
      const desc = i18n.shortDescription;
      const title = i18n.title || name;

      const item = (
        <MenuItem onClick={onChangeState(name)} ref={ref} className={itemClass}>
          {title}
        </MenuItem>
      );

      return desc ? (
        <Tooltip title={desc} placement="right">
          {item}
        </Tooltip>
      ) : (
        item
      );
    },
  ),
);
