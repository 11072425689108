import { ChangeEvent, SyntheticEvent, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { TreeView } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import IconButton from "elementTypes/common/IconButton";
import { useDebouncedState } from "utils/hooks";
import { DefaultQueryInfo } from "../../helpers";
import { EmptyData } from "../components/EmptyData";
import { TablesTreeView } from "../components/EntitiesPanel";
import { PermissionTable } from "./QueryBuilder";
import { useNotAutogeneratedTables } from "./utils";

export const GenerateQueriesComponent = () => {
  return (
    <>
      <EntitiesTree />
      <PermissionTable />
    </>
  );
};

const EntitiesTree = () => {
  const [entitiesSearchValue, setEntitiesSearchValue] = useState("");
  const [cachedSearchValue, cachedHandleSearch] = useDebouncedState(
    entitiesSearchValue,
    setEntitiesSearchValue,
  );

  const [expandedTableList, setExpandedTableList] = useState<string[]>([]);

  const [cachedExpanded, cachedHandleExpanded] = useDebouncedState(
    expandedTableList,
    setExpandedTableList,
  );

  const filteredTables = useNotAutogeneratedTables(cachedSearchValue);

  const handleToggle = (_ev: SyntheticEvent, nodeIds: string[]) =>
    cachedHandleExpanded(nodeIds);

  const toggleExpanded = () =>
    cachedHandleExpanded(
      cachedExpanded?.length ? [] : Object.keys(filteredTables),
    );

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    cachedHandleSearch(ev.target.value ?? "");
    if (!cachedExpanded.length && ev.target.value?.trim()) {
      const schemas = Object.keys(filteredTables);
      cachedHandleExpanded(schemas);
    }
  };

  const tables = (
    <TablesTreeView
      {...{
        tables: filteredTables,
        hideSize: true,
        subKey: "generateModal",
        hideAction: true,
      }}
    />
  );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="end"
        gap={1}
        pb={1}
      >
        <TextField
          label="Schema & Table Search"
          value={cachedSearchValue}
          onChange={handleChange}
          sx={{
            flex: 1,
          }}
        />
        <IconButton
          icon={cachedExpanded.length ? "expand_less" : "expand_more"}
          onClick={toggleExpanded}
          tooltip={cachedExpanded.length ? "Collapse All" : "Expand All"}
          placement="top"
          edge="end"
        />
        <DefaultQueryInfo buttonProps={{ edge: "end" }} />
      </Box>
      {Object.keys(filteredTables).length ? (
        <Box>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={cachedExpanded}
            onNodeToggle={handleToggle}
          >
            {tables}
          </TreeView>
        </Box>
      ) : (
        <EmptyData title="No entities found" />
      )}
    </Box>
  );
};
