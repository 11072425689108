import { Type, types } from "core/runtime-typing";
import {
  IElementModel,
  SelectorTypes,
  TChildren,
  TypeFactory,
  buildCustomExpressionValue,
  getExpression,
} from "core/types";

const collectElements = (
  element: IElementModel,
  elements: IElementModel[],
  updatedElements: Record<string, IElementModel>,
  omit?: string,
) => {
  const updatedElement = updatedElements[element.id] || element;
  if (element.id !== omit) {
    elements.push(updatedElement);
  }
  for (const key of Object.keys(updatedElement.children)) {
    const child = updatedElement.children[key] as TChildren;
    for (const childElement of child.elements || [child.element!]) {
      collectElements(childElement, elements, updatedElements, omit);
    }
  }
};

const getType = (
  typeOrFactory: Type | TypeFactory,
  config: IElementModel["config"],
  state: any,
) =>
  typeof typeOrFactory === "function"
    ? typeOrFactory({ config, state })
    : typeOrFactory;

export const getFlattenElements = (
  element: IElementModel,
  updatedElements: Record<string, IElementModel>,
  omit?: string,
) => {
  const elements: IElementModel[] = [];
  collectElements(element, elements, updatedElements, omit);
  return elements;
};

export const getSelectorTypesType = (
  selectorTypes: SelectorTypes,
  config: Record<string, any>,
  state: any,
  description?: string,
) =>
  types.interface(
    Object.keys(selectorTypes).reduce((acc, k) => {
      let type: Type;
      try {
        type = getType(selectorTypes[k], config, state);
      } catch {
        type = types.any();
      }
      return { ...acc, [k]: type };
    }, {}),
    description,
  );

/**
 * should be used with the `CustomExpressionEditor` `onToggleMode` callback
 *
 * this handler implements simple graceful fallback based on an array of allowed
 * values
 *
 * @example
 * <CustomExpressionEditor
 *   label={label}
 *   value={config.color}
 *   config={config}
 *   onChange={onChange}
 *   nonExpressionEditor={customColorSelect}
 *   onToggleMode={allowedValuesToggleModeHandler(colors)(
 *     config.color,
 *     onChange,
 *   )}
 * />

 */

export function allowedValuesToggleModeHandler(allowedValues: string[]) {
  return (
    /**
     * the actual value
     */
    expressionValue: string | null,
    onChange: (value: string) => void,
    /**
     * used as initial value or if unable to extract valid value
     */
    fallbackValue?: string | null,
  ) => {
    return (wasExpression: boolean) => {
      if (!wasExpression) {
        const newExpression =
          expressionValue || fallbackValue
            ? `"${expressionValue ?? fallbackValue}"`
            : // if the value is falsy, just write it as literal
              fallbackValue + "";
        onChange(buildCustomExpressionValue(newExpression));
      } else {
        if (!expressionValue) {
          if (fallbackValue) {
            onChange(fallbackValue);
          }
        } else {
          const value = getExpression(expressionValue);
          const match = value.match(
            new RegExp(`^"(${allowedValues.join("|")})"`),
          );
          if (match && match.length >= 2) {
            // match[1] is the regex group match
            const color = match[1];
            onChange(color);
          } else if (fallbackValue) {
            onChange(fallbackValue);
          }
        }
      }
    };
  };
}
