import { useCallback } from "react";

import { omit } from "ramda";
import { useSelector } from "react-redux";
import {
  DEFAULT_LANGUAGE_CODE,
  IElementModel,
  IUi,
  Language,
  TElementModelWithPosition,
} from "core";
import { useElementTypesContext } from "core/ElementTypesContext";
import { useElementEditorContext, useObjectViewList } from "core/editor";
import {
  actions as editorActions,
  selectors as editorSelectors,
} from "core/editor/reduxModule";
import { useSessionContext } from "core/session";
import { useGenerateUiElement } from "queries/app/generateElement";
import { usePage, useSession } from "utils/hooks";
import { useElement } from "utils/hooks/useElement";
import { humanize } from "utils/string";

import { UntransformedTableConfig } from "../types";

import {
  ActionsEditor,
  AllowedFilter,
  ColumnEditor,
  CreateButtonEditor,
  DataSourceEditor,
  DefaultRowsPerPage,
  DefaultSort,
  FixedFilter,
  Interval,
  References,
  SelectionEditor,
  SimpleFilter,
} from "./components";

import { IColumn, IGenerateColumn } from "./components/Columns/ColumnEditor";
import { generateColumnName } from "./utils";

export type UpdateChildrenParams = Parameters<
  typeof editorActions.updateElementChildren
>;

export type SelectElementParams = Parameters<
  typeof editorActions.selectElement
>;

export type ExtendedColumnConfig = ReturnType<typeof getHeaderConfig>;

function getHeaderConfig(
  data: Record<string, any>,
  lang: Language,
  order: number,
) {
  return {
    name: data.name,
    i18n: {
      [DEFAULT_LANGUAGE_CODE]: { label: data.label },
      [lang.code]: { label: data.label },
    },
    type: "default_table_header_cell",
    config: {
      dataSource: {
        fieldName: data.fieldName || "",
        sortable: data.sortable,
      },
      align: data.align,
      ...(!(data.width === "auto") && { width: "1px" }),
    },
    position: {
      column: order,
      height: 1,
      width: 1,
      row: 1,
    },
  };
}

export const TableEditor = () => {
  const {
    elementModel,
    elementModel: {
      config: {
        dataSource,
        canSelectRow,
        firstRowSelected,
        defaultSort,
        interval,
        simpleFilter,
        filter,
        hidden,
        hideSimpleFilter,
        createButton,
      },
      children,
      i18n,
    },
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<UntransformedTableConfig>();
  const {
    header: { elements: columns },
    body: { elements: bodyColumns },
  } = children as any;

  const { elementTypes } = useElementTypesContext();

  const { language } = useSessionContext();

  const page = usePage();

  const { updateChildren, createElement, selectElement, updateElement } =
    useElement();

  const { getViewByName } = useObjectViewList();
  const currentView = getViewByName(dataSource.viewName);

  const generateColumn = useCallback(
    (newProps: IGenerateColumn) => {
      const position = newProps.position ?? {
        column: 1,
        row: 1,
        width: 1,
        height: 1,
      };

      // create header column
      const defaultElement = getHeaderConfig(
        {
          ...newProps,
          label: newProps.label ?? humanize(newProps.name),
          fieldName: newProps.fieldName ?? newProps.name,
          sortable: newProps.sortable ?? true,
        },
        language,
        position.column,
      );

      createElement(
        elementTypes,
        elementTypes.default_table_header_cell,
        page!,
        position,
        elementModel,
        defaultElement,
        generateColumnName(newProps.name),
        "header",
        true,
      );

      // create body column
      createElement(
        elementTypes,
        elementTypes[newProps.type],
        page!,
        position,
        elementModel,
        !!Object.keys(newProps.config).length || newProps.i18n
          ? {
              config: newProps.config,
              i18n: newProps.i18n,
            }
          : undefined,
        `table_body_${newProps.name}`,
        "body",
        true,
      );
    },
    [elementTypes, elementModel, language, page, createElement],
  );

  const selected = useSelector(editorSelectors.selected);
  const ui = useSession("ui") as IUi;

  const generateElement = useGenerateUiElement({
    onSuccess: (data) => {
      const updtedElement = {
        ...elementModel,
        children: data.children,
        config: {
          ...elementModel.config,
          ...data.config,
        },
      } as IElementModel;

      const nextSelected = { ...selected!, element: updtedElement };
      updateElement({ [data.id]: updtedElement }, nextSelected, page!);
    },
  });

  const generateDefaultColumns = useCallback(
    (nextViewName?: string) => {
      const nextDataSource = {
        viewName: nextViewName,
      };

      const prevElement = {
        ...(omit(["children"], elementModel) as IElementModel),
        children: {},
        config: {
          ...(elementModel?.config ?? {}),
          dataSource: nextDataSource,
        },
      };
      generateElement.mutate({
        uiName: (ui as IUi).name,
        elementName: "default_table",
        element: prevElement,
      });
    },
    [elementModel, generateElement, ui],
  );

  function deleteColumn(index: number) {
    updateChildren(
      elementModel,
      bodyColumns.filter(
        (_: TElementModelWithPosition, i: number) => i !== index,
      ),
      page!,
      "body",
    );
    updateChildren(
      elementModel,
      columns.filter((_: IColumn, i: number) => i !== index),
      page!,
      "header",
    );
    changeConfigValue("hidden", [
      ...(hidden ?? []).filter(
        (_: unknown, hiddenIndex: number) => hiddenIndex !== index,
      ),
    ]);
  }

  return (
    <>
      <DataSourceEditor generateDefaultColumns={generateDefaultColumns} />
      {!!dataSource.viewName?.length && (
        <>
          <References
            dataSource={dataSource}
            changeConfigValue={changeConfigValue}
          />
          <SelectionEditor
            canSelectRow={canSelectRow}
            firstRowSelected={firstRowSelected}
            changeConfigValue={changeConfigValue}
          />
          <ColumnEditor
            elementModel={elementModel}
            language={language}
            currentView={currentView}
            updateChildren={updateChildren}
            generateColumn={generateColumn}
            changeConfigValue={changeConfigValue}
            generateDefaultColumns={generateDefaultColumns}
            deleteColumn={deleteColumn}
            selectElement={selectElement}
          />
          <DefaultRowsPerPage
            elementModel={elementModel}
            changeConfigValue={changeConfigValue}
          />
          <DefaultSort
            defaultSort={defaultSort}
            viewName={dataSource.viewName}
            changeConfigValue={changeConfigValue}
          />
          <Interval interval={interval} changeConfigValue={changeConfigValue} />
          <SimpleFilter
            hideSimpleFilter={hideSimpleFilter}
            simpleFilter={simpleFilter}
            viewName={dataSource.viewName}
            changeConfigValue={changeConfigValue}
          />
          <FixedFilter
            elementModel={elementModel}
            config={elementModel.config}
            changeConfigValue={changeConfigValue}
          />
          <AllowedFilter
            changeConfigValue={changeConfigValue}
            filter={filter}
            viewName={dataSource.viewName}
          />
          <ActionsEditor elementModel={elementModel} />
          <CreateButtonEditor
            createButton={createButton}
            changeConfigValue={changeConfigValue}
            changeTranslation={changeTranslation}
            i18n={i18n}
          />
        </>
      )}
    </>
  );
};
