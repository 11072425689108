import { FocusEventHandler, memo } from "react";
import { ListItem, ListItemText } from "@mui/material";
import Autocomplete, {
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import matchSorter from "match-sorter";

import { AutocompleteOption, IAutocompleteValue } from "../types";

import { CommonProps } from "./BaseAutocomplete";
import { useListStyles, useStyles } from "./styles";

type SingleAutocompleteProps = {
  value: AutocompleteOption;
  onChange: (value: IAutocompleteValue, reason?: string) => void;
  onBlur?: FocusEventHandler<HTMLElement>;
};

type Props = CommonProps & SingleAutocompleteProps;

export const SingleAutocomplete = memo<Props>(
  ({
    options,
    value,
    searchInputValue,
    disabled,
    isClearable,
    isLoading,
    size,
    autosuggestHighlight,
    virtualizedList,
    Listbox,
    className,
    disablePortal,
    onChange,
    renderInput,
    getOptionLabel,
    highlightedOption,
    customRenderOption,
    groupBy,
    renderGroup,
    onInputChange,
    getOptionDisabled,
    onBlur,
  }) => {
    const {
      classes: { root },
    } = useStyles();
    const handleChange = (
      _: any,
      newValue: AutocompleteOption | null,
      reason: string,
    ) => {
      if (reason === "clear") {
        onInputChange?.("");
        onChange(null, reason);
      } else {
        onChange(newValue?.value ?? "", reason);
      }
    };

    const renderOption = (
      props: any,
      option: any,
      { inputValue }: AutocompleteRenderOptionState,
    ) => (
      <ListItem {...props}>
        <ListItemText
          id={option.id}
          primary={highlightedOption(option, inputValue)}
        />
      </ListItem>
    );

    const inputValue =
      searchInputValue === undefined || searchInputValue === null
        ? value?.label
        : searchInputValue;

    const getOptionSelected = (
      option: AutocompleteOption,
      valueOption: AutocompleteOption,
    ) => option.value === valueOption?.value;

    const filterOptions = (
      selectOptions: AutocompleteOption[],
      { inputValue: inValue }: any,
    ) => {
      const moreValuesOption = selectOptions.find(
        (option) => option.value === Infinity,
      );
      if (moreValuesOption) {
        const opts = selectOptions.filter(
          (option) => option.value !== Infinity,
        );
        return [
          ...matchSorter(opts, inValue, {
            keys: ["label", "value"],
          }),
          moreValuesOption as AutocompleteOption,
        ];
      } else {
        return matchSorter(selectOptions, inValue, {
          keys: ["label", "value"],
        });
      }
    };

    const { classes } = useListStyles();
    const portal = disablePortal ?? false;

    return (
      <Autocomplete
        classes={classes}
        className={`${root} ${className}`}
        options={options}
        disableListWrap={virtualizedList}
        ListboxComponent={virtualizedList ? Listbox : undefined}
        value={value}
        disabled={disabled}
        loading={isLoading}
        size={size}
        disableClearable={!isClearable}
        inputValue={inputValue ?? ""}
        getOptionLabel={getOptionLabel}
        onChange={handleChange as any}
        renderOption={autosuggestHighlight ? renderOption : customRenderOption}
        renderInput={renderInput}
        filterOptions={filterOptions}
        isOptionEqualToValue={getOptionSelected}
        groupBy={groupBy}
        renderGroup={renderGroup}
        getOptionDisabled={getOptionDisabled}
        onBlur={onBlur}
        ListboxProps={{
          "aria-labelledby": "autocomplete-options-popup",
        }}
        disablePortal={portal}
      />
    );
  },
);

SingleAutocomplete.displayName = "SingleAutocomplete";
