import {
  FC,
  MouseEvent,
  ReactNode,
  memo,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Box, ClassNameMap } from "@mui/material";
import { MuiIcon } from "elementTypes/common/MuiIcon";

import {
  IElementModel,
  IElementType,
  TElementModelWithPosition,
} from "../../types";
import { getNearestParentElement } from "../utils";

import { ReduxProps } from "./container";
import { useStyles } from "./style";

/**
 * generates the HTML data attributes that can be used by Cyntia to style the application
 * CN-583
 */
function getPublicElementSelectors(
  elementModel:
    | IElementModel<{}, {}, never>
    | TElementModelWithPosition<{}, {}, never>,
) {
  return {
    "data-cypex-element-id": elementModel.id,
    "data-cypex-element-type": elementModel.type.name,
  };
}

type Props = ReduxProps & {
  elementModel: IElementModel | TElementModelWithPosition;
  elementType: IElementType;
} & {
  children: ReactNode;
};

const EditableWrapper = memo<Omit<Props, "editModeOn">>(
  ({
    selectElement,
    setActiveGrid,
    unselectElement,
    updateCopiedElements,
    selected,
    activeGrid,
    elementModel,
    elementType,
    page,
    updatedElements,
    children,
    shouldHighlightBorders,
  }) => {
    const isGrid = elementType.name === "default_grid";

    const isSelected = Boolean(selected?.element?.id === elementModel.id);
    const isActiveGrid =
      !!activeGrid &&
      (activeGrid?.id === elementModel?.id ||
        elementModel.id.endsWith(`.${activeGrid?.id}`));

    const { classes, cx } = useStyles();

    const isTableColumn = useMemo(
      () =>
        getNearestParentElement(
          page!.element,
          updatedElements,
          elementModel.id,
          "default_table",
        ) !== null,
      [elementModel.id, page, updatedElements],
    );

    const select = () => {
      if (!isSelected) {
        const parent = getNearestParentElement(
          page!.element,
          updatedElements,
          elementModel.id,
          "default_grid",
        );
        if (parent) {
          setActiveGrid(parent, page!);
        }
        selectElement(elementModel, elementType, page!);
      }
    };

    const handleClick = (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.stopPropagation();
      if (isGrid) {
        unselectElement(page!);
        setActiveGrid(elementModel, page!);
      } else {
        select();
      }
    };

    const handleCopyElement = () => updateCopiedElements(elementModel, "add");

    return (
      <div
        className={cx(
          classes.wrapper,
          {
            [classes.topPadding]: isGrid,
            isSelected,
            isGrid,
            isActiveGrid,
            shouldHighlightBorders,
          },
          "editor-element-wrapper",
        )}
        data-element-id={elementModel.id}
      >
        {!isGrid && (
          <ElementLabel
            label={elementModel.id}
            onClick={handleClick}
            classes={classes}
            {...(!isTableColumn && {
              onDoubleClick: handleCopyElement,
            })}
          />
        )}
        <div
          className={"no-drag"}
          style={{ display: "contents" }}
          onClick={handleClick}
          {...getPublicElementSelectors(elementModel)}
        >
          {children}
        </div>
      </div>
    );
  },
);

const ElementLabel = memo<{
  label: string;
  classes: ClassNameMap<string>;
  onClick: (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  onDoubleClick?: () => void;
}>(({ label, classes, onDoubleClick, onClick }) => {
  const [isCopied, setCopied] = useState<boolean>(false);

  const { cx } = useStyles();

  const handleDoubleClick = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => {
    e.stopPropagation();
    if (onDoubleClick) {
      onDoubleClick();
      setCopied(true);
    }
  };

  useLayoutEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => setCopied(false), 5000);
      return () => clearTimeout(timeout);
    }
    return;
  }, [isCopied]);

  return (
    <Box className={cx(classes.label, "editor-label")}>
      <Box display="flex" alignItems="center">
        <div className={"rgl-handle"}>
          <MuiIcon
            icon={"drag_handle"}
            fontSize="large"
            className={cx(classes.handle)}
          />
        </div>

        <Box
          style={{ cursor: "pointer" }}
          textAlign="left"
          onClick={onClick}
          textOverflow="ellipsis"
          overflow="hidden"
          maxWidth="20ch"
          whiteSpace="nowrap"
          onDoubleClick={handleDoubleClick}
        >
          {label}
        </Box>
        {isCopied && <MuiIcon icon="layers" />}
      </Box>
    </Box>
  );
});

const EditableElement: FC<Props> = ({ editModeOn, children, ...rest }) => {
  return !editModeOn ? (
    <div
      style={{ display: "contents" }}
      {...getPublicElementSelectors(rest.elementModel)}
    >
      {children}
    </div>
  ) : (
    <EditableWrapper {...rest}>{children}</EditableWrapper>
  );
};

EditableElement.displayName = "EditableElementWrapper";

export const EditableElementWrapper = memo(EditableElement);
