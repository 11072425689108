import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

const imageVariant = t.keyof({
  stretch: t.null,
  none: t.null,
  xAxis: t.null,
  yAxis: t.null,
  contain: t.null,
});

export const StorageImageConfig = t.intersection([
  t.type({
    path: customExpression(t.union([t.string, t.null])),
  }),
  t.partial({
    variant: imageVariant,
    isWrapped: t.boolean,
    fullSizeOnClick: t.boolean,
    isUsingFileStorage: t.boolean,
  }),
]);

export type StorageImageConfig = t.TypeOf<typeof StorageImageConfig>;

export type ImageVariant = t.TypeOf<typeof imageVariant>;

export type UntransformedStorageImageConfig =
  UntransformedConfig<StorageImageConfig>;

export type StorageImage = IElement<StorageImageConfig>;
