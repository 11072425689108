import { memo } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  PageSelector,
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { IPage } from "core/types";

import { InternalLinkFieldConfig } from "../types";

import { ConfigField } from "./ConfigField";
import { useInternalLinkFieldEditorTranslation } from "./translation";

export const Display = memo(() => {
  const { configTitle } = useEditorTranslation();

  const { isButtonLabel, pageSelectorLabel, labelLabel } =
    useInternalLinkFieldEditorTranslation();
  const {
    elementModel: {
      config,
      config: {
        isButton = false,
        linkTo,
        linkTo: { params },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<InternalLinkFieldConfig>();

  const toggleIsButton = (_ev: any, nextIsButton: boolean) =>
    changeConfigValue("isButton", nextIsButton);

  const handlePageChange = (p: IPage, newParams: any) => {
    changeConfigValue("linkTo", {
      ...linkTo,
      pageId: p.id,
      params: newParams,
    });
  };

  return (
    <>
      <Section title={labelLabel} wrapped={true}>
        <ConfigField name="label" />
      </Section>
      <Section title={configTitle} wrapped={true}>
        <PageSelector
          pageId={(linkTo as any).pageId}
          config={config}
          params={params as any}
          onChange={handlePageChange}
          label={pageSelectorLabel}
        />
      </Section>
      <Section title={"Styling"} wrapped={true}>
        <FormControlLabel
          control={<Switch checked={isButton} onChange={toggleIsButton} />}
          label={isButtonLabel}
        />
      </Section>
    </>
  );
});
