import { MouseEvent, ReactNode, forwardRef, memo } from "react";
import Badge, { BadgeProps } from "@mui/material/Badge";
import Box, { BoxProps } from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fab, { FabProps } from "@mui/material/Fab";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";

import { actions as routerActions } from "core/router/reduxModule";
import Link from "elementTypes/common/Link";
import { ILinkProps } from "elementTypes/common/Link/Link";

import { IMuiIconProps, IconNameType, MuiIcon } from "../MuiIcon";

import { isDefaultSupportedColor } from "./utils";

export type Placement = TooltipProps["placement"];
type Edge = IconButtonProps["edge"];

export type ButtonProps = Omit<IconButtonProps | FabProps, "children">;

export type Props = ButtonProps & {
  icon: IconNameType;
  tooltip?: string;
  filled?: boolean;
  placement?: Placement;
  edge?: Edge;
  badgeProps?: Partial<BadgeProps>;
  fontSize?: IMuiIconProps["fontSize"];
  processing?: boolean;
  href?: string;
  boxProps?: BoxProps;
  rel?: string;
  external?: boolean;
  target?: ILinkProps["target"];
};

const spinner = <CircularProgress size={24} color="inherit" />;

const Button = memo<Props>(
  ({
    onClick,
    icon,
    tooltip,
    filled,
    placement,
    badgeProps,
    fontSize,
    processing,
    color,
    href,
    boxProps,
    external,
    target,
    ...rest
  }) => {
    const dispatch = useDispatch();
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (href && !external) {
        event.preventDefault();

        dispatch(routerActions.push(href));
      }
    };

    const canHandleColor = isDefaultSupportedColor(color);

    const btnIcon = badgeProps ? (
      <Badge {...badgeProps}>
        <MuiIcon icon={icon} fontSize={fontSize} color="inherit" />
      </Badge>
    ) : (
      <MuiIcon icon={icon} fontSize={fontSize} color="inherit" />
    );

    const restWithColor = {
      ...rest,
      ...(canHandleColor && {
        color,
      }),
    };

    const btn: ReactNode = filled ? (
      <Fab onClick={handleClick} {...(restWithColor as FabProps)} href={href}>
        {processing ? spinner : btnIcon}
      </Fab>
    ) : (
      <IconButton onClick={handleClick} {...(restWithColor as IconButtonProps)}>
        {processing ? spinner : btnIcon}
      </IconButton>
    );

    const colorBtn = canHandleColor ? btn : <Box color={color}>{btn}</Box>;

    const maybeLink: ReactNode =
      href && !restWithColor.disabled ? (
        <Link
          href={!restWithColor.disabled ? href : undefined}
          underline="none"
          color="inherit"
          target={target}
        >
          {colorBtn}
        </Link>
      ) : (
        colorBtn
      );
    const wrappedButton = restWithColor.disabled ? (
      <span>{maybeLink}</span>
    ) : (
      maybeLink
    );

    return tooltip ? (
      <Tooltip title={tooltip} placement={placement || "left"}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          {...(boxProps ?? {})}
        >
          {wrappedButton}
        </Box>
      </Tooltip>
    ) : (
      wrappedButton
    );
  },
);

const ButtonComponent = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <Button ref={props.ref ?? ref} {...props} />
));

export default ButtonComponent;
