import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
  customExpression,
} from "core/types";
import { ResponseTypeConfig } from "services/api/types/ResponseType";

// https://leafletjs.com/reference.html#path
const PathOptions = t.partial({
  color: t.string,
});

export type TPathOptions = t.TypeOf<typeof PathOptions>;

export const markerLabelVariant = t.keyof({
  icon: t.null,
  text: t.null,
});

const GeoJSONSelected = t.intersection([
  t.type({
    property: t.string,
    propertyValue: customExpression(t.union([t.number, t.string])),
  }),
  t.partial({
    pathColor: PathOptions,
    markerColor: t.string,
    markerLabel: t.string,
    tooltip: t.union([t.string, customExpression(t.string)]),
  }),
]);

export type GeoJSONSelected = t.TypeOf<typeof GeoJSONSelected>;

export const GeoJSONFieldConfig = t.intersection([
  t.partial({
    //independent map element
    dataSource: t.union([
      t.null,
      t.partial({
        viewName: t.string,
        responseFormat: ResponseTypeConfig,
      }),
    ]),
    tileLayerUrl: t.union([t.null, t.string]),
    updateMapView: t.boolean,
    styleFunction: t.union([PathOptions, customExpression(t.Function)]),
    tooltipFunction: t.union([
      t.string,
      customExpression(t.union([t.string, t.Function])),
    ]),
    markerDisplayTextFunction: t.union([
      t.string,
      customExpression(t.Function),
    ]),
    markerLabelVariant,
    markerBackgroundColorFunction: t.union([
      t.string,
      customExpression(t.Function),
    ]),
    latitude: t.number,
    longitude: t.number,
    zoom: t.number,
    selected: t.union([t.null, GeoJSONSelected]),
  }),
  t.type({
    value: customExpression(t.union([t.null, t.UnknownRecord, t.UnknownArray])),
  }),
]);

const featureTyping = types.interface({
  geometry: types.anyRecord(),
  //   {
  //     "type": "Point",
  //     "coordinates": [
  //         7.4474,
  //         46.948
  //     ]
  // }
  properties: types.anyRecord(),
  // data row
});

export const styleFunctionTyping = {
  feature: featureTyping,
};

export const tooltipFunctionTyping = {
  feature: featureTyping,
};

export const markerDisplayTextFunctionTyping = {
  feature: featureTyping,
};

export const markerBackgroundColorFunctionTyping = {
  feature: featureTyping,
};

export type GeoJSONFieldConfig = t.TypeOf<typeof GeoJSONFieldConfig>;

export type UntransformedGeoJSONFieldConfig =
  UntransformedConfig<GeoJSONFieldConfig>;

export const GeoJSONFieldTranslationKeys = [] as const;

export type GeoJSONFieldTranslationKeys =
  (typeof GeoJSONFieldTranslationKeys)[number];

export type GeoJSONField = IElement<
  GeoJSONFieldConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  GeoJSONFieldTranslationKeys
>;
