import AdminService from "services/admin";

import { AuditTable } from "../../staticPages/admin/pages/audits/pages/tables/types";
import { Query, useAuthenticatedQuery } from "../utils";

import { QueryKeys } from "./queryKeys";

const services = AdminService.getInstance();

type Params = { schemaName: string; tableName: string };

type AuditList = { schema: string; table: string };

export const fetchTableAudit: Query<AuditTable[], Params> = {
  queryKey: QueryKeys.fetchTableAudit,
  queryFn: services.getTableAudit,
};

export const fetchAuditTableList: Query<AuditList[]> = {
  queryKey: QueryKeys.fetchAuditTableList,
  queryFn: services.getAuditTableList,
};

export const useTableAudit = (params: Params) =>
  useAuthenticatedQuery(fetchTableAudit, params);

export const useAuditTableList = () =>
  useAuthenticatedQuery(fetchAuditTableList);
