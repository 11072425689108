import request from "superagent";
import { Definition, IUiSavePoint, Translation } from "core/types";
import { RepositoryConfiguration } from "queries/admin/types";
import { FILE_STORAGE_PREFIX } from "services/api/constants";
import { UICreateAPIData } from "staticPages/admin/pages/apps/pages/create/types";
import { UiEditParams } from "staticPages/admin/pages/apps/pages/edit/types";
import { UiGenerate } from "staticPages/admin/pages/apps/pages/generate/types";
import {
  FileForm,
  TFileUpdate,
} from "staticPages/admin/pages/files/pages/upload/types";
import { LDAPData } from "staticPages/admin/pages/ldap/types";
import {
  DeleteQueryParams,
  QueryObject,
} from "staticPages/admin/pages/modelBuilder/components/types";
import {
  HistoryTrackingSwitchParams,
  WorkflowSetupParams,
} from "staticPages/admin/pages/modelBuilder/erd/types";
import {
  CreateStateParams,
  CreateStateTransitionParams,
  DeleteStateParams,
  DeleteStateTransitionParams,
  UpdateStateParams,
  UpdateStateTransitionParams,
  WorkflowActivationParams,
} from "staticPages/admin/pages/modelBuilder/workflow/types";
import { SettingsConfig } from "staticPages/admin/pages/settings/types";
import { UserForm } from "staticPages/admin/pages/users/pages/create/types";
import { EditUserForm } from "staticPages/admin/pages/users/pages/edit/types";
import { getResponseError, withAuthHeader, withCommonHeaders } from "utils/api";

const LDAP_CONFIG_URL = "/app/auth/config/ldap";
export default class AdminService {
  private static _instance: AdminService;

  public static getInstance(): AdminService {
    if (!AdminService._instance) {
      AdminService._instance = new AdminService();
    }

    return AdminService._instance;
  }

  public async getEdition(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/admin/edition"),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllUi(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/ui/admin/list"),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async generateUi(token: string, uiData: UiGenerate) {
    try {
      const data = {
        ...uiData,
        objectViews: uiData.objectViews.map((v) => ({ id: v })),
      };

      const { body } = await withCommonHeaders(
        request.post("/app/ui/generate").send(data),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createUi(token: string, uiData: UICreateAPIData) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/ui").send(uiData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async restoreUi(
    uiName: string,
    savePointId: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request
          .post(`/app/ui/${uiName}/save/${savePointId}/restore`)
          .send({ uiName, savePointId }),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveUi(
    name: string,
    definition: Definition,
    description: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/ui/${name}/save`).send({ definition, description }),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editUi(
    token: string,
    { name, editData }: UiEditParams,
  ): Promise<IUiSavePoint & { newPagesNumber: number }> {
    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/ui/${name}`).send(editData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async releaseUi(
    uiName: string,
    data: { name: string; description: string },
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request.post(`/app/ui/${uiName}/release`).send(data),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteUI(token: string, { uiName }: { uiName: string }) {
    try {
      const res = await withCommonHeaders(
        request.delete(`/app/ui/${uiName}`),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async publishReleaseUi(
    uiName: string,
    releaseName: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request.post(`/app/ui/${uiName}/release/${releaseName}/publish`),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllFiles(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/file"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async uploadFile(token: string, fileData: FileForm) {
    try {
      let req = request.post("/app/storage/file");

      req = withAuthHeader(req, token);

      const { body } = await req
        .field("groupName", fileData.groupName)
        .field("typeGroupName", fileData.typeGroupName)
        .field("acl[]", fileData.acl ?? [])
        .field("realName", fileData.realName ?? "")
        .attach("file", fileData.file as never);

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteFile(token: string, { fileName }: { fileName: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`${FILE_STORAGE_PREFIX}${fileName}`),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFileGroups(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/groups"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFileTypes(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/types"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFilesByGroupType(
    token: string,
    { groupType }: { groupType: string },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/storage/groups/${groupType}/files`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateFile(token: string, { fileName, data }: TFileUpdate) {
    try {
      const { body } = await withCommonHeaders(
        request.patch(`${FILE_STORAGE_PREFIX}${fileName}`).send(data),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFunctionsName(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/ui/functions/list"),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllRoles(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/roles"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteRole(token: string, { roleName }: { roleName: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/auth/role/${roleName}`),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createUserRole(token: string, params: { name: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/auth/role/${params.name}`).send(params),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getTableAudit(
    token: string,
    query: { schemaName: string; tableName: string },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/table").query({ ...query }),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAuditTableList(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/table/list"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getUserAudit(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/user-events"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getUser(token: string, { userId }: { userId: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/users/integrated/${userId}`),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getDataApiOpenApi(token: string) {
    try {
      const { body } = await withCommonHeaders(request.get("/view/"), token);
      const data: Record<string, unknown> = body;

      if (!data) {
        return null;
      }

      data.host = window.location.host;
      data.paths = Object.fromEntries(
        Object.entries(data.paths as Record<string, Object>).map(
          ([key, value]) => [`/view${key}`, value],
        ),
      );
      // remove PostgREST information
      delete data.schemes;
      delete data.info;
      delete data.externalDocs;

      return data;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllUsers(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/users/integrated"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveUser(token: string, user: UserForm) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/users/integrated").send(user),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editUser(
    token: string,
    params: { userId: string; user: EditUserForm },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/users/integrated/${params.userId}`).send(params.user),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteUser(token: string, { userId }: { userId: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/users/integrated/${userId}`).send(),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editLoginConfig(token: string, config: SettingsConfig) {
    try {
      const { body } = await withCommonHeaders(
        request.patch("/app/auth/config/login").send(config),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getLDAPConfig(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(LDAP_CONFIG_URL),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteLDAPConfig(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(LDAP_CONFIG_URL),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveLDAPConfig(token: string, ldapData: LDAPData) {
    try {
      const { body } = await withCommonHeaders(
        request.put(LDAP_CONFIG_URL).send(ldapData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getQueries(
    token: string,
    params?: { role: string },
  ): Promise<QueryObject[]> {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/admin/model/queries").query(params ?? {}),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getModel(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/admin/model"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getColumnValues(
    token: string,
    params: {
      schema: string;
      table: string;
      column: string;
    },
  ) {
    const { schema, table, column } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(
          `/app/admin/model/${schema}/${table}/workflow/column/${column}`,
        ),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getStateWorkflow(
    token: string,
    params: {
      schema: string;
      table: string;
    },
  ) {
    const { schema, table } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/${schema}/${table}/workflow/graph`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getStateNode(
    token: string,
    params: {
      id: number;
    },
  ) {
    const { id } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/workflow/state/${id}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createDefaultQuery(
    token: string,
    params: {
      schema: string;
      table: string;
      data: {
        viewName: string;
        permissions: { grantee: string; privileges: string[] }[];
        i18n: Translation<keyof any>;
      };
    },
  ) {
    const { schema, table, data } = params;
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/admin/model/query/${schema}/${table}`).send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createDefaultQueries(
    token: string,
    params: {
      tables: Record<string, string[]>;
      permissions: { grantee: string; privileges: string[] }[];
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/admin/model/query/all`).send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getStateTransition(
    token: string,
    params: {
      transitionId: string | undefined;
    },
  ) {
    const { transitionId } = params;
    if (!transitionId) {
      return;
    }
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/workflow/transition/${transitionId}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createCustomQuery(
    token: string,
    data: {
      viewName: string;
      code: string;
      permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<keyof any>;
      identifyingColumn: string | null;
      queryGroupId: number | null;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/model/query/custom").send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editCustomQuery(
    token: string,
    data: {
      viewName: string;
      code: string;
      permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<"title">;
      identifyingColumn: string | null;
      queryGroupId: number | null;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.put("/app/admin/model/query/custom").send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editDefaultQuery(
    token: string,
    data: {
      viewName: string;
      permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<"title">;
      queryGroupId: number | null;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.put("/app/admin/model/query/default").send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createStateTransition(
    token: string,
    stateTransitionParams: CreateStateTransitionParams,
  ) {
    const { schema, table, stateTransitionData } = stateTransitionParams;
    try {
      const { body } = await withCommonHeaders(
        request
          .post(`/app/admin/model/${schema}/${table}/workflow/transition`)
          .send(stateTransitionData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createState(token: string, params: CreateStateParams) {
    const { schema, table, stateData } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .post(`/app/admin/model/${schema}/${table}/workflow/state`)
          .send(stateData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateState(token: string, params: UpdateStateParams) {
    const { id, stateData } = params;

    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/admin/model/workflow/state/${id}`).send(stateData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteState(token: string, params: DeleteStateParams) {
    const { id } = params;
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/workflow/state/${id}`).send(),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateStateTransition(
    token: string,
    params: UpdateStateTransitionParams,
  ) {
    const { transitionId, schema, table, stateTransitionData } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(
            `/app/admin/model/${schema}/${table}/workflow/transition/${transitionId}`,
          )
          .send(stateTransitionData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async workflowSetup(token: string, params: WorkflowSetupParams) {
    const { schema, table, workflowSetupAPIData } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .post(`/app/admin/model/${schema}/${table}/workflow`)
          .send(workflowSetupAPIData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async workflowActivationSwitch(
    token: string,
    params: WorkflowActivationParams,
  ) {
    const { schema, table, active } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(`/app/admin/model/${schema}/${table}/workflow/toggle`)
          .send({ active }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async setColumAlias(
    token: string,
    params: {
      schema: string;
      table: string;
      column: string;
    },
  ) {
    const { schema, table, column } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(`/app/admin/model/${schema}/${table}/lookup`)
          .send({ column }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteStateTransition(
    token: string,
    params: DeleteStateTransitionParams,
  ) {
    const { id } = params;
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/workflow/transition/${id}`).send(),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getGeneratedQuery(token: string, params: { viewName: string }) {
    const { viewName } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/query/${viewName}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async testCustomQuery(
    token: string,
    params: {
      data: {
        viewName: string;
        code: string;
      };
    },
  ) {
    const { data } = params;
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/model/query/temp").send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async historyTrackingSwitch(
    token: string,
    params: HistoryTrackingSwitchParams,
  ) {
    const { schema, table, active } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(`/app/admin/model/${schema}/${table}/historyTracker/toggle`)
          .send({ active }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getDataPreview(
    token: string,
    params: {
      data: {
        viewName: string;
        code: string;
      };
    },
  ) {
    const { data } = params;
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/model/query/preview").send(data),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteQuery(token: string, params: DeleteQueryParams) {
    const { name } = params;
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/query/${name}`).send(),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteQueries(token: string, params: { viewNames: string[] }) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/queries`).send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteWorkflow(
    token: string,
    params: {
      schema: string;
      table: string;
    },
  ) {
    const { schema, table } = params;
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/${schema}/${table}/workflow`).send(),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getExtensions(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/extensions`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async installExtension(
    token: string,
    params: {
      name: string;
      version?: string;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/install_extension").send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async uninstallExtension(
    token: string,
    params: {
      name: string;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/uninstall_extension").send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async installApplication(
    token: string,
    params: {
      repositoryId: number;
      name: string;
      version: string;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/install_application").send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async uninstallApplication(
    token: string,
    params: {
      repositoryId: number;
      name: string;
      version?: string;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/uninstall_application").send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getRepositoryConfigurations(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/repositories`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getRepositoryConfiguration(
    token: string,
    params: { id: string },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/repository/${params.id}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateRepositoryConfiguration(
    token: string,
    params: RepositoryConfiguration,
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/admin/repositories`).send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async testRepositoryConfiguration(
    token: string,
    params: RepositoryConfiguration,
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/admin/repository/test`).send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getRepositoryBranches(
    token: string,
    params: Pick<
      RepositoryConfiguration,
      "repositoryPath" | "repositoryBaseUrl" | "personalAccessToken"
    >,
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/admin/repository/branches`).send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteRepositoryConfiguration(
    token: string,
    params: { id: number },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/repositories`).send(params),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getRepositoriesContent(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/repositories/content`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getRepositoryResourceContent(
    token: string,
    params: {
      repositoryId: number;
      resourcePath: string;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request
          .get(`/app/admin/repositories_resource_content`)
          .query({ ...params }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getQueryGroups(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/query-groups`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createQueryGroup(
    token: string,
    params: { i18n: Translation<"label"> },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/admin/model/group/`).send({ ...params }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateQueryGroup(
    token: string,
    { id, i18n }: { id: number; i18n: Translation<"label"> },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.patch(`/app/admin/model/group/${id}`).send({ i18n }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteQueryGroup(token: string, params: { id: number }) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/group/${params.id}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getInternalModel(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/internal`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateQueryWithQueryGroup(
    token: string,
    { id, queryGroupId }: { id: string; queryGroupId: number | null },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request
          .patch(`/app/admin/model/query/${id}/assign-group`)
          .send({ queryGroupId }),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }
}
