import { ChangeEvent, memo, useEffect } from "react";
import {
  ButtonGroup,
  CircularProgress,
  Button as MuiButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ConnectedReduxModuleProps } from "core";
import Button from "elementTypes/common/Button";
import { FILE_STORAGE_PREFIX } from "services/api/constants";

import { MuiIcon } from "../common/MuiIcon";
import { ASTERISK_SYMBOL } from "../common/utils";

import { ReduxModule } from "./reduxModule";
import { useStyles } from "./styles";
import { useFileInputTranslation } from "./translation";
import { FileInput } from "./types";

const StorageFileInput = memo<
  ConnectedReduxModuleProps<ReduxModule, FileInput>
>(
  ({
    value,
    element: {
      id,
      i18n,
      config: { accept, fileName },
    },
    upload,
    clear,
    fetchMetadata,
    metadata,
    loading,
    metadataError,
    required,
    disabled,
  }) => {
    const { classes } = useStyles();
    const t = useFileInputTranslation();

    const metadataName = metadata?.fileName;

    useEffect(() => {
      // if the value changed from outside, retrieve metadata
      if (!loading && metadataName !== value && !!value && !metadataError) {
        fetchMetadata();
      }
    }, [fetchMetadata, loading, metadataName, value, metadataError, fileName]);

    useEffect(() => {
      if (value === null && !!metadataName) {
        clear();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.item(0);
      if (file) {
        upload(file);
      }
      e.target.value = "";
    };

    const deleteButton =
      !required &&
      (value ? (
        <Button tooltip={t.delete} onClick={clear} style={{ flex: 0 }}>
          <MuiIcon icon="delete_outline" />
        </Button>
      ) : (
        <Button onClick={clear} style={{ flex: 0 }}>
          <MuiIcon icon="delete_outline" color="disabled" />
        </Button>
      ));

    const linkOrError =
      (loading && (
        <Button>
          <CircularProgress size="1em" />
        </Button>
      )) ||
      (metadata && (
        <Tooltip title={`${t.view} ${fileName ?? metadata.realName}`}>
          <MuiButton
            href={`${FILE_STORAGE_PREFIX}${metadata.fileName}`}
            target="_blank"
          >
            <Typography noWrap>{fileName ?? metadata.realName}</Typography>
          </MuiButton>
        </Tooltip>
      )) ||
      (metadataError && (
        <Button
          tooltip={t.reload}
          color="error"
          label={metadataError}
          onClick={fetchMetadata}
        />
      ));

    return (
      <>
        <ButtonGroup disabled={disabled} fullWidth>
          <Tooltip title={t.upload}>
            <MuiButton
              component="label"
              htmlFor={id}
              startIcon={<MuiIcon icon={value ? "check" : "cloud_upload"} />}
              style={{ flex: linkOrError ? 0 : 1 }}
            >
              {i18n.label}
              {required && ASTERISK_SYMBOL}
            </MuiButton>
          </Tooltip>
          {linkOrError}
          {deleteButton}
        </ButtonGroup>
        <input
          type="file"
          accept={accept}
          className={classes.input}
          id={id}
          onChange={onFileUpload}
        />
      </>
    );
  },
);

StorageFileInput.displayName = "StorageFileInput";

export default StorageFileInput;
