import defaultsDeep from "lodash/defaultsDeep";
import {
  all,
  call,
  delay,
  getContext,
  put,
  race,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import { ILifecycleTypes } from "core";
import { AllServices } from "core/buildStore";
import { editorTranslation } from "core/editor";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation";
import { getServerError } from "core/utils/api";
import { createWatcherSaga } from "core/utils/saga";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";

import { replaceSubstringAny } from "utils/string";
import {
  FilterGroupCombinator,
  IFilterGroup,
  IFilterRule,
} from "../toolsPanel";
import { tableTranslation } from "../translation";
import { Table, TableMetadata } from "../types";

import { Actions, IOrder, ITableParams, Selectors, Types } from "./types";
import {
  buildCsFilter,
  buildEqFilter,
  buildFixedFilterFromConfig,
  buildILikeFilter,
  mapParamsToApiServiceParams,
} from "./utils";

const filterElementsFlter = (filter: IFilterGroup) => ({
  ...filter,
  filters: filter?.filters?.reduce(
    (res, f) =>
      f && "value" in f && f.value // TODO: improve bool_input to set true/false/undefined, e.g undefined is defaukt valye
        ? [...res, f]
        : res,
    [] as IFilterGroup["filters"],
  ),
});

export function buildSaga(
  element: Table,
  lifecycleTypes: ILifecycleTypes,
  actions: Actions,
  types: Types,
  selectors: Selectors,
) {
  const {
    fixedFilter,
    dataSource,
    simpleFilter,
    fullTextSearch,
    canSelectRow,
    firstRowSelected,
    elementsFilter,
    defaultRowsPerPage,
    hideSimpleFilter,
  } = element.config;
  let fixedFilterSelector:
    | ((state: any) => IFilterGroup | IFilterRule | null)
    | null;

  if (fixedFilter) {
    /**
     * If the fixed filter is a function, it means it is a selector, in which case
     * we must watch for changes to the fixed filter value that may come from
     * outside the table element.
     */
    if (typeof fixedFilter === "function") {
      fixedFilterSelector = (state: any) =>
        buildFixedFilterFromConfig(fixedFilter(state));
    } else {
      const builtFixedFilter = buildFixedFilterFromConfig(fixedFilter);
      fixedFilterSelector = () => builtFixedFilter;
    }
  } else {
    fixedFilterSelector = null;
  }

  let firstLoad = true;

  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const params: ITableParams = yield select(selectors.params);
    const loadingParams: Partial<ITableParams> | null = yield select(
      selectors.loadingParams,
    );
    let metadata: TableMetadata = yield select(selectors.metadata);
    let nextParams = {
      ...params,
      limit: params.limit ?? defaultRowsPerPage ?? 10,
      ...loadingParams,
    };

    /**
     * If no order is set, determine a default
     */
    if (!nextParams.order?.length) {
      const {
        config: { defaultSort },
      } = element;

      let order: IOrder[] = [];
      if (defaultSort?.length) {
        order = defaultSort;
      } else {
        if (dataSource.identifierName?.length) {
          // no default found, use identifierName
          order = yield getNextOrder(dataSource.identifierName);
        } else {
          // no identifierName, use the first header cell
          const headerCell = element.children.header.elements.find(
            (el) =>
              el.type.name === "default_table_header_cell" &&
              el.config.dataSource?.sortable &&
              !!el.config.dataSource?.fieldName.length,
          ) as TableHeaderCell | undefined;
          // there may not be a header cell at all
          if (headerCell) {
            order = yield getNextOrder(headerCell.config.dataSource!.fieldName);
          } else {
            // no header cell found; do not set a default order
          }
        }
      }

      nextParams = {
        ...nextParams,
        order,
      };
    }
    /**
     * If there is a fixed filter, add it to the current filter with an `AND` combinator.
     */

    /**
     * Be aware, that only one type of filter will be applied once changes have been saved.
     * Please, make sure that the table is filtered whether by fixed or element's value.
     */

    let nextParamsWithFixedFilter = nextParams;
    let fixedFilterValue: IFilterRule | IFilterGroup | null;

    if (fixedFilterSelector) {
      try {
        fixedFilterValue = yield select(fixedFilterSelector);
        if (elementsFilter?.length) {
          fixedFilterValue = filterElementsFlter(
            fixedFilterValue as IFilterGroup,
          );
        }

        nextParamsWithFixedFilter = {
          ...nextParams,
          filter: {
            combinator: FilterGroupCombinator.AND,
            filters: [nextParams.filter, fixedFilterValue],
          },
        };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Fixed filter is invalid", e);
      }
    }

    if (simpleFilter && !hideSimpleFilter) {
      nextParamsWithFixedFilter = yield getNextParamsWithSimpleFilter(
        nextParamsWithFixedFilter,
      );
    }

    // We add an extra row to know if there are more pages available or not.
    const nextParamsWithExtraRow = {
      ...nextParamsWithFixedFilter,
      limit: nextParamsWithFixedFilter.limit + 1,
    };

    if (!dataSource.viewName?.length) {
      // Inside editor mode if element has just been created viewName can be empty
      // set error to warn user of viewName necessity
      const msg: string = yield call(
        getTranslatedTextSaga,
        editorTranslation,
        "viewNameError",
      );
      yield put(actions.loadError(msg, nextParams));
      return;
    }

    try {
      const apiServiceParams = mapParamsToApiServiceParams(
        nextParamsWithExtraRow,
        fullTextSearch,
      );
      const data: any[] = yield call(services.api.loadViewData, token, {
        viewName: dataSource.viewName,
        params: apiServiceParams,
      });

      // Get rid of the extra row used to detect more pages.
      const exposedData = data.slice(0, nextParams.limit);

      const { stateColumnName, identifierName, references } = dataSource;
      const nextPageAvailable = data.length === nextParamsWithExtraRow.limit;

      let dataReferences: Record<string, Record<string, unknown>[]> | null =
        null;

      // Get referenced data
      if (
        references &&
        !!Object.keys(references).length &&
        !!exposedData.length
      ) {
        for (const reference in references) {
          const { viewName: targetView, identifierName: referencedColumnName } =
            references[reference];

          const _values = [
            ...new Set(
              exposedData.reduce((res, row) => {
                const v = row[reference];
                return v !== null && v !== undefined ? [...res, v] : res;
              }, []),
            ),
          ];

          if (_values.length > 0) {
            const values = (
              Array.isArray(_values[0])
                ? [...new Set(_values.flat(1))]
                : _values
            ) as string[];
            try {
              const referencedData: any[] = yield getReferencedData(
                targetView,
                referencedColumnName,
                values,
              );
              dataReferences = {
                ...(dataReferences ?? {}),
                [reference]: referencedData,
              } as Record<"string", any[]>;
            } catch (e) {
              // TODO what should happen when reference data load fails?
              // eslint-disable-next-line no-console
              console.error(e);
            }
          } else {
            // make sure the reference is still set to empty data
            dataReferences = { ...(dataReferences ?? {}), [reference]: [] };
          }
        }
      }

      if (identifierName) {
        const stateNames = stateColumnName
          ? Array.from(new Set(exposedData.map((row) => row[stateColumnName])))
          : undefined;
        metadata = {
          ...(yield getMetadata(dataSource.viewName, stateNames)),
          stateColumnName,
        };
      }

      yield put(
        actions.loadSuccess(
          exposedData,
          nextParams,
          nextPageAvailable,
          metadata,
          dataReferences,
        ),
      );

      if (canSelectRow && firstRowSelected && exposedData.length) {
        const selected: ReturnType<typeof selectors.selected> = yield select(
          selectors.selected,
        );

        // only reset if no row of the current data is already selected
        // e.g. to not reset selected row when clicking table "reload" button
        if (
          !(
            identifierName &&
            exposedData.find(
              (row) => row[identifierName] === selected.identifier,
            )
          )
        ) {
          const selectedRow = exposedData[0];
          yield put(
            actions.selectRow(
              identifierName ? selectedRow[identifierName] : 0,
              selectedRow,
            ),
          );
        }
      }
    } catch (error) {
      const msg = replaceSubstringAny(
        getServerError(error),
        /relation "cypex_generated./,
        'relation "',
      );
      yield put(actions.loadError(msg, nextParams));
    }

    if (firstLoad) {
      firstLoad = false;
    }
  }

  function* getReferencedData(
    objectViewName: string,
    referencedColumnName: string,
    values: string[],
  ) {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);

    const filter = {
      offset: 0,
      order: null,
      limit: values.length,
      [referencedColumnName]: `in.(${values.toString()})`,
    };

    try {
      const res: Record<string, unknown>[] = yield call(
        services.api.loadViewData,
        token,
        {
          viewName: objectViewName,
          params: filter,
        },
      );
      return res;
    } catch (error) {
      const msg = replaceSubstringAny(
        getServerError(error),
        /relation "cypex_generated./,
        'relation "',
      );
      yield put(
        actions.enqueueSnackbar({
          message: msg,
          options: {
            variant: "error",
          },
        }),
      );
      return null;
    }
  }

  function* getMetadata(objectViewName: string, stateNames?: string[]) {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    try {
      const res: Record<string, unknown>[] = yield call(
        services.api.getViewMetadata,
        { token },
        {
          objectViewName,
          stateNames,
        },
      );
      return res;
    } catch (error) {
      const msg = replaceSubstringAny(
        getServerError(error),
        /relation "cypex_generated./,
        'relation "',
      );
      yield put(
        actions.enqueueSnackbar({
          message: msg,
          options: {
            variant: "error",
          },
        }),
      );
      const data: TableMetadata = {
        canUpdate: false,
        canDelete: false,
        rows: {},
      };
      return data;
    }
  }

  function* getNextParamsWithSimpleFilter(
    nextParams: Partial<ITableParams> | undefined = {},
  ) {
    let searchInputValue: string = yield select(selectors.searchInputValue);
    const getField = (f: IFilterRule | IFilterGroup | null) =>
      f !== null &&
      "field" in f &&
      simpleFilter?.some((filter) => f.field.name === filter.name) &&
      f.hidden;

    const currentSimpleFilter = nextParams?.filter?.filters.find((f) =>
      getField(f),
    ) as IFilterRule | undefined;
    const simpleFilterValue = currentSimpleFilter?.value?.length
      ? currentSimpleFilter.value.replace(/\*/g, "")
      : "";

    /**
     * set `searchInputValue` from filter params if page has been updated,
     * on first load only
     */
    if (
      firstLoad &&
      !searchInputValue.trim().length &&
      simpleFilterValue !== searchInputValue.trim()
    ) {
      yield put(actions.changeSearchValue(simpleFilterValue));
      searchInputValue = simpleFilterValue;
    }
    const textValue = `${fullTextSearch ? "*" : ""}${searchInputValue.trim()}*`;
    const trimedValue = `${searchInputValue.trim()}`;

    const filterParams =
      nextParams?.filter?.filters.filter((f) => !getField(f)) ?? [];

    /**
     * update `nextParams`:
     * add a `simpleFilter` if `searchInputValue` exists
     * or remove the `simpleFilter` if no `searchInputValue`
     */

    const filter: { filters?: any; combinator?: string } = {};

    if (searchInputValue && !!searchInputValue.trim().length && simpleFilter) {
      filter.filters = [
        ...filterParams,
        ...[
          {
            filters: simpleFilter.flatMap((sFilter) =>
              sFilter.type === "text"
                ? sFilter.isArray
                  ? buildCsFilter(sFilter.name, trimedValue)
                  : buildILikeFilter(sFilter.name, textValue, true)
                : !isNaN(+trimedValue)
                  ? sFilter.isArray
                    ? buildCsFilter(sFilter.name, trimedValue)
                    : buildEqFilter(sFilter.name, trimedValue)
                  : undefined,
            ),
            type: "group",
            combinator: FilterGroupCombinator.OR,
          },
        ],
      ];
    } else {
      filter.filters = filterParams;
    }

    const nextParamsWithSimpleFilter = {
      ...nextParams,
      filter: {
        ...nextParams.filter,
        ...filter,
      },
    };

    return nextParamsWithSimpleFilter;
  }

  function* filterChangeSaga(action: ReturnType<Actions["changeFilter"]>) {
    const { nextFilter } = action.payload;
    yield put(
      actions.load({
        filter: nextFilter,
      }),
    );
  }

  function* orderChangeSaga(action: ReturnType<Actions["changeOrder"]>) {
    const { fieldName, multi } = action.payload;
    const nextOrder: IOrder[] | null = yield getNextOrder(fieldName, multi);

    const nextParams = {
      order: nextOrder,
      offset: 0,
    };
    yield put(actions.load(nextParams));
  }

  function* getNextOrder(fieldName: string, multi?: boolean) {
    const { order } = (yield select(selectors.params)) as { order: IOrder[] };

    let nextOrder: IOrder[];
    const newField = {
      fieldName,
      asc: true,
      hidden: false,
    };

    if (order && !!order.length) {
      const currentOrderIndex = order.findIndex(
        (o) => fieldName === o.fieldName,
      );
      if (currentOrderIndex === -1) {
        nextOrder = multi
          ? [...order, newField]
          : [newField, ...order.map((o) => ({ ...o, hidden: true }))];
      } else {
        const current = order[currentOrderIndex];
        if (multi) {
          if (current.hidden) {
            nextOrder = [
              ...order.filter((o) => o.fieldName !== fieldName),
              newField,
            ];
          } else {
            nextOrder = current.asc
              ? order.map((o) =>
                  o.fieldName === fieldName ? { ...o, asc: false } : o,
                )
              : order.filter((o) => o.fieldName !== fieldName);
          }
        } else {
          nextOrder = [
            {
              ...newField,
              asc:
                currentOrderIndex === 0 ? !order[currentOrderIndex].asc : true,
            },
            ...order
              .filter((o) => o.fieldName !== fieldName)
              .map((o) => ({ ...o, hidden: true })),
          ];
        }
      }

      /**
       * If we're changing from multi mode to single mode or the other way round, just filter the
       * previous "mode" out.
       */
      nextOrder = multi
        ? nextOrder.filter((o) => !o.hidden)
        : [nextOrder[0], ...nextOrder.slice(1).filter((o) => o.hidden)];
    } else {
      nextOrder = [newField];
    }

    return nextOrder;
  }

  function* limitChangeSaga(action: ReturnType<Actions["changeLimit"]>) {
    yield put(
      actions.load({
        offset: 0,
        limit: action.payload.limit,
      }),
    );
  }

  function* offsetChangeSaga(action: ReturnType<Actions["changeOffset"]>) {
    yield put(
      actions.load({
        offset: action.payload.offset,
      }),
    );
  }

  function* filterApplySaga() {
    const nextFilter: IFilterGroup | null = yield select(selectors.nextFilter);
    yield put(actions.load({ filter: nextFilter }));
  }

  function* updateRowSaga(action: ReturnType<Actions["updateRow"]>) {
    if (!dataSource.identifierName) {
      throw new Error("Instance is not updatable");
    }

    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    try {
      yield call(
        services.api.updateViewData,
        token,
        dataSource.viewName,
        // TODO why can't we just use the new data?
        // maybe because NEW.<not-set-column> will then be NULL instead of the old (current) value?
        // TODO investigate this
        defaultsDeep({}, action.payload.data, action.payload.oldData),
        dataSource.identifierName,
        action.payload.oldData[dataSource.identifierName],
      );
      yield put(actions.updateRowSuccess());
      yield put(
        actions.enqueueSnackbar({
          message: yield call(
            getTranslatedTextSaga,
            tableTranslation,
            "messageUpdated",
          ),
          options: {
            variant: "success",
          },
        }),
      );
    } catch (error) {
      const msg = replaceSubstringAny(
        getServerError(error),
        /relation "cypex_generated./,
        'relation "',
      );
      yield put(actions.updateRowError(msg));
      yield put(
        actions.enqueueSnackbar({
          message: msg,
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  function* deleteRowSaga(action: ReturnType<Actions["deleteRow"]>) {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const { identifierName, viewName } = dataSource;
    try {
      if (identifierName === undefined) {
        throw new Error("Instance is not deletable");
      }

      yield call(
        services.api.deleteViewData,
        token,
        viewName,
        identifierName,
        action.payload.data[identifierName],
      );
      yield put(actions.deleteRowSuccess());
      yield put(
        actions.enqueueSnackbar({
          /**
           * TODO:
           * Call translation function, we cannot rely on the instantiated element here because the language
           * might change and we don't want to re-mount the whole redux module.
           */
          message: yield call(
            getTranslatedTextSaga,
            tableTranslation,
            "messageDeleted",
          ),
          options: {
            variant: "info",
          },
        }),
      );
    } catch (error) {
      const msg = replaceSubstringAny(
        getServerError(error),
        /relation "cypex_generated./,
        'relation "',
      );
      yield put(actions.deleteRowError(msg));
      yield put(
        actions.enqueueSnackbar({
          message: msg,
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  function* pollSaga() {
    const { interval } = element.config;

    while (true) {
      yield delay(interval! * 1000);
      yield put(actions.load());
    }
  }

  function* watchPollSaga() {
    yield race([call(pollSaga), take(types.POLL_STOP)]);
  }

  function* callLoad() {
    yield put(actions.load());
  }

  return function* mainSaga() {
    yield all([
      takeLatest(
        [
          types.LOAD,
          types.ROW_UPDATE_SUCCESS,
          types.ROW_DELETE_SUCCESS,
          lifecycleTypes.ELEMENT_SHOW,
        ],
        loadSaga,
      ),
      takeLatest(types.FILTER_CHANGE, filterChangeSaga),
      takeLatest(types.ORDER_CHANGE, orderChangeSaga),
      takeLatest(types.LIMIT_CHANGE, limitChangeSaga),
      takeLatest(types.OFFSET_CHANGE, offsetChangeSaga),
      takeLatest(types.FILTER_APPLY, filterApplySaga),
      takeLatest(types.ROW_UPDATE, updateRowSaga),
      takeLatest(types.ROW_DELETE, deleteRowSaga),
      takeLatest(types.POLL_START, watchPollSaga),
      createWatcherSaga(fixedFilterSelector, {
        onChange: callLoad,
      }),
    ]);

    yield put(actions.load());
  };
}
