import { memo } from "react";
import { Box, Divider, Paper, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

import { RoutePaths } from "staticPages/routes";
import BackButton from "../../../../../../elementTypes/common/BackButton";
import Button from "../../../../../../elementTypes/common/Button";
import { HookForm } from "../../../../../../elementTypes/common/HookForm";
import { useHookFormContext } from "../../../../../../elementTypes/common/HookForm/utils";
import { useCreateUserRole } from "../../../../../../queries/admin";
import { getApiError } from "../../../../../../queries/utils";
import { useRoute, useSnackbar } from "../../../../../../utils/hooks";
import { useHookFormError } from "../../../../../../utils/hooks/useHookFormError";
import { useRolePagesTranslation } from "../../translation";

const ROLES_URL = RoutePaths.Roles;

export const CreateRolePage = memo(() => {
  const showSnackbar = useSnackbar();
  const route = useRoute();

  const create = useCreateUserRole({
    onSuccess: (newRole) => {
      showSnackbar(
        translation.successMsg.replace("*", `"${newRole.name}"`),
        "success",
      );
      route("push", ROLES_URL);
    },
    onError: (apiError) => {
      showSnackbar(getApiError(apiError), "error");
    },
  });

  const handleSubmit = (newRole: Record<string, unknown>) => {
    create.mutate(newRole as { name: string });
  };

  const translation = useRolePagesTranslation();

  return (
    <HookForm onSubmit={handleSubmit} formOptions={{ mode: "onChange" }}>
      <Box display="flex" alignItems="center" gap={1}>
        <BackButton isIcon href={ROLES_URL} />
        <Typography variant="h5">{translation.createPageTitle}</Typography>
      </Box>
      <FormContent />
    </HookForm>
  );
});

const FormContent = memo(() => {
  const {
    control,
    formState: { isSubmitting },
  } = useHookFormContext();

  const handleValidate = (val: string) => !!val.trim();

  const getErrorMessage = useHookFormError();

  const translation = useRolePagesTranslation();

  return (
    <Paper variant="outlined">
      <Box p={1}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label={translation.inputNameLabel}
              error={Boolean(error)}
              helperText={getErrorMessage(error)}
              autoFocus
              variant="standard"
              {...field}
            />
          )}
          name="name"
          control={control}
          rules={{
            required: true,
            validate: handleValidate,
          }}
          defaultValue=""
        />
      </Box>
      <Divider />
      <Box
        p={1}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button color="primary" label="Cancel" href={ROLES_URL} />
        <Button
          color="secondary"
          disabled={isSubmitting}
          processing={isSubmitting}
          iconRight="forward"
          type="submit"
          label={translation.createBtnLabel}
        />
      </Box>
    </Paper>
  );
});
