import { useCallback, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { IObjectView } from "core";
import { useTranslator } from "core/session/translation";
import {
  QueryGroup,
  QueryObject,
  QueryTypeFilterOptions,
  UIQueryGroups,
} from "staticPages/admin/pages/modelBuilder/components/types";

type Props<T extends QueryObject | IObjectView = QueryObject> = {
  queryGroups: QueryGroup[];
  queries?: T[];
  queryType?: QueryTypeFilterOptions | null;
  showEmptyQueryGroups?: boolean;
};

export const useQueryGroupsList = <
  T extends QueryObject | IObjectView = QueryObject,
>({
  queryGroups,
  queries,
  queryType = null,
  showEmptyQueryGroups = true,
}: Props<T>) => {
  const { translate } = useTranslator();

  const translatedQueryGroups = useMemo(
    () =>
      queryGroups.reduce(
        (acc, queryGroup) => {
          const translatedQueryGroup = {
            [String(queryGroup.id)]: translate(queryGroup.i18n).label ?? "",
          };

          return { ...acc, ...translatedQueryGroup };
        },
        {} as Record<string, string>,
      ),
    [queryGroups, translate],
  );

  const mappedQueries = useMemo(() => {
    return !queryGroups
      ? ({ noGroup: queries } as UIQueryGroups)
      : queries?.length
        ? queries?.reduce(
            (acc, curr) => {
              const queryId = Number(curr.id);

              const filteredQueryGroups = queryGroups.filter((group) =>
                group.queries.includes(queryId),
              );

              if (!queryType || showEmptyQueryGroups) {
                const emptyGroups = queryGroups.filter((group) =>
                  isEmpty(group.queries),
                );

                if (isEmpty(acc.emptyGroups)) {
                  acc.emptyGroups = emptyGroups;
                }
              }

              if (!isEmpty(filteredQueryGroups)) {
                filteredQueryGroups.map((group) => {
                  if (!acc[group.id]) {
                    acc[group.id] = [];
                  }
                  acc[group.id].push(curr);
                });
              } else {
                acc.noGroup.push(curr);
              }

              return acc;
            },
            { noGroup: [] } as UIQueryGroups<T>,
          )
        : ({
            emptyGroups: queryGroups,
            noGroup: [] as T[],
          } as UIQueryGroups<T>);
  }, [queries, queryGroups, queryType, showEmptyQueryGroups]);

  const queryGroupsByType = useMemo<UIQueryGroups>(() => {
    if (!queryType || !queries) {
      return mappedQueries as UIQueryGroups;
    }

    return Object.keys(mappedQueries ?? ({} as UIQueryGroups)).reduce(
      (acc, curr) => {
        if (curr === "emptyGroups") {
          acc.emptyGroups = mappedQueries.emptyGroups;
          return acc;
        }

        const group = mappedQueries[curr] as QueryObject[];

        const filteredGroup = group.filter((q) =>
          queryType === QueryTypeFilterOptions.default
            ? q.autogenerated
            : !q.autogenerated,
        );

        if (filteredGroup.length) {
          acc[curr] = filteredGroup;
        }

        return acc;
      },
      {} as UIQueryGroups,
    );
  }, [queries, mappedQueries, queryType]);

  const findQueryGroupByKey = useCallback(
    (key: keyof QueryGroup, value: string | number | null) =>
      queryGroups?.find((group) => {
        const groupName = translate(group.i18n).label;
        switch (key) {
          case "queries":
            return group.queries.includes(Number(value));
          case "i18n":
            return groupName === value;
          default:
            return group[key] === value;
        }
      }),
    [queryGroups, translate],
  );

  return {
    findQueryGroupByKey,
    queryGroupsByType,
    translatedQueryGroups,
    mappedQueries,
  };
};
