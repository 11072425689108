import {
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { Box } from "@mui/material";

import ReactFlow, {
  Connection,
  ConnectionMode,
  Controls,
  Edge,
  EdgeChange,
  EdgeRemoveChange,
  EdgeSelectionChange,
  Node,
  NodeAddChange,
  NodeChange,
  ReactFlowInstance,
  Viewport,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";

import { useAdminContext } from "staticPages/admin/context";
import { LayoutOption } from "../../../component";
import { MiniMap } from "../../../components/MiniMap";
import { removeNewNode } from "../../utils";

import ConnectionLine from "./ConnectionLine";
import EndNode from "./EndNode";
import StartNode from "./StartNode";
import { StateNode } from "./StateNode";
import Transition from "./Transition";
import { EdgeData, NodeData, State, Workflow } from "./types";
import { getLayoutedElements, workflowToReactFlowElements } from "./utils";

const edgeTypes = {
  transition: Transition,
};

const nodeTypes = {
  state: StateNode,
  start: StartNode,
  end: EndNode,
  new: StateNode,
};

type IProps = {
  schema: string;
  table: string;
  directionValue: LayoutOption;
  workflow: Workflow;
  elementToImageRef: MutableRefObject<null | HTMLDivElement>;
  getStateById: (id: string) => State | undefined;
};

type WorkflowNodeChange = NodeChange & {
  selected?: boolean;
  id?: string;
  dragging?: boolean;
};

const getNodeChangeByType = (
  changes: WorkflowNodeChange[],
  cbType: (change: WorkflowNodeChange) => boolean,
) => changes.find((change) => cbType(change));

const WorkflowViewer = memo<IProps>(
  ({
    schema,
    table,
    directionValue,
    workflow,
    elementToImageRef,
    getStateById,
  }) => {
    const { fitView, setViewport, getViewport } = useReactFlow();

    const { workflowView, setWorkflowView } = useAdminContext();

    const elements = useMemo(
      () => workflowToReactFlowElements(workflow),
      [workflow],
    ) ?? { nodes: [], edges: [] };

    const prevPositions = useMemo(() => {
      const currentTable = workflowView?.[schema]?.[table] ?? {};
      let nodes = {};
      let viewport = {} as Viewport;
      if ("nodes" in currentTable) {
        nodes = currentTable.nodes;
      }
      if ("viewport" in currentTable) {
        viewport = currentTable.viewport;
      }

      return {
        nodes,
        viewport,
      };
    }, [workflowView, schema, table]);

    useEffect(() => {
      // Specify how to clean up after this effect:
      return function cleanup() {
        setWorkflowView({
          path: [schema, table, "viewport"],
          value: getViewport(),
        });
      };
    }, [schema, table, setWorkflowView, getViewport]);

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      elements.nodes,
      elements.edges,
      directionValue,
      prevPositions.nodes,
    );

    const [nodes, setNodes] = useNodesState(layoutedNodes);
    const [edges, setEdges] = useEdgesState(layoutedEdges);

    const onLayout = useCallback(
      (direction: LayoutOption, nodesArr: Node[], edgesArr: Edge[]) => {
        const layouted = getLayoutedElements(
          nodesArr,
          edgesArr,
          direction,
          prevPositions.nodes,
        );

        setNodes([...layouted.nodes]);
        setEdges([...layouted.edges]);
      },
      [prevPositions.nodes, setNodes, setEdges],
    );

    const handleConnect = useCallback(
      (params: Connection) => {
        const newIsExists =
          edges.find((e) => e.id === "newElement") ||
          nodes.find((n) => n.id === "newElement");

        // prevent connection if the new one is already on the page
        if (!newIsExists && params.target !== "start") {
          const newEdge = {
            ...params,
            id: "newElement",
            type: "transition",
            ...(params.source && {
              sourceName: getStateById(params.source)?.name,
            }),
            ...(params.target && {
              targetName: getStateById(params.target)?.name,
            }),
            animated: true,
            selected: true,
          };
          setEdges((eds) =>
            addEdge(
              newEdge,
              eds.map((ed) => ({ ...ed, selected: false })),
            ),
          );
        }
      },
      [getStateById, setEdges, edges, nodes],
    );

    useLayoutEffect(() => {
      onLayout(directionValue, elements.nodes, elements.edges);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directionValue, elements.nodes, elements.edges]);

    const handleLoad = (_instance: ReactFlowInstance<NodeData, EdgeData>) => {
      onLayout(directionValue, elements.nodes, elements.edges);

      if (Object.values(prevPositions.viewport).length) {
        setViewport(prevPositions.viewport);
      } else {
        fitView();
      }
    };

    const onNodesChange = useCallback(
      (changes: WorkflowNodeChange[]) => {
        const deleteNode = getNodeChangeByType(
          changes,
          (ch) =>
            !ch.dragging &&
            !ch.selected &&
            ch.id === "newElement" &&
            !["dimensions", "position"].includes(ch.type),
        );

        const newNode = getNodeChangeByType(
          changes,
          (ch) => ch.type === "add",
        ) as NodeAddChange;

        setNodes((nds) =>
          deleteNode
            ? applyNodeChanges(removeNewNode(changes), removeNewNode(nds))
            : applyNodeChanges(
                changes,
                newNode ? nds.map((nd) => ({ ...nd, selected: false })) : nds,
              ),
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    const onEdgesChange = useCallback(
      (changes: (EdgeChange & { selected?: boolean; id?: string })[]) => {
        const deleteEdge = changes.find(
          (change) =>
            (change as EdgeRemoveChange).id === "newElement" &&
            !(change as EdgeSelectionChange).selected,
        );

        setEdges((eds) =>
          deleteEdge
            ? applyEdgeChanges(removeNewNode(changes), removeNewNode(eds))
            : applyEdgeChanges(changes, eds),
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const handleNodeDragStop = useCallback(
      (_event: any, nodeDrag: Node) =>
        nodeDrag?.id &&
        setWorkflowView({
          path: [schema, table, "nodes", nodeDrag?.id],
          value: nodeDrag.position,
        }),
      [schema, table, setWorkflowView],
    );

    return (
      <Box
        flex="1"
        position="relative"
        overflow="hidden"
        width="100% !important"
        height="100% !important"
        bgcolor="background.paper"
        border="1px solid"
        borderColor="divider"
        borderRadius={1}
      >
        <ReactFlow
          ref={elementToImageRef}
          style={{ overflow: "visible" }}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          connectionLineComponent={ConnectionLine}
          nodes={nodes}
          edges={edges}
          connectionMode={ConnectionMode.Loose}
          elementsSelectable={true}
          selectNodesOnDrag={false}
          fitView
          onConnect={handleConnect}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDragStop={handleNodeDragStop}
          onInit={handleLoad}
        >
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      </Box>
    );
  },
);

export default WorkflowViewer;
