import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { getServerError } from "core/utils/api";
import { createWatcherSaga } from "core/utils/saga";

import { BuiltinResponseType } from "services/api/types/ResponseType";
import { DataDisplay } from "../types";

import { Actions, Selectors, Types } from "./types";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: DataDisplay,
  selectors: Selectors,
) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);

    const { dataSource } = element.config;

    try {
      const identifierValue: unknown = yield select(selectors.identifier);
      const responseFormat: BuiltinResponseType = yield select(
        selectors.responseFormat,
      );

      const metadata: Record<
        "order" | "offset" | "limit",
        string | number | null
      > = {
        order: dataSource.identifierName
          ? `${dataSource.identifierName}.desc`
          : null,
        offset: 0,
        limit: dataSource.setAllData ? 10 : 1,
      };
      const identifierName = dataSource.identifierName as keyof typeof metadata;

      if (identifierName && identifierValue !== undefined) {
        metadata[identifierName] = `eq.${identifierValue}`;
      }

      if (!dataSource.viewName) {
        yield put(actions.loadSuccess(null));

        return;
      }

      const res: Record<string, unknown>[] | GeoJSON.FeatureCollection =
        yield call(services.api.loadViewData, token, {
          viewName: dataSource.viewName,
          params: metadata,
          responseFormat,
        });
      let data:
        | Record<string, unknown>
        | Record<string, unknown>[]
        | GeoJSON.FeatureCollection["features"]
        | GeoJSON.FeatureCollection["features"][number];

      if (responseFormat === "geo") {
        data = dataSource.setAllData
          ? (res as GeoJSON.FeatureCollection)?.features
          : (res as GeoJSON.FeatureCollection)?.features?.[0];
      } else {
        data = dataSource.setAllData
          ? (res as Record<string, unknown>[])
          : (res as Record<string, unknown>[])?.[0];
      }

      yield put(actions.loadSuccess(data ?? null));
    } catch (error) {
      yield put(actions.loadError(getServerError(error)));
    }
  }

  function* callLoad() {
    yield put(actions.load());
  }

  return function* mainSaga() {
    yield all([
      takeLatest(types.LOAD, loadSaga),
      createWatcherSaga(selectors.identifier, {
        onChange: callLoad,
      }),
    ]);

    yield put(actions.load());
  };
}
