import { selectors as routerSelectors } from "core/router/reduxModule";
import { IElementComponentProps, PropsFromConnector } from "core/types";
import { connectElement } from "core/utils/react-redux";

import Component from "./component";
import { ReduxModule } from "./reduxModule";
import { InternalLinkField } from "./types";

/*
 * TODO:
 * TAKE THE LOGIC FROM COMPONENT AND PUT IT HERE. THE LOCATION CHANGE COULD HAVE PARAMETERS
 * THAT MUST BE TAKEN FROM OTHER ELEMENTS' SELECTORS, THE SAME WAY THE DETAIL FORM WORKS. FORMALIZE A JSON
 * STRUCTURE FOR ELEMENTS' SELECTORS AND ACTIONS, THAT INCLUDES ID AND SELECTOR OR ACTION.
 */

const mapStateToProps = (
  state: any,
  { element, module }: IElementComponentProps<ReduxModule, InternalLinkField>,
) => {
  const { params, pageId } = element.config.linkTo;

  return {
    linkTo: {
      pageId: pageId(state),
      params: Object.keys(params).reduce(
        (p, k) => ({
          ...p,
          [k]: params[k](state),
        }),
        {},
      ),
    },
    label:
      module.selectors.hasStarted(state) && !module.selectors.loading(state)
        ? element.config.label(state)
        : "",
    pages: routerSelectors.allPages(state),
  };
};

const mapDispatchToProps = {};

const connector = connectElement<
  ReduxModule,
  InternalLinkField,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>(mapStateToProps, mapDispatchToProps);

export type Props = PropsFromConnector<typeof connector>;

export default connector(Component);
