import { UseQueryOptions } from "@tanstack/react-query";
import { getOptions } from "queries/admin/utils";

import { loadViewData } from "services/api";
import { Query, useAuthenticatedQuery } from "../utils";
import { QueryKeys } from "./queryKeys";

export const fetchIdentifierData: Query<
  Record<string, string | number>[],
  { viewName: string; params?: Record<string, unknown> }
> = {
  queryKey: QueryKeys.fetchIdentifierData,
  queryFn: loadViewData,
};

export const useIdentifierData = (
  params: { viewName: string; params?: Record<string, unknown> },
  { identifierName }: { identifierName: string },
  config?: UseQueryOptions<
    Record<string, string | number>[],
    Error,
    Record<string, string | number>[]
  >,
) => {
  return useAuthenticatedQuery(fetchIdentifierData, params, {
    ...config,
    select: getOptions(identifierName),
  });
};
