export enum QueryKeys {
  createCustomQuery = "createCustomQuery",
  createDefaultQuery = "createDefaultQuery",
  createDefaultQueries = "createDefaultQueries",
  createState = "createState",
  fetchApps = "fetchApps",
  fetchColumnValues = "fetchColumnValues",
  fetchFileGroups = "fetchFileGroups",
  fetchFile = "fetchFile",
  fetchFiles = "fetchFiles",
  fetchFileTypes = "fetchFileTypes",
  fetchLDAPConfig = "fetchLDAPConfig",
  fetchModel = "fetchModel",
  fetchObjectViews = "fetchObjectViews",
  fetchQueries = "fetchQueries",
  fetchRoles = "fetchRoles",
  fetchStateNode = "fetchStateNode",
  fetchStateTransition = "fetchStateTransition",
  fetchUIList = "fetchUIList",
  fetchUiMetadata = "fetchUiMetadata",
  fetchUsers = "fetchUsers",
  fetchWorkflow = "fetchWorkflow",
  fetchDataPreview = "fetchDataPreview",
  fetchTableDataPreview = "fetchTableDataPreview",
  fetchTableAudit = "fetchTableAudit",
  fetchAuditTableList = "fetchAuditTableList",
  fetchLoginConfig = "fetchLoginConfig",
  fetchUser = "fetchUser",
  fetchFunctions = "fetchFunctions",
  getFilesByGroupType = "getFilesByGroupType",
  fetchGeneratedQuery = "fetchGeneratedQuery",
  fetchExtensions = "fetchExtensions",
  fetchRepositoryConfigurations = "fetchRepositoryConfigurations",
  fetchRepositoryConfiguration = "fetchRepositoryConfiguration",
  fetchRepositoriesContent = "fetchRepositoriesContent",
  getRepositoryResourceContent = "getRepositoryResourceContent",
  fetchQueryGroups = "getQueryGroups",
  fetchInternalModel = "fetchInternalModel",

  // MUTATE
  deleteQuery = "deleteQuery",
  deleteQueries = "deleteQueries",
  createQueryGroup = "createQueryGroup",
  updateQueryGroup = "updateQueryGroup",
  deleteQueryGroup = "deleteQueryGroup",
  updateQueryWithQueryGroup = "updateQueryWithQueryGroup",
}
