import { IDefaultElement, buildCustomExpressionValue } from "core";
import { defaultExpressionValue } from "elementTypes/common/Echarts/utils";

import { UntransformedCustomChartConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedCustomChartConfig> = {
  config: {
    dataSource: {
      viewName: "",
    },
    option: buildCustomExpressionValue("{}"),
    filter: defaultExpressionValue,
  },
  i18n: {
    en: {
      title: "",
    },
  },
};
