import { MouseEvent, useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import {
  QueryGroupEmptyDroppableProvider,
  QueryGroupFullDroppableProvider,
  useQueriesPanelContext,
} from "staticPages/admin/pages/modelBuilder/context/queriesPanel/QueriesPanelContext.utils.ts";
import { QueryGroup, UIQueryGroups } from "../../types.ts";
import { EmptyQueryGroup } from "../components/tree/EmptyQueryGroup.tsx";
import { FullQueryGroup } from "../components/tree/FullQueryGroup.tsx";
import { QueryRow } from "../components/tree/VirtualizedQuery.tsx";
import { useQueryGroupStyles } from "../styles.ts";
import { TTreeProps } from "../types.ts";
import { generateDraggableId } from "./dragAndDrop.ts";
import { useQueryFetch } from "./useQueryFetch.ts";

export const useTrees = ({
  filteredQueries,
  filteredQueryGroups,
  handleTreeDeleteClick,
  handleTreeEditClick,
}: TTreeProps) => {
  const [isDraggingGlobal, setIsDraggingGlobal] = useState(false);

  const {
    queries,
    queryGroupsUtils: { findQueryGroupByKey, translatedQueryGroups },
    queryProps: { fetchQueryProps },
  } = useQueriesPanelContext();

  const {
    classes: { droppableStyle },
    cx,
  } = useQueryGroupStyles();

  const { updateQueryWithQueryGroup } = useQueryFetch(fetchQueryProps);

  const handleEditClick = useCallback(
    (queryGroup?: QueryGroup) => (ev: MouseEvent<HTMLButtonElement>) =>
      handleTreeEditClick(queryGroup)(ev),
    [handleTreeEditClick],
  );

  const handleDeleteClick = useCallback(
    (queryGroup?: QueryGroup) => (ev: MouseEvent<HTMLButtonElement>) =>
      handleTreeDeleteClick(queryGroup)(ev),
    [handleTreeDeleteClick],
  );

  const memoizedFilteredQueryGroups = useMemo(
    () =>
      Object.keys(filteredQueryGroups).reduce((acc, curr) => {
        const currQueryGroup = filteredQueryGroups[curr];

        return !currQueryGroup.length
          ? { ...acc }
          : { ...acc, [curr]: currQueryGroup };
      }, {} as UIQueryGroups),
    [filteredQueryGroups],
  );

  const onDragStart = () => setIsDraggingGlobal(true);

  const onDragEnd = ({ destination, source, draggableId }: DropResult) => {
    setIsDraggingGlobal(false);

    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    const [queryId] = draggableId.split("-");
    const [queryGroupId] = destination.droppableId.split("-");

    const query = queries.find((q) => q.id === queryId);

    const validQueryGroupId = Number.isInteger(Number(queryGroupId))
      ? Number(queryGroupId)
      : null;

    if (query) {
      updateQueryWithQueryGroup({
        id: queryId,
        queryGroupId: validQueryGroupId,
      });
    }
  };

  const groupQueriesTree = useMemo(() => {
    const groups = Object.keys(memoizedFilteredQueryGroups).filter(
      (queryName) => queryName !== "noGroup" && queryName !== "emptyGroups",
    );

    return groups.map((id, index) => {
      const groupQueries = memoizedFilteredQueryGroups[id];

      const queryGroup = findQueryGroupByKey("id", id);
      const groupName = translatedQueryGroups[id];

      const providerProps = {
        groupName,
        index,
        groupQueries,
        queryGroup,
        handleEditClick,
        handleDeleteClick,
      };

      return (
        <Droppable
          key={`${queryGroup?.id}-droppable`}
          droppableId={`${queryGroup?.id}-droppable`}
          isDropDisabled={
            !filteredQueries.length || !memoizedFilteredQueryGroups
          }
        >
          {(provided, snapshot) => (
            <QueryGroupFullDroppableProvider
              value={{ ...providerProps, provided, snapshot, isDraggingGlobal }}
            >
              <FullQueryGroup />
            </QueryGroupFullDroppableProvider>
          )}
        </Droppable>
      );
    });
  }, [
    filteredQueries.length,
    findQueryGroupByKey,
    handleDeleteClick,
    handleEditClick,
    isDraggingGlobal,
    memoizedFilteredQueryGroups,
    translatedQueryGroups,
  ]);

  const queriesWithNoGroups = useMemo(
    () =>
      memoizedFilteredQueryGroups.noGroup?.map((query, index) => (
        <Draggable
          key={query.id}
          draggableId={generateDraggableId(query.id)}
          index={index}
          isDragDisabled={Object.keys(memoizedFilteredQueryGroups).length === 1}
        >
          {(provided, snapshot) => (
            <QueryRow
              key={`${query.id}-${index}-noGroup`}
              type="tree"
              index={index}
              query={query}
              provided={provided}
              snapshot={snapshot}
            />
          )}
        </Draggable>
      )),
    [memoizedFilteredQueryGroups],
  );

  const noGroupQueriesTree = useMemo(
    () => (
      <Droppable
        key="none-droppable"
        droppableId="none-droppable"
        isDropDisabled={!filteredQueries.length || !memoizedFilteredQueryGroups}
      >
        {({ innerRef, placeholder }, { isDraggingOver }) => (
          <Box
            ref={innerRef}
            className={cx({
              [droppableStyle]: isDraggingOver,
            })}
          >
            {placeholder}
            {queriesWithNoGroups}
          </Box>
        )}
      </Droppable>
    ),
    [
      cx,
      droppableStyle,
      filteredQueries.length,
      memoizedFilteredQueryGroups,
      queriesWithNoGroups,
    ],
  );

  const emptyGroupsTree = useMemo(
    () =>
      memoizedFilteredQueryGroups?.emptyGroups?.map(({ id }, index) => {
        const label = translatedQueryGroups[id];
        const queryGroup = findQueryGroupByKey("id", id);

        const providerProps = {
          queryGroup,
          index,
          label,
          handleEditClick,
          handleDeleteClick,
          id,
        };

        return (
          <Droppable
            key={`${queryGroup?.id}-droppable`}
            droppableId={`${queryGroup?.id}-droppable`}
            isDropDisabled={
              !filteredQueries.length || !memoizedFilteredQueryGroups
            }
          >
            {(provided, snapshot) => (
              <QueryGroupEmptyDroppableProvider
                value={{
                  ...providerProps,
                  provided,
                  snapshot,
                  isDraggingGlobal,
                }}
              >
                <EmptyQueryGroup />
              </QueryGroupEmptyDroppableProvider>
            )}
          </Droppable>
        );
      }),
    [
      memoizedFilteredQueryGroups,
      translatedQueryGroups,
      findQueryGroupByKey,
      handleEditClick,
      handleDeleteClick,
      filteredQueries.length,
      isDraggingGlobal,
    ],
  );

  const treeItems = useMemo(
    () =>
      filteredQueries.length ? (
        <>
          {groupQueriesTree}
          {emptyGroupsTree}
          {noGroupQueriesTree}
        </>
      ) : memoizedFilteredQueryGroups.emptyGroups?.length ? (
        <>{emptyGroupsTree}</>
      ) : null,
    [
      emptyGroupsTree,
      filteredQueries.length,
      memoizedFilteredQueryGroups.emptyGroups?.length,
      groupQueriesTree,
      noGroupQueriesTree,
    ],
  );

  return { treeItems, onDragEnd, onDragStart };
};
