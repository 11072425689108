import { PropsWithChildren, memo } from "react";
import { useBeforeunload } from "react-beforeunload";

import { BoundActions } from "../../types";
import { EditorProvider } from "../EditorContext";
import { actions } from "../reduxModule";

import { mapStateToProps } from "./container";

type Props = ReturnType<typeof mapStateToProps> &
  BoundActions<typeof actions> &
  PropsWithChildren;

// TODO: to check: we have EditorProvider & ElementEditorProvider, do we need both?
export const EditorLayout = memo<Props>(
  ({
    editModeOn,
    children,
    isLayoutChanged,
    changeConfigValue,
    changePositionValue,
    changeTranslationValue,
    setDraggableElement,
    unselectElement,
  }) => {
    useBeforeunload((event: Event) => {
      if (isLayoutChanged) {
        return event.preventDefault();
      }
    });

    return (
      <EditorProvider
        value={{
          editModeOn,
          changeConfigValue,
          changePositionValue,
          changeTranslationValue,
          setDraggableElement,
          unselectElement,
        }}
      >
        {children}
      </EditorProvider>
    );
  },
);

EditorLayout.displayName = "EditorLayout";
